import React, { useEffect, useState } from 'react'
import Sidebar from '../../../../components/CustomerDashboard/Sidebar/Sidebar'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { countries } from 'countries-list'
import Topbar from '../Topbar/Topbar'
import Subscribe from '../../../Landing/sections/Subscribe'
import Copyright from '../../../../components/CustomerDashboard/Copyright/Copyright'

const Address = () => {
    const [customer, setCustomer] = useState(null);

    const customerState = useSelector((state) => state.customer);

    const navigate = useNavigate();

    const getCustomerData = async (token) => {
        try {
            const res = await axios.get('https://store.platformz.us/rest/V1/customers/me', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (res.data) {
                setCustomer(res.data);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }
    useEffect(() => {
        if (customerState.accessToken) {
            getCustomerData(customerState.accessToken);
        }
    }, [customerState.accessToken])

    useEffect(() => {
        if (customer?.addresses?.length === 0) {
            navigate('/dashboard/address/new')
        }
        console.log(customer);
    }, [customer, navigate])

    const deleteAddress = (id) => {

        let payload = {
            "customer": {
                "email": customer?.email,
                "firstname": customer?.firstname,
                "lastname": customer?.lastname,
                "addresses": customer.addresses.filter(address => address.id != id)
            }
        };
        axios.put(
            `https://store.platformz.us/rest/V1/customers/me`,
            payload,
            {
                headers: {
                    'Authorization': `Bearer ${customerState.accessToken}`,
                    'Content-Type': 'application/json',
                },
            }
        )
            .then(response => {
                console.log('Customer address added successfully:', response.data);
                setCustomer(response.data)
            })
            .catch(error => {
                console.error('Error adding customer address:', error);
            });
    }


    return (
        <>

            <Topbar />
            <div className={'min-h-screen'}>
                <div className={'page-main'}>
                    <Sidebar />
                    <div className={'main-content'} style={{
                        width: "calc(100% - 385px)"
                    }}>

                        <div className='page-heading'>
                            <h1><span className='highlight'>Address</span> <span>Book</span></h1>
                        </div>

                        <div className='block-dashboard-info address-book'>
                            <div className='section-title'>
                                <span>Default Addresses</span>
                                <hr />
                            </div>
                            <div className='address-book-info'>
                                <div style={{
                                    fontWeight: "500",
                                    clear: "none",
                                    float: "left",
                                    width: "50%"
                                }}>
                                    <h4 className='font-bold'>Default Billing Address</h4>
                                    {customer?.addresses?.filter(address => address.default_billing === true).map((address) => {
                                        console.log(address);
                                        return (
                                            <>
                                                <p>{address.firstname} {address.lastname}</p>
                                                {address.company && <p>{address.company}</p>}
                                                {address.street[0] && <p>{address.street[0]}</p>}
                                                {address.street[1] && <p>{address.street[1]}</p>}
                                                <p>{address.city}{address.region.region && ", " + address.region.region}{address.postcode && ", " + address.postcode}</p>
                                                <p>{address.country_id && ", " + countries[address.country_id]?.name}</p>
                                                <p>T: <Link to={'tel:' + address.telephone}>{address.telephone}</Link></p>
                                                <Link to={'edit/' + address.id}><p>Change Billing Address</p></Link>
                                            </>

                                        )
                                    })}
                                    {customer?.addresses?.filter(address => address.default_billing === true).length === 0 &&
                                        <p className='mgn-btm'>You have not set a default billing address.</p>
                                    }

                                    <div>
                                    </div>
                                </div>
                                <div style={{
                                    fontWeight: "500",
                                    clear: "none",
                                    float: "left",
                                    width: "50%"
                                }}>
                                    <h4 className='font-bold'>Default Shipping Address</h4>
                                    {customer?.addresses?.filter(address => address.default_shipping === true).map((address) => {
                                        return (
                                            <>
                                                <p>{address.firstname} {address.lastname}</p>
                                                {address.company && <p>{address.company}</p>}
                                                {address.street[0] && <p>{address.street[0]}</p>}
                                                {address.street[1] && <p>{address.street[1]}</p>}
                                                <p>{address.city}{address.region.region && ", " + address.region.region}{address.postcode && ", " + address.postcode}</p>
                                                <p>{address.country_id && ", " + countries[address.country_id]?.name}</p>
                                                <p>T: <Link to={'tel:' + address.telephone}>{address.telephone}</Link></p>
                                                <Link to={'edit/' + address.id}><p>Change Shipping Address</p></Link>
                                            </>

                                        )
                                    })}
                                    {customer?.addresses?.filter(address => address.default_shipping === true).length === 0 &&
                                        <p>You have not set a default shipping address.</p>
                                    }
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='mb-12 block-dashboard-info'>
                            <div className='mt-10 mb-6 section-title'>
                                <span className=''>Additional Address Entries</span>
                                <hr></hr>
                            </div>
                            <div>
                                <table className='dashboard-table' style={{ width: "100%", borderCollapse: "separate", borderSpacing: "0 15px" }} border="1">
                                    <thead>
                                        <tr>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Street Address</th>
                                            <th>City</th>
                                            <th>Country</th>
                                            <th>State</th>
                                            <th>Zip/Postal Code</th>
                                            <th>Phone</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <hr></hr>
                                    <tbody>
                                        {customer?.addresses?.map((item) => {
                                            return (
                                                <tr>
                                                    <td>{item.firstname}</td>
                                                    <td>{item.lastname}</td>
                                                    <td>{item.street[0]}{item.street[1] ? ", " + item.street[1] : ''}</td>
                                                    <td>{item.city}</td>
                                                    <td>{countries[item.country_id]?.name}</td>
                                                    <td>{item.region.region}</td>
                                                    <td>{item.postcode}</td>
                                                    <td>{item.telephone}</td>
                                                    <td className='edit'>
                                                        <Link to={`edit/${item.id}`}><span style={{ paddingRight: "10px", color: "#006bb4" }}>Edit</span></Link>
                                                    </td>
                                                    <td className='del'>
                                                        <Link to={'#'} onClick={(e) => deleteAddress(item.id)}><span style={{ paddingLeft: "10px", color: "#006bb4" }}>Delete</span></Link>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="mb-6">
                            <Link to={'new'}>
                                <button className='save save-new' style={{ padding: "10px 20px", color: "#fff", backgroundColor: "#1979c3", borderRadius: "5px" }}>Add New Address</button>
                            </Link>
                        </div>

                        <div style={{
                            marginRight: "-85px",
                            marginLeft: "-100px",
                            marginTop: "126px",
                            marginBottom: "-126px",
                        }}>
                            <Subscribe />
                            <Copyright />
                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}

export default Address