import React, {useEffect, useState} from "react";

import RegistrationForm from "../../../components/RegistationForm/RegistrationForm";
import {useSearchParams} from "react-router-dom";

import axios from "axios";

import SSO from "../../../components/SSO/SSO";

export default function RegistrationPage() {
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();


    return (

        <section className="bg-gray-50 bg-gray-50 py-24 md:py-28">
            <div
                className="flex flex-col items-center justify-center  mx-auto h-full px-4 md:px-24 lg:w-3/4 xl:w-1/2 xl:px-0">
                <div
                    className="w-full bg-white rounded-lg shadow">

                    <div className="p-8 pt-20 ">
                        <p className="text-center leading-tight tracking-tight text-gray-900 md:text-4xl mb-6">
                            REGISTER WITH
                        </p>
                        <SSO/>
                        <p className="text-center leading-tight tracking-tight text-gray-900 md:text-4xl mb-12 mt-12">
                            OR SIGNUP USING
                        </p>
                        <RegistrationForm/>

                    </div>
                </div>
            </div>


        </section>
    );

}
