import React, {useEffect, useState} from "react";
import axios from "axios";
import PageBanner from "../../../components/PageBanner/PageBanner";
import PricingSlider from "../../../components/PricingSlider/PricingSlider";
import {strapi_url} from "../../../tools/api/api";
import PricingTemplates from "../../InfoPages/Pricing/PricingComponents/PricingTemplates/PricingTemplates";
import PricingAddons from "../../InfoPages/Pricing/PricingComponents/PricingAddons/PricingAddons";
import TechnologySlider from "../../../components/TechnologySlider/TechnologySlider";
import {Link} from "react-router-dom";

export default function Templates() {
    const [content, setContent] = useState(null)
    const [templates, setTemplates] = useState(null)
    const [addons, setAddons] = useState(null)


    function getTemplaOptions() {
        axios.get('https://strapi.platformz.us/api/ecommerce-template-options?populate=deep')
            .then(res => {
                setTemplates(res.data.data)

            })
            .catch(err => console.error(err))
    }

    function getAddons() {
        axios.get('https://strapi.platformz.us/api/ecommerce-add-ons-and-features?populate=deep')
            .then(res => {
                setAddons(res.data.data)
                console.log('res.data.data', res.data.data)

            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        getTemplaOptions()
        getAddons()
        axios.get('https://strapi.platformz.us/api/build-your-platform?populate=deep')
            .then(res => {
                setContent(res.data.data.attributes)
            })
            .catch(err => console.error(err))
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        console.log(templates)
    }, [templates]);
    if (content) {


        return (
            <>
                <section className="pricing">

                    {/*<ul className="pricing__features">*/}
                    {/*    {content.features.map(feature => {*/}
                    {/*        return (*/}
                    {/*            <li className="pricing__feature-item">*/}
                    {/*                <div className="pricing__feature-item-ico"*/}
                    {/*                     style={{backgroundColor: feature.feature_ico_bg_color}}>*/}
                    {/*                    <img src={strapi_url + feature.feature_ico.data.attributes.url}*/}
                    {/*                         alt={feature.feature_name}/>*/}
                    {/*                </div>*/}
                    {/*                <h4 className={'text-2xl py-2'}>{feature.feature_name}</h4>*/}
                    {/*                <p className={'text-xl text-gray-500'}>{feature.feature_desc}</p>*/}
                    {/*            </li>*/}
                    {/*        )*/}
                    {/*    })}*/}


                    {/*</ul>*/}


                    <div className="pricing__templates">
                        <h3 className="text-4xl text-center">
                            Select Your Pre-Built Platform to Customize
                        </h3>
                        <p className="text-xl pt-4 pb-8 text-center">
                            {content.template_subtitle}
                        </p>

                        {templates !== null && <PricingTemplates templates={templates}/>}

                        <div className={'flex gap-4 justify-center'}>
                            <Link to={'/purchase/bundle'}
                                  className="features_template__section-row-list_item-cta mt-3"
                            >Back

                            </Link>
                            <Link to={'/purchase/addons'}
                                  className="features_template__section-row-list_item-cta mt-3"
                            >Next

                            </Link>
                        </div>
                    </div>


                    {/*<div className="pricing__faq">*/}
                    {/*    <p className="pricing__faq-overtitle">Pricing FAQ</p>*/}
                    {/*    <h3 className="pricing__faq-title">Dedicated to help anything people’s needs</h3>*/}
                    {/*    <ul className="pricing__faq-list">*/}
                    {/*        {content.faq.map((item, index) => {*/}
                    {/*            return (*/}
                    {/*                <FaqItem data={item} open={index === 0 && true}/>*/}
                    {/*            )*/}
                    {/*        })}*/}

                    {/*    </ul>*/}
                    {/*</div>*/}
                    {/*{addons && <PricingAddons title={content.addons_and_features_title}*/}
                    {/*                          subtitle={content.addons_and_features_subtitle} addons={addons}/>}*/}

                </section>
                <TechnologySlider content={content.logo_scroller}/>
            </>
        )
    }
}