import Container from "../../../components/Container/Container";
import arrow_blue_left from "../../../assets/shapes/left-arrow-blue.svg";
import avatar from "../../../assets/shapes/avatar.png";
import star from "../../../assets/shapes/start.png";
import arrow_blue_right from "../../../assets/shapes/right-arrow-blue.svg";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import EffectCarousel from "../effect-carousel.esm";
import "../c.scss";

const Testimonial = ({ data }) => {
  const generateStars = (count) => {
    return Array.from({ length: count }, (_, index) => (
      <div>
        <img key={index} src={star} alt="" className={"w-full"} />
      </div>
    ));
  };
  return (
    <section className="testimonial bg-gray-100 py-10 md:py-32">
      <Container>
        <div className="testimonial__content">
          <div className="testimonial__titles">
            <p className="text-6xl font-medium">What They are</p>
            <h3 className="text-7xl text-[#4ea0f2]">Saying?</h3>
          </div>
        </div>
        <div className="grid mt-20 relative">
          <div className="testimonial__btn arrow_blue_left">
            <img src={arrow_blue_left} alt="" />
          </div>

          <Swiper
            modules={[EffectCarousel, Pagination, Navigation]}
            effect={"carousel"}
            navigation={{
              prevEl: ".arrow_blue_left",
              nextEl: ".arrow_blue_right",
            }}
            slidesPerView={1}
            centeredSlides={true}
            loop={true}
            carouselEffect={{
              // opacity change per side slide
              opacityStep: 0,
              // scale change per side slide
              scaleStep: 0.2,
              // amount of side slides visible, can be 1, 2 or 3
              sideSlides: 1,
            }}
            // loopAdditionalSlides={0}
            pagination={true}
            breakpoints={{
              0: {
                slidesPerView: 1,
                carouselEffect: {
                  sideSlides: 1,
                },
              },
              786: {
                slidesPerView: 2,
                carouselEffect: {
                  sideSlides: 1,
                },
              },
              1200: {
                slidesPerView: 3,
                carouselEffect: {
                  sideSlides: 1,
                },
              },
            }}
          >
            {[...data.user_testimonial, ...data.user_testimonial].map(
              (item, index) => {
                return (
                  <>
                    <SwiperSlide key={index}>
                      <div className="swiper-carousel-animate-opacity bg-white p-6 md:p-12 rounded-lg h-full">
                        <img
                          src={`https://strapi.platformz.us${item.profile_pic.data.attributes.url}`}
                          alt=""
                          className={
                            "rounded-full aspect-square w-1/4 mx-auto object-cover object-top"
                          }
                        />
                        <div className="slide-content text-center">
                          <h2 className={"text-2xl md:text-4xl pt-4"}>
                            {item.name}
                          </h2>
                          <h2 className={"text-xl md:text-3xl text-gray-500"}>
                            {item.position}
                          </h2>
                          <div className="flex w-1/3 mx-auto my-4 gap-2">
                            {generateStars(item.rating)}
                          </div>
                          <p
                            className={
                              "text-base text-justify font-medium px-0 md:px-12"
                            }
                          >
                            {item.text}
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                  </>
                );
              }
            )}
          </Swiper>

          <div className="testimonial__btn arrow_blue_right">
            <img src={arrow_blue_right} alt="" />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Testimonial;
