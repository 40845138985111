import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { strapi_url } from "../../../tools/api/api";

const RecentWork = ({ data }) => {
  const [content, setContent] = useState();

  useEffect(() => {
    axios
      .get(`${strapi_url}/api/portfolio-cases?populate=deep`)
      .then((res) => {
        setContent(res.data.data);
      })
      .catch((err) => console.error(err));
  }, []);

  const breakpoints = {
    1200: {
      slidesPerView: 3,
      spaceBetween: 80,
      loop: true,
    },
    0: {
      spaceBetween: 48,
      slidesPerView: 1,
    },
  };

  console.log(content)

  if (content) {
    return (
      <section className={`recent-work mt150 `}>
        <div className="container max-w-full">
          <div className="recent-work__content">
            <div className="recent-work__row">
              <h3 className="text-6xl">
                Recent <span className={"text-[#4ea0f2]"}>Work</span>
              </h3>
            </div>
          </div>
        </div>

        <div className={"relative w-full "}>
          {/*{content.length > 2 && <div className="recent-work__arrows ">*/}
          {/*    <div className="recent-work__btn recent-work_left">*/}
          {/*        <img src={arrow_blue_left} alt=""/>*/}
          {/*    </div>*/}

          {/*    <div className="recent-work__btn recent-work_right ">*/}
          {/*        <img src={arrow_blue_right} alt=""/>*/}
          {/*    </div>*/}
          {/*</div>}*/}
          <div className={"w-full  mx-auto overflow-hidden th-slider-marquee"}>
            <Swiper
              grabCursor={true}
              loop={true}
              centeredSlides={true}
              speed={5000}
              autoplay={{ delay: 0 }}
              allowTouchMove={false}
              disableOnInteraction={true}
              breakpoints={breakpoints}
            // autoplay={{ delay: 3000, disableOnInteraction: false }}
            >
              {content
                .sort((a, b) => a.attributes.rank - b.attributes.rank)
                .map((item, index) => (
                  <SwiperSlide key={index}>
                    <Link
                      to={item.attributes.url?.startsWith('http') ? item.attributes.url : `https://${item.attributes.url}`}
                      className="hover:opacity-80"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="w-full">
                        {item.attributes.images && (
                          <img
                            className={
                              "aspect-square object-contain md:w-1/2 mx-auto"
                            }
                            src={`https://strapi.platformz.us${item.attributes &&
                              item.attributes.images.data.attributes.url
                              }`}
                            alt={item.attributes.name}
                          />
                        )}
                        <p className="text-3xl pb-4">{item.attributes.name}</p>
                        <p className="text-base font-medium text-justify leading-normal	">
                          {item.attributes.short_desc}
                        </p>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      </section>
    );
  }
};

export default RecentWork;
