import { toast } from "react-toastify";

const Notification = (status, message) => {
  debugger;
  if (status === "success") {
    debugger;
    toast.success(message, {
      autoClose: 3000,
      hideProgressBar: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
      // transition: Bounce,
    });
  } else if (status === "error") {
    debugger;
    toast.error(message, {
      autoClose: 3000,
      hideProgressBar: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
      // transition: Bounce,
    });
  }
};
export default Notification;
