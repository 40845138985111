import './MediaCenter.scss'
import pdf_icon from "../../../assets/shapes/pdf_ico.svg";
import wide_black_icon from "../../../assets/pic/wide-black.png";
import wide_white_icon from "../../../assets/pic/wide-white.png";
import square_icon from "../../../assets/pic/square.png";
import axios from "axios";
import {strapi_url} from "../../../tools/api/api";
import React, {useEffect, useState} from "react";
import PageBanner from "../../../components/PageBanner/PageBanner";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper/modules";
import Plan from "../Pricing/Plan";
import {Link} from "react-router-dom";
import ReactPlayer from "react-player";


const MediaCenter = () => {
    const breakpoints = {
        1200: {
            slidesPerView: 3,
            loop: true,
        },
        0: {
            slidesPerView: 1,
        },
    };
    const breakpoints2 = {
        1200: {
            slidesPerView: 2,
            loop: true,
        },
        0: {
            slidesPerView: 1,
        },
    };
    const [content, setContent] = useState(null)
    useEffect(() => {
        axios.get(`${strapi_url}/api/media-center?populate=deep`)
            .then((response) => {
                setContent(response.data.data.attributes)
                console.log(response.data.data.attributes)
            })
            .catch(error => console.error(error))
    }, []);

    if (content) {
        return (
            <>
                <PageBanner content={content.banner}/>
                <div className="media">


                    <p className="media__text pt-24">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla et
                        elit
                        ut odio bibendum consequat ut a felis. Integer ut faucibus ligula, non ultrices nisi. Ut ac orci
                        non
                        metus viverra pharetra sit amet tincidunt nibh.</p>
                    <div className={'w-5/6 mx-auto relative'}>
                        <div className={'my-swiper-container relative z-50'}>
                            <Swiper
                                modules={[Navigation]}
                                navigation={{
                                    prevEl: '.prev-files',
                                    nextEl: '.next-files',
                                }}
                                spaceBetween={12}


                                loop={true}


                                breakpoints={breakpoints}


                            >
                                {content.file.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="download h-full mx-auto lg:mx-0">
                                            <img src={pdf_icon} alt="" className="download__icon"/>
                                            <h4 className="download__title">{item.name}</h4>
                                            <p className="download__text">{item.description}</p>
                                            <a href={strapi_url + item.file.data.attributes.url}
                                               className="download__link"
                                               download={item.file.data.attributes.name}>Download PDF <span
                                                className="download__link--icon"></span></a>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                        {
                            content.file.length > 3 && (<div
                                className="flex gap-12 w-full h-fit justify-between absolute top-0 bottom-0 z-30 left-0 my-auto">
                                <div className="prev-files">
                                    <svg width="50" height="50" viewBox="0 0 24 24" fill="none"

                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.7071 5.29289C15.0976 5.68342 15.0976 6.31658 14.7071 6.70711L9.41421 12L14.7071 17.2929C15.0976 17.6834 15.0976 18.3166 14.7071 18.7071C14.3166 19.0976 13.6834 19.0976 13.2929 18.7071L7.29289 12.7071C6.90237 12.3166 6.90237 11.6834 7.29289 11.2929L13.2929 5.29289C13.6834 4.90237 14.3166 4.90237 14.7071 5.29289Z"
                                            fill="black"/>
                                    </svg>
                                </div>
                                <div className="next-files">
                                    <svg width="50" height="50" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M9.29289 18.7071C8.90237 18.3166 8.90237 17.6834 9.29289 17.2929L14.5858 12L9.29289 6.70711C8.90237 6.31658 8.90237 5.68342 9.29289 5.29289C9.68342 4.90237 10.3166 4.90237 10.7071 5.29289L16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L10.7071 18.7071C10.3166 19.0976 9.68342 19.0976 9.29289 18.7071Z"
                                            fill="black"/>
                                    </svg>


                                </div>


                            </div>)
                        }
                    </div>


                    <h3 className="media__title--thin-blue">Gallery</h3>

                    <div className={'w-5/6 mx-auto relative'}>
                        <div className={'my-swiper-container relative z-50 '}>
                            <Swiper
                                modules={[Navigation]}
                                navigation={{
                                    prevEl: '.prev-gallery',
                                    nextEl: '.next-gallery',
                                }}
                                spaceBetween={12}


                                loop={true}


                                breakpoints={breakpoints}


                            >
                                {content.gallery.data.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <div className={'flex h-full items-center'}>
                                            <img src={strapi_url + item.attributes.url} className={'w-full'}/>
                                        </div>

                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                        {
                            content.gallery.data.length > 3 && (<div
                                className="flex gap-12 w-full h-fit justify-between absolute top-0 bottom-0 z-30 left-0 my-auto">
                                <div className="prev-gallery">
                                    <svg width="50" height="50" viewBox="0 0 24 24" fill="none"

                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.7071 5.29289C15.0976 5.68342 15.0976 6.31658 14.7071 6.70711L9.41421 12L14.7071 17.2929C15.0976 17.6834 15.0976 18.3166 14.7071 18.7071C14.3166 19.0976 13.6834 19.0976 13.2929 18.7071L7.29289 12.7071C6.90237 12.3166 6.90237 11.6834 7.29289 11.2929L13.2929 5.29289C13.6834 4.90237 14.3166 4.90237 14.7071 5.29289Z"
                                            fill="black"/>
                                    </svg>
                                </div>
                                <div className="next-gallery">
                                    <svg width="50" height="50" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M9.29289 18.7071C8.90237 18.3166 8.90237 17.6834 9.29289 17.2929L14.5858 12L9.29289 6.70711C8.90237 6.31658 8.90237 5.68342 9.29289 5.29289C9.68342 4.90237 10.3166 4.90237 10.7071 5.29289L16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L10.7071 18.7071C10.3166 19.0976 9.68342 19.0976 9.29289 18.7071Z"
                                            fill="black"/>
                                    </svg>


                                </div>


                            </div>)
                        }
                    </div>


                    <h3 className="media__title--thin-blue">Videos</h3>

                    <div className={'w-5/6 mx-auto relative'}>
                        <div className={'my-swiper-container relative z-50 '}>
                            <Swiper
                                modules={[Navigation]}
                                navigation={{
                                    prevEl: '.prev-videos',
                                    nextEl: '.next-videos',
                                }}
                                spaceBetween={12}


                                loop={true}


                                breakpoints={breakpoints2}


                            >
                                {content.videos.map((item, index) => (
                                    <SwiperSlide key={index}>

                                        <ReactPlayer url={item.video_url.url} width={"100%"}/>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                        {
                            content.gallery.data.length > 3 && (<div
                                className="flex gap-12 w-full h-fit justify-between absolute top-0 bottom-0 z-30 left-0 my-auto">
                                <div className="prev-videos">
                                    <svg width="50" height="50" viewBox="0 0 24 24" fill="none"

                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.7071 5.29289C15.0976 5.68342 15.0976 6.31658 14.7071 6.70711L9.41421 12L14.7071 17.2929C15.0976 17.6834 15.0976 18.3166 14.7071 18.7071C14.3166 19.0976 13.6834 19.0976 13.2929 18.7071L7.29289 12.7071C6.90237 12.3166 6.90237 11.6834 7.29289 11.2929L13.2929 5.29289C13.6834 4.90237 14.3166 4.90237 14.7071 5.29289Z"
                                            fill="black"/>
                                    </svg>
                                </div>
                                <div className="next-videos">
                                    <svg width="50" height="50" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M9.29289 18.7071C8.90237 18.3166 8.90237 17.6834 9.29289 17.2929L14.5858 12L9.29289 6.70711C8.90237 6.31658 8.90237 5.68342 9.29289 5.29289C9.68342 4.90237 10.3166 4.90237 10.7071 5.29289L16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L10.7071 18.7071C10.3166 19.0976 9.68342 19.0976 9.29289 18.7071Z"
                                            fill="black"/>
                                    </svg>


                                </div>


                            </div>)
                        }
                    </div>

                </div>
            </>
        )
    }
}

export default MediaCenter