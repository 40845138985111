import React from "react";


import {Link, useNavigate} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import InputText from "../../../components/FormComponents/InputText";
import InputEmail from "../../../components/FormComponents/InputEmail";
import InputPhone from "../../../components/FormComponents/InputPhone";
import InputPassword from "../../../components/FormComponents/InputPassword";
import CheckBox from "../../../components/FormComponents/CheckBox";
import axios from "axios";

import {useDispatch} from "react-redux";

export default function ThanksPage() {
    const {
        register, handleSubmit, getValues, setError, formState: {errors},
    } = useForm();

    const navigate = useNavigate()

    return (

        <section className="bg-gray-50 pt-80 pb-80 min-h-screen">
            <div
                className="flex flex-col items-center justify-center  mx-auto h-full px-12 md:px-24 lg:w-3/4 xl:w-1/2 xl:px-0  ">
                <div
                    className="w-full bg-white rounded-lg shadow">

                    <div className="pt-20 pb-16 ">
                        <div className={'pb-4'}>
                            <h3 className={'text-center text-3xl'}>Your order is placed</h3>
                            <h3 className={'text-center text-lg font-medium pt-2'}>Register to access the dashboard.</h3>
                        </div>


                        <Link to="/registration"
                              className="features_template__section-row-list_item-cta mx-auto">Go to registration page
                            </Link>


                    </div>

                </div>
            </div>


        </section>
    );

}
