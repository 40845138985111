import './HeaderSecond.scss'
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { customerLogout } from "../../tools/store/slices/customerSlice";
import ShoppingCart from "./components/ShoppingCart/ShoppingCart";
import { closeCart } from "../../tools/store/slices/shippingCartSlice";
import { useScrollDirection } from "../../hooks/useScrollDirection";
import { motion, useAnimation } from 'framer-motion';

const HeaderSecond = () => {
    const [activeItem, setActiveItem] = useState('home');

    const customer = useSelector((state) => state.customer)
    const dispatch = useDispatch()
    const [mobileDropDown, setMobileDropDown] = useState(false)
    const [data, setData] = useState()

    const scrollDir = useScrollDirection();
    const controls = useAnimation();

    const shippingCart = useSelector(state => state.shippingCart)

    function getData() {
        axios.get('https://strapi.platformz.us/api/header?populate=deep')
            .then(res => {
                setData(res.data.data)
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        getData()
    }, []);

    let down = false;
    let up = false;

    useEffect(() => {
        if (scrollDir === 'down' && down === false) {
            // controls.start({ top: downPos, transition: { duration: 1 } });
            controls.start({ marginTop: -120, transition: { duration: 1 } });
        } else if (scrollDir === 'up' && up === false) {
            // controls.start({ top: upPos, transition: { duration: 1 } });
            controls.start({ marginTop: 0, transition: { duration: 1 } });
        }
    }, [scrollDir, controls, down, up]);

    if (data) {
        return (<>
            {shippingCart.isOpen && <ShoppingCart close={() => dispatch(closeCart())} />}

            <motion.header
                animate={controls}
                className={'bg-white fixed top-0 left-0 right-0  px-3 lg:container lx:px-0 py-4 z-[100] mx-auto lg:rounded-br-2xl lg:rounded-bl-2xl shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#33BBED,0_0_15px_#33BBED,0_0_30px_#33BBED]'}>
                <div className="flex justify-between items-center">
                    <Link to={'/'} className={'w-[180px]'} onClick={() => setActiveItem(false)}>
                        <img
                            src={"https://strapi.platformz.us" + data.attributes.navigation.logotype.data.attributes.url}
                            className="" alt="Platformz logotype" />
                    </Link>

                    <nav className={'hidden 2xl:flex gap-4 w-fit ml-16 '}>
                        {data && data.attributes.navigation.navigation_link.map((item, index) => {
                            return (
                                <Link
                                    className={`text-[19px] font-medium hover:underline underline-offset-4 ${activeItem === item.path ? "text-blue-400 underline-offset-4  underline decoration-blue-400" : ""}`}
                                    to={item.path} onClick={() => {
                                        setActiveItem(item.path)
                                    }}
                                    key={index}>{item.name}</Link>)
                        })}
                    </nav>

                    <div className={'flex items-center gap-8'}>
                        {/* <div className="w-[48px] cursor-pointer relative" onClick={() => dispatch(openCart())}>
                            {shippingCart.products && shippingCart.products.length > 0 && <div
                                className={'absolute w-[30px] h-[30px] bg-red-600 rounded-full flex items-center justify-center text-white -top-1 -left-1'}>
                                {shippingCart.products.length}
                            </div>}

                            <img
                                src={"https://strapi.platformz.us" + data.attributes.navigation.shopping_cart.data.attributes.url}
                                alt="" />

                        </div> */}

                        <div

                            onClick={() => {
                                setMobileDropDown(!mobileDropDown)
                            }}
                            className={`headerSecond__burger z-40 ${mobileDropDown ? "headerSecond__burger--active" : ''} 2xl:hidden`}>
                            <span></span>
                        </div>

                    </div>


                    <div className={'hidden 2xl:flex items-center gap-2'}>
                        {customer.accessToken ? <><Link onClick={() => setActiveItem(false)} to={'/dashboard'}
                            className={'block px-5 py-2 bg-black text-white rounded-lg font-medium text-bese h-fit hover:opacity-70'}>Dashboard</Link>
                            <span onClick={() => {
                                setActiveItem(false)
                                dispatch(customerLogout())
                            }}
                                className={' block px-5 py-2 bg-black text-white rounded-lg font-medium text-base -fit hover:opacity-70 cursor-pointer'}>Logout</span></> : <><Link onClick={() => setActiveItem(false)} to={'/login'}
                                    className={'block px-5 py-2 bg-black text-white rounded-lg font-medium text-bese h-fit hover:opacity-70'}>Sign
                                    In</Link>
                            <Link onClick={() => setActiveItem(false)} to={'/registration'}
                                className={' block px-5 py-2 bg-black text-white rounded-lg font-medium text-base -fit hover:opacity-70'}>Sign
                                Up</Link></>}

                    </div>
                </div>

                {mobileDropDown &&
                    <div className={'h-screen animate-[show-menu-top-bottom_0.5s_ease-in-out_1]'}>

                        <nav className={'flex flex-col gap-6 mt-12'}>
                            {data && data.attributes.navigation.navigation_link.map((item, index) => {
                                return (
                                    <Link
                                        className={`text-3xl font-medium hover:underline underline-offset-4 ${activeItem === item.path ? "text-blue-400 underline-offset-4  underline decoration-blue-400" : ""}`}
                                        to={item.path} onClick={() => {
                                            setActiveItem(item.path)
                                            setMobileDropDown(!mobileDropDown)
                                        }}
                                        key={index}>{item.name}</Link>)
                            })}
                        </nav>

                        <div className={'flex items-center gap-2 mt-6'}>
                            {customer.accessToken ? <><Link onClick={() => setActiveItem(false)} to={'/dashboard'}
                                className={'block px-5 py-2 bg-black text-white rounded-lg font-medium text-2xl h-fit hover:opacity-70 w-full'}>Dashboard</Link>

                                <span onClick={() => {
                                    setActiveItem(false)
                                    setMobileDropDown(!mobileDropDown)
                                    dispatch(customerLogout())
                                }} to={'/registration'}
                                    className={' block px-5 py-2 bg-black text-white rounded-lg font-medium text-2xl -fit hover:opacity-70  w-full cursor-pointer'}>Logout</span>
                            </> : <>
                                <Link
                                    onClick={() => {
                                        setActiveItem(false)
                                        setMobileDropDown(!mobileDropDown)
                                    }} to={'/login'}
                                    className={'block px-5 py-2 bg-black text-white rounded-lg font-medium text-2xl h-fit hover:opacity-70 w-full'}
                                >
                                    Sign In
                                </Link>
                                <Link
                                    onClick={() => {
                                        setActiveItem(false)
                                        setMobileDropDown(!mobileDropDown)
                                    }}
                                    to={'/registration'}
                                    className={' block px-5 py-2 bg-black text-white rounded-lg font-medium text-2xl -fit hover:opacity-70  w-full'}>Sign
                                    Up
                                </Link>
                            </>}

                        </div>

                    </div>}

            </motion.header>


        </>)
    }
}

export default HeaderSecond