import React from "react";

function FormContainer({children}) {
   return (
       <div
           className="flex flex-col items-center justify-center h-full mx-auto  md:px-24 lg:w-3/4 xl:w-1/2 xl:px-0">
           <div
               className="w-full bg-white rounded-lg shadow">
               <div className="p-8 pt-20 pb-20 ">
                   {children}

               </div>
           </div>
       </div>
   )
}

export default FormContainer