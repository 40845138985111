import CustomCursor from "../../../components/CustomCursor/CustomCursor";
import React, { useState, useEffect, useRef } from "react";
import { Stream } from "@cloudflare/stream-react";


const Showreel2 = ({ data }) => {
    // eslint-disable-next-line
    const fullscreenVideo = useRef(null)
    const [isHovered, setIsHovered] = useState(false);
    const [cursorAnim, setCursorAnim] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);
    const [currentVideo, setCurrentVideo] = useState('658ecd1b55da3cae748182502c21bddc')
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const updateScreenWidth = () => {
        setScreenWidth(window.innerWidth);
    };

    useEffect(() => {

        window.addEventListener('resize', updateScreenWidth);


        return () => {
            window.removeEventListener('resize', updateScreenWidth);
        };
    }, []);


    useEffect(() => {
        if (fullscreen === true) {

            setCurrentVideo(data.video_with_sound_id)
        } else if (screenWidth <= 1200 && fullscreen === false) {

            setCurrentVideo(data.video_without_sound_mobile_id)
        } else if (screenWidth >= 1200) {

            setCurrentVideo(data.video_without_sound_id)
        }
        // eslint-disable-next-line
    }, [screenWidth, fullscreen])

    return (
        <>


            {isHovered && <CustomCursor animation={cursorAnim} fullscreen={fullscreen} isHovered={isHovered} />}
            <section
                className={fullscreen ? 'showreel2__fullscreen' : 'showreel2__content'}


            >
                {screenWidth <= 1200 && fullscreen ?
                    <div className="showreel2__close-btn" onClick={() => setFullscreen(!fullscreen)}><span></span></div>
                    : null}
                {screenWidth <= 1200 && fullscreen !== true && <div
                    className={`custom-cursor`}
                    style={{
                        left: 0,
                        right: 0,
                        width: 200,
                        height: 200,
                        fontSize: 30,
                        margin: 'auto',
                        top: `30%`,
                        opacity: 1,
                        zIndex: 99,
                        position: 'absolute',
                        display: 'flex'
                    }}>
                    {fullscreen ? <p>close</p> : <p>play <br />reel</p>}
                </div>}
                {screenWidth > 1200}
                <div className={'showreel2__ov'} onClick={() => setFullscreen(!fullscreen)} onMouseEnter={() => {

                    setCursorAnim(false)
                    setIsHovered(true)
                }}
                    onMouseLeave={() => {
                        setCursorAnim(true)
                        setTimeout(() => {
                            setIsHovered(false)
                        }, 200)

                    }}></div>

                <Stream controls={fullscreen ? true : false}

                    src={currentVideo}
                    autoplay={true} loop muted={fullscreen ? false : true} responsive={true} />
            </section>


        </>
    )
}

export default Showreel2