import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import axios from "axios";

const Metadata = () => {
    const [data, setData] = useState(null)
    useEffect(() => {
        axios.get('https://strapi.platformz.us/api/meta-data?populate=deep')
            .then(res => {
                setData(res.data.data)
            })
            .catch(err => console.error(err))
    }, [])
    if (data) {
        return (
            <Helmet>
                {/*   <!-- HTML Meta Tags --> */}
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <link rel="icon" href={`https://strapi.platformz.us${data.attributes.favicon.data.attributes.url}`} />
                <link rel="apple-touch-icon" href={`https://strapi.platformz.us${data.attributes.site_logo_192.data.attributes.url}`} />
                <title>{data.attributes.site_title}</title>
                <meta name="description" content={data.attributes.site_description} />


                {/*   <!-- Facebook Meta Tags --> */}

                <meta property="og:url" content="https://www.platformz.us/" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={data.attributes.site_title} />
                <meta property="og:description" content={data.attributes.site_description} />
                <meta property="og:image" content={`https://strapi.platformz.us${data.attributes.share_preview.data.attributes.url}`} />

                {/* <!-- Twitter Meta Tags --> */}

                <meta name="twitter:card" content={`https://strapi.platformz.us${data.attributes.share_preview.data.attributes.url}`} />
                <meta property="twitter:domain" content="platformz.us" />
                <meta property="twitter:url" content="https://www.platformz.us/" />
                <meta name="twitter:title" content={data.attributes.site_title} />
                <meta name="twitter:description" content={data.attributes.site_description} />
                <meta name="twitter:image" content={`https://strapi.platformz.us${data.attributes.share_preview.data.attributes.url}`} />


                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />


            </Helmet>
        )
    }



}
export default Metadata