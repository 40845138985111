import {Button} from "../Button/Button";
import React, {useEffect} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {userSetTokenAsync} from "../../tools/store/slices/customerSlice";
import axios from "axios";
import InputText from "../FormComponents/InputText";
import InputEmail from "../FormComponents/InputEmail";
import InputPhone from "../FormComponents/InputPhone";
import InputPassword from "../FormComponents/InputPassword";
import CheckBox from "../FormComponents/CheckBox";
import {url} from "../../tools/api/api";
import {addToCard, updateShoppingCart} from "../../tools/store/slices/shippingCartSlice";

function LoginForm() {
    const navigate = useNavigate()
    const customer = useSelector(state => state.customer)
    const shippingCart = useSelector(state => state.shippingCart)
    const dispatch = useDispatch()
    const {
        register, handleSubmit, getValues, setError, formState: {errors},
    } = useForm();


    useEffect(() => {
        if (customer.type === 'customer' && customer.quote_id && shippingCart.products && shippingCart.products.length > 0) {
            console.log('shippingCart.products ', shippingCart.products)
            setTimeout(() => {
                shippingCart.products.forEach(product => {
                    dispatch(addToCard(product.sku, null, null, null, product.name, product.price))
                })
                dispatch(updateShoppingCart())
                navigate('/checkout')
            }, 1000)

        } else if (customer.type === 'customer' && customer.accessToken) {
            navigate('/dashboard')
        }

    }, [customer])


    const onSubmit = (data) => {
        axios.post(`${url}/api/customer/login-customer`, {email: data.email, password: data.password})
            .then(response => {
                dispatch(userSetTokenAsync(response.data.data))

            })
            .catch(error => {
                console.error(error.response.data)
                setError('password', {type: 'custom', message: error.response.data.error.message});
            })
    };

    const methods = useForm()
    return (
        <FormProvider {...methods}>
            <form
                className="space-y-12"
                onSubmit={handleSubmit(onSubmit)}
            >


                <InputEmail register={register} name={'email'} label={"Email"} placeholder={'email@platformz.us'}
                            errors={errors}
                            options={{required: 'This field is required'}}/>

                <div>
                    <InputPassword register={register} name={'password'} label={"Password"}
                                   placeholder={'•••••••••••••'}
                                   errors={errors}
                                   options={{required: 'This field is required'}}/>

                    <p className="text-xl font-light text-gray-500 pt-4">
                        Forgot your password ?<Link to="/forgot-password"
                                                    className="text-xl font-light  text-blue-400 hover:underline"> Reset
                        the password here.</Link>
                    </p>
                </div>


                <button className="features_template__section-row-list_item-cta mx-auto">Login
                </button>
            </form>
            <p className="text-xl font-light text-gray-500 pt-24">
                You don't have an account? <Link to="/registration"
                                                 className="text-xl font-light  text-blue-400 hover:underline">Register
                here</Link>
            </p>
        </FormProvider>
    );
}

export default LoginForm;
