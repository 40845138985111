import React, {useEffect} from "react";
import verificationCompleteImg from "../../../assets/pic/verification_complete_img.svg";
import "./VerificationComplete.scss";
import {useSearchParams} from "react-router-dom";
import axios from "axios";

export default function VerificationComplete() {
    // /verification-approved?type=Phone
    // /verification-approved?type=Email
    const searchParams = useSearchParams();

    useEffect(() => {
        if(searchParams.get('code')) {
            axios.get(`https://strapi.platformz.us/api/auth/email-confirmation`, {params: {
                    confirmation: searchParams.get('code')
                }})
                .then(response => {

                })
                .catch(error => {
                    console.error(error)
                })
        }
    })

    return (
        <div className="verification_complete container">
            <h3 className="over-title"> {(searchParams.get('type') === "Phone" && 'Verification') || (searchParams.get('type') === "Email" && 'Successfully') }</h3>
            <h3 className="title__h3"> {(searchParams.get('type') === "Phone" && 'Complete') || (searchParams.get('type') === "Email" && 'Verified')}</h3>
            <p className="text-regular">{searchParams.get('type') === "Phone" && 'Login with your social account'}</p>
            <div className="verification_complete_box_img">
                <img src={verificationCompleteImg} alt="#"/>
            </div>
            <h4>Congratulations!</h4>
            <p className="text-regular">
            {(searchParams.get('type') === "Phone" && `Your Phone No have been verified!Your Registration has been completed!`) || (searchParams.get('type') === "Email" && `Your Email ID have been verified!`)}
            </p>
            <a href="/dashboard">{(searchParams.get('type') === "Phone" && `Go to Dashboard`) || (searchParams.get('type') === "Email" && `Next`)}</a>
        </div>
    );
}
