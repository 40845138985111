import loader from '../../assets/gifs/loader-hires.gif'

const Loader = ({isActive}) => {

    if(isActive) {
        return (
            <div className={`loader`}>
                <div className="loader__animation">
                    <img src={loader} alt="loading"/>
                </div>
            </div>
            )
    }
    
}

export default Loader