import React, { useEffect, useState } from "react";
import "./Policy.scss";
import Container from "../../../components/Container/Container";
import axios from "axios";
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { useLocation } from "react-router-dom";
import { strapi_url } from "../../../tools/api/api";
import PageBanner from "../../../components/PageBanner/PageBanner";
import CKEditorRender from "../../../components/CKEditorText/CKEditorRender";


export default function Policy() {
    const [data, setData] = useState(null)
    const [currentPolice, setCurrentPolice] = useState(null)
    const location = useLocation();
    const currentPath = location.pathname;


    useEffect(() => {
        switch (currentPath) {
            case '/privacy-policy':
                setCurrentPolice('Privacy Policy');
                break;
            case '/terms-of-service':
                setCurrentPolice('Terms of Service');
                break;
            case '/refund-policy':
                setCurrentPolice('Refund Policy');
                break;
            case '/referral-policy':
                setCurrentPolice('Referral Policy');
                break;
            default:
                setCurrentPolice(null);
        }

    }, [currentPath])

    function getPolicyData(currentPolicy) {
        axios.get('https://strapi.platformz.us/api/police?populate=deep')
            .then(res => {
                console.log(res.data.data.attributes)
                const cp = res.data.data.attributes.policy.find(item => item.title_h1 === currentPolicy)
                if (cp) {
                    setData(cp)
                }

            })
            .catch(err => console.error(err))
    }


    useEffect(() => {
        getPolicyData(currentPolice)

    }, [currentPolice]);
    if (data && currentPolice) {
        const content = { picture: data.banner, title: data.title_h1, over_title: data.over_title, page_banner_style: data.page_banner_style, picture_mobile: data.picture_mobile }
        return (
            <div className="privecy_policy">
                <PageBanner content={content} />


                <div className={'container'}>
                    <div className="py-12">
                        <CKEditorRender text={data.text} />


                    </div>
                </div>

            </div>
        );
    } else {
        return (
            <div></div>
        )
    }
}
