import React from "react";
import bgShowcase from "../../../assets/bg/bgShowcase.png";
import ShowcaseBl1 from "../../../assets/pic/Showcase_bl_1.png";
import ShowcaseBl1Logo from "../../../assets/pic/Showcase_bL1_logo.png";
import ShowcaseBl4 from "../../../assets/pic/Showcase_bl_4.png";
import ShowcaseBl4Logo from "../../../assets/pic/Showcase_bL4_logo.png";
import ShowcaseBl5 from "../../../assets/pic/Showcase_bl_5.png";
import ShowcaseBl5Logo from "../../../assets/pic/Showcase_bL5_logo.png";
import ShowcaseBl8 from "../../../assets/pic/Showcase_bl_8.png";
import ShowcaseBl8Logo from "../../../assets/pic/Showcase_bL8_logo.png";
import "./Showcase.scss";

export default function Showcase() {
  return (
    <div className="Showcase">
      <div className="wrapper_showcase">
        <img src={bgShowcase} alt="bg" />
      </div>
      <div className="content_showcas container">
        <h3 className="over-title">PlatformZ</h3>
        <h3 className="title__h3">Showcase</h3>
        <div className="box_block_showcas">
          <div className="block_showcas_picture bl1">
            <img src={ShowcaseBl1} alt="#" />
            <img src={ShowcaseBl1Logo} alt="#" />
          </div>
          <div className="block_showcas_content bl2">
            <h5>Pet Travel</h5>
            <h3>InCabinPets</h3>
            <h4>Unique Automation/ Customization</h4>
            <h6>Features</h6>
            <ul>
              <li>Real Time Pet and Flight Tracking</li>
              <li>2FA, OTP, Identity Verification, reCAPTCHA</li>
              <li>Instant Flight Booking and Payments</li>
              <li>Automated ID Card Just in time order and printing</li>
              <li>ales and Marketing CRM</li>
              <li>Support / Ticketing / Live Chat</li>
              <li>FAQ / Knowledgebase</li>
              <li>Forum / Newsletter / Media Center</li>
              <li>Accounting Automation</li>
            </ul>
            <a href="/">Explore More</a>
          </div>
          <div className="block_showcas_content bl3">
            <h5>Skatewear</h5>
            <h3>RockerZ Skatewear</h3>
            <h4>Highly Customized Magento Store</h4>
            <h6>Features</h6>
            <ul>
              <li>Magento Custom Store</li>
              <li>Custom Photoshop Templates</li>
              <li>Worldwide Language and Currencies - Animated Sliders</li>
              <li>Carousels</li>
              <li>Layered Navigation</li>
              <li>Social Links and Pages</li>
              <li>Customer Brochure and Product Sheet PDFs</li>
              <li>Support Ticketing with </li>
            </ul>
            <a href="/">Explore More</a>
          </div>
          <div className="block_showcas_picture bl4">
            <img src={ShowcaseBl4} alt="#" />
            <img src={ShowcaseBl4Logo} alt="#" />
          </div>
          <div className="block_showcas_picture bl5">
            <img src={ShowcaseBl5} alt="#" />
            <img src={ShowcaseBl5Logo} alt="#" />
          </div>
          <div className="block_showcas_content bl6">
            <h5>Internet Radio Platform</h5>
            <h3>Station Digitalr</h3>
            <h4>Internet Radio With Enterprise Class Architechture</h4>
            <h6>Features</h6>
            <ul>
              <li>100% Custom Code on Angular/React</li>
              <li>HTML 5 / Java based - Device Agnostic</li>
              <li>Lyric Automation</li>
              <li>23mm Songs Playlist Radio</li>
              <li>Mobile and Desktop Platforms:</li>
              <li>IOS/DROID Apps</li>
              <li>Desktop Version</li>
              <li>Loyalty and Rewards Program</li>
              <li>Ad Supported</li>
            </ul>
            <a href="/">Explore More</a>
          </div>
          <div className="block_showcas_content bl7">
            <h5>Bitcoin Mining Platform</h5>
            <h3>Hashing Space</h3>
            <h4>Customized Platform For Bitcoin Mining Operations</h4>
            <h6>Features</h6>
            <ul>
              <li>Custom Coding</li>
              <li>Miner Config and Monitoring Platform</li>
              <li>Designed Cutting Edge Data Centers</li>
              <li>CAD/CAM Blueprinting</li>
              <li>Video Walkthroughs of Data Centers</li>
              <li>Stratum and P2P Custom Pool Platform</li>
              <li>
                Analyzes mining data and provides insights into mining
                performance and
              </li>
            </ul>

            <a href="/">Explore More</a>
          </div>
          <div className="block_showcas_picture bl8">
            <img src={ShowcaseBl8} alt="#" />
            <img src={ShowcaseBl8Logo} alt="#" />
          </div>
        </div>
      </div>
    </div>
  );
}
