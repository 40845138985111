import React, {useState} from "react"


export function InputPassword({register, options, name, label, placeholder, errors}) {
    const [showPassword, setShowPassword] = useState(false)

    const style = ' bg-gray-50 border text-xl border-gray-300 text-gray-900 rounded-lg font-medium   focus:ring-primary-600 focus:border-primary-600 block w-full p-6 pt-8 pb-8'

    return (
        <div className={'w-full relative'}>
            <label
                className="block mb-4 text-xl font-medium text-gray-900">{label}</label>
            <input type={showPassword ? 'text' : 'password'} {...register(name, {
                ...options,
                pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    message: "The password must contain at least one lowercase letter, one uppercase letter, one number, one special character, and be at least 8 characters long. For example, Abcdefgh1$.",
                },
            })} placeholder={placeholder} className={style}/>
            <div className={'absolute right-4 top-20 bottom-0 cursor-pointer hover:opacity-70 pt-1'}
                 onClick={() => setShowPassword(!showPassword)}>

                {showPassword ?
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
                            fill="black"/>
                        <path
                            d="M21.8944 11.5528C19.7362 7.23635 15.9031 5 12 5C8.09687 5 4.26379 7.23635 2.10557 11.5528C1.96481 11.8343 1.96481 12.1657 2.10557 12.4472C4.26379 16.7637 8.09687 19 12 19C15.9031 19 19.7362 16.7637 21.8944 12.4472C22.0352 12.1657 22.0352 11.8343 21.8944 11.5528ZM12 17C9.03121 17 5.99806 15.3792 4.12966 12C5.99806 8.62078 9.03121 7 12 7C14.9688 7 18.0019 8.62078 19.8703 12C18.0019 15.3792 14.9688 17 12 17Z"
                            fill="black"/>
                    </svg>
                    :
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.70711 3.29289C4.31658 2.90237 3.68342 2.90237 3.29289 3.29289C2.90237 3.68342 2.90237 4.31658 3.29289 4.70711L5.71706 7.13127C4.28639 8.20737 3.03925 9.68543 2.10557 11.5528C1.96481 11.8343 1.96481 12.1657 2.10557 12.4472C4.26379 16.7637 8.09687 19 12 19C13.5552 19 15.0992 18.645 16.5306 17.9448L19.2929 20.7071C19.6834 21.0976 20.3166 21.0976 20.7071 20.7071C21.0976 20.3166 21.0976 19.6834 20.7071 19.2929L4.70711 3.29289ZM15.0138 16.428C14.0343 16.8112 13.0134 17 12 17C9.03121 17 5.99806 15.3792 4.12966 12C4.94721 10.5214 5.98778 9.3794 7.14838 8.56259L9.29237 10.7066C9.10495 11.0982 9 11.5369 9 12C9 13.6569 10.3431 15 12 15C12.4631 15 12.9018 14.8951 13.2934 14.7076L15.0138 16.428Z"
                            fill="#0D0D0D"/>
                        <path
                            d="M18.5523 13.8955C19.0353 13.3402 19.4784 12.7088 19.8703 12C18.0019 8.62078 14.9687 7 12 7C11.888 7 11.7759 7.00231 11.6637 7.00693L9.87939 5.22258C10.5774 5.07451 11.2875 5 12 5C15.9031 5 19.7362 7.23635 21.8944 11.5528C22.0352 11.8343 22.0352 12.1657 21.8944 12.4472C21.3504 13.5352 20.7 14.491 19.9689 15.3121L18.5523 13.8955Z"
                            fill="#0D0D0D"/>
                    </svg>
                }


            </div>
            {errors[name] && (
                <p className={'text-base text-red-600 font-light pt-4'}>{errors[name].message}</p>)}

        </div>


    )
}

export default InputPassword