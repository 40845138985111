import React from 'react'
import './Copyright.scss'

const Copyright = () => {
  return (
    <div className='copyright-container'>
      <p>Copyright © 2023 Plaformz | All Rights Reserved</p>
    </div>
  )
}

export default Copyright