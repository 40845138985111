import {FormProvider, useForm} from "react-hook-form";
import {Button} from "../../../components/Button/Button";
import btnPhone from "../../../assets/shapes/Group_phone.png";
import btnEmail from "../../../assets/shapes/Group_email.png";
import btnWebsite from "../../../assets/shapes/Group_inter.png";
import imgBgForm from "../../../assets/pic/shape_887.png";
import "./ContactUs.scss";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {strapi_url} from "../../../tools/api/api";
import PageBanner from "../../../components/PageBanner/PageBanner";
import CKEditorRender from "../../../components/CKEditorText/CKEditorRender";
import InputText from "../../../components/FormComponents/InputText";
import InputEmail from "../../../components/FormComponents/InputEmail";
import InputPassword from "../../../components/FormComponents/InputPassword";
import CheckBox from "../../../components/FormComponents/CheckBox";
import {Link} from "react-router-dom";
import InputPhone from "../../../components/FormComponents/InputPhone";
import InputFiles from "../../../components/FormComponents/InputFiles";
import InputTextArea from "../../../components/FormComponents/InputTextArea";
import GoogleProtectedInfo from "../../../components/Forms/GoogleProtectedInfo/GoogleProtectedInfo";

const ContactUs = () => {


    const {
        register, reset, handleSubmit, getValues, setError, formState: {errors, isSubmitSuccessful},
    } = useForm();
    const methods = useForm()
    const [content, setContent] = useState(null)

    const [formStatus, setFormStatus] = useState(false)
    const style = 'bg-gray-50 border text-xl border-gray-300 text-gray-900 rounded-lg font-medium  focus:ring-primary-600 focus:border-primary-600 block w-full p-6 pt-8 pb-8 border-0 outline-none '


    const onSubmit = (data) => {
        console.log("form", data);
        reset({...data})
        if (data) {
            //https://devapi.platformz.us/
            axios.post("https://devapi.platformz.us/api/sendgrid/send-message",
                {
                    name: data.name,
                    email: data.email,
                    phone: data.phone,
                    budget: data.budget,

                }, {})
                .then(res => {

                    if (res.data.status === "Email sent") {
                        setFormStatus(true)
                        setTimeout(() => {
                            setFormStatus(false)
                        }, 5000)
                    }
                })

                .catch(err => {
                    console.error(err)
                })
        }


    };
    useEffect(() => {
        setTimeout(() => {
            if (!isSubmitSuccessful) {
                return
            }

            reset({
                email: "",
                name: "",
                phone: "",
                budget: "",
            })
        }, 2000)
    }, [isSubmitSuccessful])
    useEffect(() => {
        axios.get(`${strapi_url}/api/page-contact-us?populate=deep`)
            .then((response) => {
                setContent(response.data.data.attributes)
                console.log(response.data.data.attributes)

            })
            .catch(error => console.error(error))
    }, [])
    if (content) {


        return (
            <>
                <PageBanner content={content.banner}/>

                <div className="contact_with_us">
                    <div className="text-center my-12 ">
                        <div className="text-center w-3/4 ml-auto mr-auto">
                            <CKEditorRender text={content.section_01}/>
                        </div>
                    </div>
                    <div className="wrapper_contact_with_us_link">
                        <div className="contact_with_us_link container">
                            <a href={`tel:${content.phone}`}>
                                <img src={btnPhone} alt="phone"/>
                                <h4 className={'text-3xl'}>Phone</h4>
                                <h3 className={'text-2xl'}>{content.phone}</h3>
                            </a>
                            <a href={`mailto:${content.email}`}>
                                <img src={btnEmail} alt="email"/>
                                <h4 className={'text-3xl'}>Email</h4>
                                <h3 className={'text-2xl'}>{content.email}</h3>
                            </a>
                            <a href="/">
                                <img src={btnWebsite} alt="internet"/>
                                <h4 className={'text-3xl'}>Website</h4>
                                <h3 className={'text-2xl'}>www.platformz.us</h3>
                            </a>
                        </div>
                        <div className="bg_contact_with_us_link"></div>
                    </div>
                    <div className="contact_with_us_enquiry container">

                        <div className="text-center w-3/4 ml-auto mr-auto pt-6 pb-12 ">
                            <CKEditorRender text={content.section_02}/>
                        </div>
                        <div className="lg:flex justify-center gap-8">
                            <FormProvider {...methods}>
                                <form
                                    className="space-y-12 lg:w-1/2"
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <InputText register={register} name={'name'} label={"Name"}
                                               placeholder={'Name'}
                                               errors={errors}
                                               options={{required: 'This field is required'}}/>
                                    <section className={'space-y-12 lg:flex lg:space-x-12 lg:space-y-0'}>
                                        <InputPhone register={register} name={'phone'} label={"Your Phone"}
                                                    placeholder={'+14845691532'}
                                                    errors={errors}
                                                    options={{required: 'This field is required'}}/>


                                        <InputEmail register={register} name={'email'} label={"Email"}
                                                    placeholder={'email@platformz.us'}
                                                    errors={errors}
                                                    options={{required: 'This field is required'}}/>
                                    </section>

                                    <div className={'w-full'}>
                                        <label className="block mb-4 text-xl font-medium text-gray-900 ">Budget</label>
                                        <select
                                            {...register('budget')}
                                            className={style} defaultValue={null}
                                            value={null}>

                                            <option selected value={'50k'}>50k</option>
                                            <option selected value={'100k'}>100k</option>
                                            <option selected value={'200k+'}>200k+</option>


                                        </select>
                                        {errors['budget'] && (
                                            <p className={'text-base text-red-600 font-light pt-4'}>{errors['budget'].message}</p>)}

                                    </div>


                                    <InputTextArea register={register} name={'message'} label={"Your message"}
                                                   placeholder={'Your message'}
                                                   errors={errors}
                                                   options={{required: 'This field is required'}}/>


                                    <GoogleProtectedInfo/>

                                    <button className={`features_template__section-row-list_item-cta`}>Create
                                        Account
                                    </button>
                                </form>

                            </FormProvider>

                            <div className="wrapper_img_form">
                                <img src={imgBgForm} alt="#"/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    ;
}

export default ContactUs;
