import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {updaterCartId, updateUserData} from "./customerSlice";
import {useSelector} from "react-redux";

export const updateShoppingCart = (accessToken, cart_id) => async (dispatch, getState) => {
    const customer = getState().customer;
    if (customer.type === 'customer') {
        try {
            const getShoppingCart = await
                axios.get('https://store.platformz.us/rest/V1/carts/mine/items', {
                    headers: {
                        Authorization: 'Bearer ' + customer.accessToken
                    }
                })
            if (getShoppingCart) {
                dispatch(updateItems({products: getShoppingCart.data}))
            }

        } catch (error) {
            console.error("Error when retrieving user data:", error);
        }

    } else if (customer.type === 'guest') {
        try {
            const getShoppingCart = await axios.get(`https://store.platformz.us/rest/V1/guest-carts/${customer.cart_id}`)
            if (getShoppingCart) {
                dispatch(updateItems({products: getShoppingCart.data.items}))
            }

        } catch (error) {
            console.error("Error when retrieving user data:", error);
        }
    }


};


export const addToCard = (sku, quote_id, accessToken, cart_id, name, price, type) => async (dispatch, getState) => {
    // const user = useSelector(state => state.customer.type)
    const customer = getState().customer;

    const url = 'https://store.platformz.us/rest/V1/carts/mine/items'

    const product = {
        cartItem: {
            sku: sku, qty: 1, quote_id: quote_id,
        }
    }


    if (customer.type === 'customer') {
        try {
            const postItem = await axios.post(url, product, {
                headers: {
                    Authorization: 'Bearer ' + customer.accessToken
                }
            })
            if (postItem) {
                dispatch(addToLocalCart({name: name, price: price, sku: sku, item_id: postItem.data.item_id}))
            }
        } catch (e) {

        }
    } else if (customer.type === 'guest') {
        try {
            const postItem = await axios.post(`https://store.platformz.us/rest/V1/guest-carts/${customer.cart_id}/items`, {
                cartItem: {
                    sku: product.cartItem.sku, qty: 1,
                }
            })
            if (postItem) {
                dispatch(addToLocalCart({name: name, price: price, sku: sku, item_id: postItem.data.item_id}))
            }
        } catch (e) {

        }
    }
    // dispatch(openCart())

    



};

export const createCartId = () => async (dispatch, getState) => {
    const customer = getState().customer;

    if (customer.type === 'guest' && customer.cart_id === null) {
        const getCartId = await axios.post(`https://store.platformz.us/rest/V1/guest-carts/`, {})
        if (getCartId) {
            dispatch(updaterCartId({cart_id: getCartId.data}))
        }

    }

}


const shippingCart = createSlice({
    name: "shippingCart",
    initialState: {
        isOpen: false,
        items: null,
        items_count: null,
        products: null
    },
    reducers: {
        updateItems(state, action) {
            state.products = action.payload.products
        },
        addToLocalCart(state, action) {
            const product = {
                name: action.payload.name,
                item_id: action.payload.item_id,
                price: action.payload.price,
                sku: action.payload.sku,
            }


            if (state.products) {

                const isHave = state.products.filter((item) => item.sku === product.sku)
                console.log(isHave)
                if (isHave.length === 0) {
                    state.products = [...state.products, product]
                }
            } else {
                state.products = [product]
            }

        },
        removeFromLocalCart(state, action) {
            const skuToRemove = action.payload.sku;

            if (state.products) {
                const updatedProducts = state.products.filter((item) => item.sku !== skuToRemove);
                state.products = updatedProducts;
            }
        },
        openCart(state) {
            state.isOpen = true
        },
        closeCart(state) {
            state.isOpen = false
        }
    },
});

export const {updateItems, removeFromLocalCart, openCart, addToLocalCart, closeCart} = shippingCart.actions;

export default shippingCart.reducer;
