import './PageBanner.scss'
import {strapi_url} from "../../tools/api/api";
import React from "react";
import CKEditorRender from "../CKEditorText/CKEditorRender";

function PageBanner({content, titleCenter}) {
    if (content) {
        let bgColor = content.page_banner_style ? content.page_banner_style.banner_color : '#f8fbff'
        return (
            <div className="page_banner__bg" style={{backgroundColor: bgColor}}>
                <picture>
                    <source media="(min-width:1024px)"
                            srcSet={content.picture.data !== null  && strapi_url + content.picture.data.attributes.url}/>

                    <img
                        src={content.picture_mobile.data && strapi_url + content.picture_mobile.data.attributes.url}
                        alt=""/>
                </picture>


                <div className={'container'}>
                    <div
                        className={`page_banner__title w-full `}>
                        {content.ck_title &&
                            <CKEditorRender text={content.ck_title}/> }

                    </div>
                </div>


            </div>
        )
    }
}

export default PageBanner