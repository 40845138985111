import React, {useEffect, useState} from "react"


export function InputText({register, options, name, label, placeholder, errors, data}) {
    const [currentRegion, setCurrentRegion] = useState(null)
    const [regionsList, setRegionList] = useState(null)
    useEffect(() => {
        if(currentRegion) {
            const getRegions = data.filter(i => i.id === currentRegion)
            setRegionList(getRegions[0].available_regions)
        }
    }, [currentRegion]); useEffect(() => {

    }, [regionsList]);
    const style = 'bg-gray-50 border text-xl border-gray-300 text-gray-900 rounded-lg font-medium  focus:ring-primary-600 focus:border-primary-600 block w-full p-6 pt-8 pb-8 border-0 outline-none '
    if (data) {
        return (
            <div className={'xl:grid-cols-2 grid gap-4'}>
                <div className={'w-full'}>
                    <label className="block mb-4 text-xl font-medium text-gray-900 ">{label}</label>
                    <select
                        {...register(name, options)} placeholder={placeholder} className={style} defaultValue={null}
                        value={null}
                    onChange={(e) => setCurrentRegion(e.target.value)}>
                        {/*<option selected value={false}>{placeholder}y</option>*/}
                        <option selected value={null}>{placeholder}</option>
                        {data.map((item, index) => {
                            return (
                                <option value={item.id}>{item.full_name_english}</option>
                            )
                        })}

                    </select>
                    {errors[name] && (
                        <p className={'text-base text-red-600 font-light pt-4'}>{errors[name].message}</p>)}

                </div>
                <div className={'w-full'}>
                    <label className="block mb-4 text-xl font-medium text-gray-900 ">Region</label>
                    <select
                        {...register(name === 'shipping_country' ?'shipping_region' : 'region', options)} placeholder={placeholder} className={style} defaultValue={null}
                        value={null}>
                        {/*<option selected value={false}>{placeholder}y</option>*/}
                        <option selected value={null}>{placeholder}</option>
                        {regionsList && regionsList.map((item, index) => {
                            return (
                                <option value={item.id}>{item.name}</option>
                            )
                        })}

                    </select>
                    {errors[name === 'shipping_country' ?'shipping_region' : 'region'] && (
                        <p className={'text-base text-red-600 font-light pt-4'}>{errors[name].message}</p>)}

                </div>
            </div>


        )
    }
}

export default InputText