import React, { useEffect, useState } from 'react'
import Sidebar from '../../../../components/CustomerDashboard/Sidebar/Sidebar'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Topbar from '../Topbar/Topbar';
import Subscribe from '../../../Landing/sections/Subscribe';
import Copyright from '../../../../components/CustomerDashboard/Copyright/Copyright';

const AccountInformation = () => {
    const [customer, setCustomer] = useState(null);
    const [currentPass, setCurrentPass] = useState('');
    const [newPass, setNewPass] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [isChangePass, setIsChangePass] = useState(false);
    const [isChangeEmail, setIsChangeEmail] = useState(false);
    const [isShowPass, setIsShowPass] = useState(false);

    const customerState = useSelector((state) => state.customer);
    const navigate = useNavigate();

    const getCustomerData = async (token) => {
        try {
            const res = await axios.get('https://store.platformz.us/rest/V1/customers/me', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (res.data) {
                setCustomer(res.data);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }
    useEffect(() => {
        if (customerState.accessToken) {
            getCustomerData(customerState.accessToken);
        }
    }, [customerState.accessToken])


    const updateCustomer = () => {
        let payload = {
            "customer": {
                "email": customer?.email,
                "firstname": customer?.firstname,
                "lastname": customer?.lastname
            },
            "password": currentPass
        }


        axios.put(
            `https://store.platformz.us/rest/V1/customers/me`,
            payload,
            {
                headers: {
                    'Authorization': `Bearer ${customerState.accessToken}`,
                    'Content-Type': 'application/json',
                },
            }
        )
            .then(response => {
                // console.log('Customer updated successfully:', response.data);

                if (newPass !== '' && newPass === confirmPass && isValid) {
                    axios.put(
                        `https://store.platformz.us/rest/V1/customers/me/password`,
                        {
                            "currentPassword": currentPass,
                            "newPassword": newPass
                        },
                        {
                            headers: {
                                'Authorization': `Bearer ${customerState.accessToken}`,
                                'Content-Type': 'application/json',
                            },
                        }
                    )
                        .then(response => {
                            console.log('Customer updated password successfully:', response.data);
                        })
                        .catch(error => {
                            console.error('Error updating customer:', error);
                        });
                }

                setCustomer(response.data)
                navigate('/dashboard')
            })
            .catch(error => {
                console.error('Error updating customer:', error);
            });
    }

    const handleValidatePass = (e) => {
        const newPassword = e.target.value;
        setNewPass(newPassword);

        // Regular expressions for uppercase, lowercase, number, and special character
        const uppercaseRegex = /[A-Z]/;
        const lowercaseRegex = /[a-z]/;
        const numberRegex = /[0-9]/;
        const specialCharRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

        // Check if the password meets all criteria
        const hasUppercase = uppercaseRegex.test(newPassword);
        const hasLowercase = lowercaseRegex.test(newPassword);
        const hasNumber = numberRegex.test(newPassword);
        const hasSpecialChar = specialCharRegex.test(newPassword);
        const isLengthValid = newPassword.length >= 8;

        setIsValid(
            hasUppercase && hasLowercase && hasNumber && hasSpecialChar && isLengthValid
        );
    };

    return (

        <>
            <Topbar />
            <div className={'min-h-screen'}>
                <div className={'page-main'}>
                    <Sidebar />
                    <div className={'main-content'} style={{
                        width: "calc(100% - 385px)"
                    }}>

                        <div>
                            <h1 className='text-4xl font-medium'>Edit <span>Account Information</span></h1>
                        </div>

                        <div className='mb-12 default-address edit-account'>
                            <div>
                                <div className='change-name' style={{ clear: "none", marginRight: "25px" }}>
                                    <div className='pb-2 mt-10 mb-6 section-title'>
                                        <h1 className='text-4xl font-medium'>Change Name</h1>
                                        <hr></hr>
                                    </div>
                                    <div className='input-field'>
                                        <label>First Name</label><br />
                                        <input type="text" style={{ border: "1px solid grey" }} value={customer?.firstname} onChange={(e) => setCustomer({ ...customer, firstname: e.target.value })} />
                                    </div>
                                    <div className='input-field'>
                                        <label>Last Name</label><br />
                                        <input type="text" style={{ border: "1px solid grey" }} value={customer?.lastname} onChange={(e) => setCustomer({ ...customer, lastname: e.target.value })} />
                                    </div>

                                    <div className='input-check'>
                                        <input type="checkbox" style={{ border: "1px solid grey" }} checked={isChangeEmail} onChange={(e) => setIsChangeEmail(!isChangeEmail)} />
                                        <label>Change Email</label>
                                    </div>

                                    <div className='input-check'>
                                        <input type="checkbox" style={{ border: "1px solid grey" }} checked={isChangePass} onChange={(e) => setIsChangePass(!isChangePass)} />
                                        <label>Change Password</label>
                                    </div>

                                </div>
                                {isChangeEmail || isChangePass ?
                                    <div className='change-name' style={{ clear: "none", marginRight: "25px" }}>
                                        <div className='pb-2 mt-10 mb-6 section-title'>
                                            <h1 className='text-4xl font-medium'>{isChangeEmail || isChangePass ? "Change" : ""} {isChangeEmail && "Email"} {isChangeEmail && isChangePass && "and"} {isChangePass && "Password"}</h1>
                                            <hr></hr>
                                        </div>
                                        {isChangeEmail &&
                                            <div className='input-field'>
                                                <label>Email</label><br />
                                                <input type="text" style={{ border: "1px solid grey" }} value={customer?.email} onChange={(e) => setCustomer({ ...customer, email: e.target.value })} />
                                            </div>
                                        }

                                        <div className='input-field'>
                                            <label>Current Password</label><br />
                                            <input type={isShowPass ? "text" : "password"} style={{ border: "1px solid grey" }} value={currentPass} onChange={(e) => setCurrentPass(e.target.value)} />
                                        </div>

                                        {isChangePass &&
                                            <div className='input-field'>
                                                <label>New Password</label><br />
                                                <input type={isShowPass ? "text" : "password"} style={{ border: "1px solid grey" }} value={newPass} onChange={(e) => handleValidatePass(e)} />
                                                {!isValid && (
                                                    <p style={{ maxWidth: "200px" }}>
                                                        Password must contain at least one uppercase letter, one lowercase letter,
                                                        one number, one special character, and have a minimum length of 8 characters.
                                                    </p>
                                                )}
                                            </div>
                                        }

                                        {isChangePass &&
                                            <div className='input-field'>
                                                <label>Confirm New Password</label><br />
                                                <input type={isShowPass ? "text" : "password"} style={{ border: "1px solid grey" }} value={confirmPass} onChange={(e) => setConfirmPass(e.target.value)} />
                                            </div>
                                        }

                                        <div className='input-check'>
                                            <input type="checkbox" style={{ border: "1px solid grey" }} checked={isShowPass} onChange={(e) => setIsShowPass(!isShowPass)} />
                                            <label>Show Password</label>
                                        </div>

                                    </div>
                                    : ""
                                }

                                <div className="mt-6 mb-6" style={{ clear: "both" }}>
                                    <button className='save' style={{ padding: "10px 20px", color: "#fff", backgroundColor: "#1979c3", borderRadius: "5px" }} onClick={updateCustomer}>Save</button>
                                </div>
                            </div>
                        </div>

                        <div style={{
                            marginRight: "-85px",
                            marginLeft: "-100px",
                            marginTop: "126px",
                            marginBottom: "-126px",
                        }}>
                            <Subscribe />
                            <Copyright />
                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}

export default AccountInformation