import React, {useState} from "react";

export function InputFiles({register, options, name, label, placeholder, errors}) {
    const [totalFileSize, setTotalFileSize] = useState(0);
    const [fileSizeExceeded, setFileSizeExceeded] = useState(false);
    const maxTotalFileSize = 10 * 1024 * 1024; // 30 MB in bytes

    const style =
        "bg-gray-50 border text-xl border-gray-300 text-gray-900 rounded-lg font-medium   focus:ring-primary-600 focus:border-primary-600 block w-full p-6 pt-8 pb-8";

    const handleFileInputChange = (event) => {
        const files = event.target.files;
        let totalSize = 0;

        // Calculate total file size
        for (let i = 0; i < files.length; i++) {
            totalSize += files[i].size;
        }

        setTotalFileSize(totalSize);

        // Update fileSizeExceeded state
        setFileSizeExceeded(totalSize > maxTotalFileSize);
    };

    return (
        <div className={"w-full"}>
            <label className="block mb-4 text-xl font-medium text-gray-900">{label}</label>
            <input
                type="file"
                multiple={true}
                {...register(name, options)}
                placeholder={placeholder}
                className={style}
                onChange={handleFileInputChange}
            />
            <p className={"text-xl  text-gray-600  font-light pt-4"}>
                The total file size should not exceed (10 MB).
            </p>
            {errors[name] && <p className={"text-xl text-red-600 font-light pt-4"}>{errors[name].message}</p>}
            {fileSizeExceeded && (
                <p className={"text-xl text-red-600 font-light pt-4"}>
                    Total file size exceeds the limit (10 MB).
                </p>
            )}
           
        </div>
    );
}

export default InputFiles;
