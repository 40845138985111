import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { updaterCartId } from "../../../tools/store/slices/guestSlice";
import { userSetTokenAsync } from "../../../tools/store/slices/customerSlice";
import {
  addToCard,
  openCart,
  updateShoppingCart,
} from "../../../tools/store/slices/shippingCartSlice";
import { useNavigate } from "react-router";

const Plan = ({ data, plansType, nextStep }) => {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const guest = useSelector((state) => state.guest);
  const navigate = useNavigate();

  if (data) {
    return (
      <div
        className="plan"
        style={{
          border: `1px solid ${
            data.attributes.primary_color
              ? data.attributes.primary_color
              : "#4ea0f2"
          }`,
        }}
      >
        <p className="text-2xl">{data.attributes.name}</p>
        <p className="text-base text-gray-500 font-medium pt-4">
          {data.attributes.short_desc}
        </p>
        <div className="py-4">
          <span className={"text-5xl"}>${data.attributes.price}</span>
          <span className={"text-xl text-gray-500"}>
            /
            {plansType === "e-commerce-development-subscriptions"
              ? "Monthly"
              : "One time"}
          </span>
        </div>
        <button
          onClick={() => {
            dispatch(
              addToCard(
                data.attributes.sku,
                customer.quote_id,
                customer.accessToken,
                guest.cart_id,
                data.attributes.name,
                data.attributes.price
              )
            );
            navigate(nextStep);
          }}
          className="features_template__section-row-list_item-cta"
          style={{
            backgroundColor: data.attributes.primary_color
              ? data.attributes.primary_color
              : "#4ea0f2",
          }}
        >
          Add to cart
          <svg
            width="8"
            height="14"
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.292893 13.7071C-0.0976311 13.3166 -0.0976312 12.6834 0.292893 12.2929L5.58579 7L0.292893 1.70711C-0.0976317 1.31658 -0.0976317 0.683417 0.292893 0.292893C0.683417 -0.0976315 1.31658 -0.0976315 1.70711 0.292893L7.70711 6.29289C8.09763 6.68342 8.09763 7.31658 7.70711 7.70711L1.70711 13.7071C1.31658 14.0976 0.683418 14.0976 0.292893 13.7071Z"
              fill="#FFFFFF"
            />
          </svg>
        </button>
        <ul className="plan__features">
          {data.attributes.plan_features.map((item, index) => {
            return (
              <li className="plan__item">
                {!item.no_included ? (
                  <>
                    <div
                      className="plan__item-ico"
                      style={{
                        backgroundColor: data.attributes.primary_color
                          ? data.attributes.primary_color
                          : "#4ea0f2",
                      }}
                    >
                      <svg
                        width="18"
                        height="14"
                        viewBox="0 0 18 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.6644 0.252601C18.0772 0.619519 18.1143 1.25159 17.7474 1.66437L7.08075 13.6644C6.89099 13.8779 6.61898 14 6.33334 14C6.04771 14 5.7757 13.8779 5.58593 13.6644L0.252601 7.66437C-0.114317 7.25159 -0.077136 6.61952 0.335647 6.2526C0.74843 5.88568 1.3805 5.92286 1.74742 6.33565L6.33334 11.4948L16.2526 0.335647C16.6195 -0.077136 17.2516 -0.114317 17.6644 0.252601Z"
                          fill="#ffffff"
                        />
                      </svg>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="plan__item-ico plan__item-ico--not-include">
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L8.41421 7L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L7 8.41421L1.70711 13.7071C1.31658 14.0976 0.683417 14.0976 0.292893 13.7071C-0.0976311 13.3166 -0.0976311 12.6834 0.292893 12.2929L5.58579 7L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </>
                )}

                <p className={"text-base text-[#616b7e]"}>{item.name}</p>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
};

export default Plan;
