import React, { useEffect, useState } from "react";


import { useNavigate, useSearchParams } from "react-router-dom";

import axios from "axios";


import FormContainer from "../../../components/FormContainer/FormContainer";
import { useSelector } from "react-redux";

export default function EmailVerification() {
    const navigate = useNavigate()
    const customer = useSelector(state => state.customer)


    const [loading, setLoading] = useState(true)

    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();

    const code = searchParams.get('code')
    const status = searchParams.get('status')


    function sendEmail(email) {
        axios.post(`https://devapi.platformz.us/api/customer/email-verification`, { to: email })
            .then(response => {

            })
            .catch(error => console.error(error))
    }

    const handleTimeout = () => {
        setLoading(false);
    };

    function sendCode(email, code, type, customer_id) {
        axios.post(`https://devapi.platformz.us/api/customer/verify-otp`, {
            to: email,
            code: code,
            type: type,
            customer_id: customer_id
        })
            .then(response => {


                if (response.data.status === 'approved') {
                    setTimeout(handleTimeout, TIMEOUT_DELAY);
                    navigate('/email-verification?code=done')
                }


            })
            .catch(error => {
                console.error(error)
                setTimeout(handleTimeout, TIMEOUT_DELAY);
                navigate('/email-verification?code=error')
            })
    }

    const TIMEOUT_DELAY = 5000;


    useEffect(() => {


        if (status === 'pending' && customer.email) {
            sendCode(customer.email, code, 'email', customer.id);
        } else if (status === 'sending' && customer.email) {
            sendEmail(customer.email);
        } else if (code === 'done') {

            navigate('/otp');
        }


    }, [status, customer]);


    return (

        <section className="bg-gray-50 pt-80 pb-80">
            <FormContainer>
                {code ? (<>
                    {loading ? <><>
                        <div className={'w-fit mx-auto'}>
                            <div role="status">
                                <svg aria-hidden="true"
                                    className="w-20 h-20 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor" />
                                    <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill" />
                                </svg>

                            </div>


                        </div>
                        <p className="text-center leading-tight tracking-tight  font-medium md:text-4xl mt-6">
                            Data validation...
                        </p></>
                    </> : <>
                        {code !== 'done' ? (
                            <>
                                <div className={'w-fit mx-auto'}>
                                    <svg width="100" height="100" viewBox="0 0 100 100" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M91.6667 58.3333H83.3334V30.1583L50.3 59.7417L16.6667 30.0667V79.1667H62.5V87.5H12.5C11.395 87.5 10.3352 87.061 9.55376 86.2796C8.77236 85.4982 8.33337 84.4384 8.33337 83.3333V16.6667C8.33337 15.5616 8.77236 14.5018 9.55376 13.7204C10.3352 12.939 11.395 12.5 12.5 12.5H87.5C88.6051 12.5 89.6649 12.939 90.4463 13.7204C91.2277 14.5018 91.6667 15.5616 91.6667 16.6667V58.3333ZM18.7959 20.8333L50.2542 48.5917L81.2584 20.8333H18.7959Z"
                                            fill="#0D0D0D" />
                                        <path
                                            d="M98.0666 86.0708L89.225 77.2333L98.0666 68.3958L92.1708 62.5L83.3333 71.3417L74.4958 62.5L68.6 68.3958L77.4416 77.2333L68.6 86.0708L74.4958 91.9667L83.3333 83.125L92.1708 91.9667L98.0666 86.0708Z"
                                            fill="#FF0000" />
                                    </svg>


                                </div>

                                <p className="text-center leading-tight tracking-tight  font-medium md:text-4xl mt-8">
                                    Something went wrong - <span onClick={() => {
                                        navigate('/email-verification')
                                        sendEmail(customer.email);
                                    }}
                                        className=" text-blue-400  font-medium md:text-4xl cursor-pointer hover:underline"
                                    > resend the verification link </span>
                                </p>


                            </>) : (<>
                                <div className={'w-fit mx-auto'}>
                                    <svg width="100" height="81" viewBox="0 0 100 81" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M88.9442 49.3637H80.0498V19.2916L44.7923 50.8668L8.89442 19.1938V71.5997H53.3665V80.4941H4.44721C3.26774 80.4941 2.13657 80.0256 1.30256 79.1916C0.468544 78.3576 0 77.2264 0 76.0469V4.89155C0 3.71207 0.468544 2.58091 1.30256 1.74689C2.13657 0.91288 3.26774 0.444336 4.44721 0.444336H84.497C85.6765 0.444336 86.8077 0.91288 87.6417 1.74689C88.4757 2.58091 88.9442 3.71207 88.9442 4.89155V49.3637ZM11.1669 9.33876L44.7434 38.9661L77.8351 9.33876H11.1669Z"
                                            fill="black" />
                                        <path
                                            d="M62.261 64.4114L77.9863 80.1368L100 58.1231L93.7116 51.8347L77.9863 67.5601L68.5538 58.1231L62.261 64.4114Z"
                                            fill="#00FF19" />
                                    </svg>


                                </div>
                                <p className="text-center leading-tight tracking-tight  font-medium md:text-4xl mt-8">
                                    Your mail has been verified.
                                </p></>)}


                    </>}
                </>) : (<>
                    <div className={'w-fit mx-auto'}>
                        <svg width="100" height="auto" viewBox="0 0 24 25" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1_230)">
                                <path
                                    d="M22 20.4513C21.9982 20.714 21.8931 20.9653 21.7075 21.1511C21.5219 21.3369 21.2706 21.4422 21.008 21.4443H2.992C2.72881 21.4441 2.4765 21.3393 2.29049 21.1531C2.10448 20.9669 2 20.7145 2 20.4513V19.4443H20V7.74434L12 14.9443L2 5.94434V4.44434C2 4.17912 2.10536 3.92477 2.29289 3.73723C2.48043 3.54969 2.73478 3.44434 3 3.44434H21C21.2652 3.44434 21.5196 3.54969 21.7071 3.73723C21.8946 3.92477 22 4.17912 22 4.44434V20.4513ZM4.434 5.44434L12 12.2543L19.566 5.44434H4.434ZM0 15.4443H8V17.4443H0V15.4443ZM0 10.4443H5V12.4443H0V10.4443Z"
                                    fill="#0D0D0D" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1_230">
                                    <rect width="24" height="24" fill="white" transform="translate(0 0.444336)" />
                                </clipPath>
                            </defs>
                        </svg>

                    </div>
                    <p className="text-center leading-tight tracking-tight  font-medium md:text-2xl  mt-6">
                        We have sent you a link to verify your email to your inbox
                    </p>
                    <button className={'features_template__section-row-list_item-cta mx-auto mt-4'} onClick={() => sendEmail(customer.email)}>Resend</button>
                </>)}

            </FormContainer>


        </section>
    )
        ;

}
