import React, { useEffect, useState } from 'react'
import Sidebar from '../../../../../components/CustomerDashboard/Sidebar/Sidebar'
import './../../Dashboard.scss'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { log } from 'three'
import Topbar from '../../Topbar/Topbar'
import Subscribe from '../../../../Landing/sections/Subscribe'
import Copyright from '../../../../../components/CustomerDashboard/Copyright/Copyright'

const AddressEdit = () => {
    const { id } = useParams();
    const [addressId, setAddressId] = useState(id);
    const [addressData, setAddressData] = useState({
        region: {
            region_code: '',
            region: '',
            region_id: null,
        },
        country_id: '',
        street: [],
        telephone: '',
        postcode: '',
        city: '',
        firstname: '',
        lastname: '',
        default_shipping: false,
        default_billing: false,
    });
    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedRegion, setSelectedRegion] = useState('');
    const [isRegionSelect, setIsRegionSelect] = useState(false);
    const [customer, setCustomer] = useState(null);

    const customerState = useSelector((state) => state.customer);

    const navigate = useNavigate();


    useEffect(() => {
        setAddressId(id);
    }, [id])
    const getCustomerData = async (token) => {
        try {
            const res = await axios.get('https://store.platformz.us/rest/V1/customers/me', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (res.data) {
                setCustomer(res.data);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }
    useEffect(() => {
        if (customerState.accessToken) {
            getCustomerData(customerState.accessToken);
        }
    }, [customerState.accessToken])

    useEffect(() => {
        if (addressId && customer) {
            const address = customer.addresses.find(address => address.id == addressId);
            if (address) {
                setAddressData(address)
            }
            // console.log(addressData, address);
        }
    }, [customer, addressId])


    useEffect(() => {
        // Fetch countries
        axios.get('https://store.platformz.us/rest/V1/directory/countries')
            .then(response => {
                setCountries(response.data);
            })
            .catch(error => {
                console.error('Error fetching countries:', error);
            });
    }, []);

    const handleCountryChange = (event) => {
        const selectedCountryCode = event.target.value;
        setSelectedCountry(selectedCountryCode);

        axios.get(`https://store.platformz.us/rest/V1/directory/countries/${selectedCountryCode}`)
            .then(response => {
                const countryRegions = response.data;
                setRegions(countryRegions.available_regions);

                setIsRegionSelect(countryRegions.available_regions?.length > 0);
                setSelectedRegion('');
            })
            .catch(error => {
                console.error('Error fetching regions:', error);
            });

        setAddressData({ ...addressData, country_id: event.target.value })
    };

    const addCustomerAddress = () => {
        let payload = {
            "customer": {
                "email": customer?.email,
                "firstname": customer?.firstname,
                "lastname": customer?.lastname,
                "addresses": customer.addresses.filter(address => address.id != addressId)
            }
        };
        payload.customer?.addresses.push({
            ...addressData
        })
        console.log(payload);
        axios.put(
            `https://store.platformz.us/rest/V1/customers/me`,
            payload,
            {
                headers: {
                    'Authorization': `Bearer ${customerState.accessToken}`,
                    'Content-Type': 'application/json',
                },
            }
        )
            .then(response => {
                //   console.log('Customer address added successfully:', response.data);
                setAddressData({
                    region: {
                        region_code: '',
                        region: '',
                        region_id: null,
                    },
                    country_id: '',
                    street: [],
                    telephone: '',
                    postcode: '',
                    city: '',
                    firstname: '',
                    lastname: '',
                    default_shipping: false,
                    default_billing: false,
                });
                navigate('/dashboard/address');
            })
            .catch(error => {
                console.error('Error adding customer address:', error);
            });
    };
    const handleStreetChange = (e, index) => {
        const updatedStreet = [...addressData.street];

        updatedStreet[index] = e.target.value;
        setAddressData({ ...addressData, street: updatedStreet });
    };

    return (

        <>
            <Topbar />
            <div className={'min-h-screen'}>
                <div className={'page-main'}>
                    <Sidebar />
                    <div className={'main-content'} style={{
                        width: "calc(100% - 385px)"
                    }}>

                        <div>
                            <h1 className='text-4xl font-medium'>Add New Address</h1>
                        </div>
                        <div>
                            <div style={{ clear: "none", float: "left", marginRight: "25px" }}>
                                <h3>Contact Information</h3>
                                <div>
                                    <label>First Name</label><br />
                                    <input type="text" style={{ border: "1px solid grey" }} value={addressData.firstname} onChange={(e) => setAddressData({ ...addressData, firstname: e.target.value })} />
                                </div>
                                <div>
                                    <label>Last Name</label><br />
                                    <input type="text" style={{ border: "1px solid grey" }} value={addressData.lastname} onChange={(e) => setAddressData({ ...addressData, lastname: e.target.value })} />
                                </div>
                                <div>
                                    <label>Company</label><br />
                                    <input type="text" style={{ border: "1px solid grey" }} value={addressData.company} onChange={(e) => setAddressData({ ...addressData, company: e.target.value })} />
                                </div>
                                <div>
                                    <label>Phone Number</label><br />
                                    <input type="text" style={{ border: "1px solid grey" }} value={addressData.telephone} onChange={(e) => setAddressData({ ...addressData, telephone: e.target.value })} />
                                </div>
                            </div>
                            <div style={{ clear: "none", float: "left" }}>
                                <h3>Address</h3>

                                <div>
                                    <label>Street Address</label><br />
                                    <input type="text" style={{ border: "1px solid grey" }} value={addressData.street[0]} onChange={(e) => handleStreetChange(e, 0)} /><br />
                                    <input type="text" style={{ border: "1px solid grey" }} value={addressData.street[1]} onChange={(e) => handleStreetChange(e, 1)} />
                                </div>

                                <div>
                                    <label>Country</label><br />
                                    <select id="country" style={{ border: "1px solid grey" }} onChange={handleCountryChange} value={selectedCountry ? selectedCountry : addressData.country_id}>
                                        <option value="">Select Country</option>
                                        {countries.map(country => (
                                            <option key={country.id} value={country.id}>
                                                {country.full_name_locale}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div>
                                    <label>State/Province</label><br />
                                    {isRegionSelect ? (
                                        <select id="region" style={{ border: "1px solid grey" }} onChange={(e) => setAddressData((prevAddressData) => ({
                                            ...prevAddressData,
                                            region: {
                                                ...prevAddressData.region,
                                                region_id: e.target.value,
                                                region: '',
                                            },
                                        }))} value={addressData.region.region_id}>
                                            <option value="">Select Region</option>
                                            {regions.map(region => (
                                                <option key={region.id} value={region.id}>
                                                    {region.name}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        <input
                                            style={{ border: "1px solid grey" }}
                                            type="text"
                                            id="region"
                                            onChange={(e) => {
                                                setAddressData((prevAddressData) => ({
                                                    ...prevAddressData,
                                                    region: {
                                                        ...prevAddressData.region,
                                                        region_id: null,
                                                        region: e.target.value,
                                                    },
                                                }))
                                            }}
                                            value={addressData.region.region}
                                        />
                                    )}
                                </div>

                                <div>
                                    <label>City</label><br />
                                    <input type="text" style={{ border: "1px solid grey" }} value={addressData.city} onChange={(e) => setAddressData({ ...addressData, city: e.target.value })} />
                                </div>

                                <div>
                                    <label>Zip/Postal Code</label><br />
                                    <input type="text" style={{ border: "1px solid grey" }} value={addressData.postcode} onChange={(e) => setAddressData({ ...addressData, postcode: e.target.value })} />
                                </div>

                                <div>
                                    <input type="checkbox" style={{ border: "1px solid grey" }} checked={addressData.default_billing} onChange={(e) => setAddressData({ ...addressData, default_billing: !addressData.default_billing })} />
                                    <label>Use as my default billing address</label>
                                </div>

                                <div>
                                    <input type="checkbox" style={{ border: "1px solid grey" }} checked={addressData.default_shipping} onChange={(e) => setAddressData({ ...addressData, default_shipping: !addressData.default_shipping })} />
                                    <label>Use as my default shipping address</label>
                                </div>



                            </div>
                            <button onClick={addCustomerAddress} style={{ clear: "both", float: "left", padding: "10px", color: "#fff", backgroundColor: "#1979c3" }}>Save Address</button>
                        </div>

                        <div style={{
                            marginRight: "-85px",
                            marginLeft: "-100px",
                            marginTop: "126px",
                            marginBottom: "-126px",
                        }}>
                            <Subscribe />
                            <Copyright />
                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}

export default AddressEdit