import React, {useEffect, useState} from "react";

import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';

import {Link, useNavigate} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";

import axios from "axios";


import {useDispatch, useSelector} from "react-redux";

import {userSetTokenAsync} from "../../../tools/store/slices/customerSlice";
import {updateShoppingCart} from "../../../tools/store/slices/shippingCartSlice";

export default function PaymentMethod() {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const stripe = useStripe();
    const elements = useElements();
    const [shipppinStatus, setShipppinStatus] = useState(false)
    const [shipppingData, setShipppinData] = useState(null)
    const [shipppinGuestData, setGuestShipppingData] = useState(null)
    const [regions, setRegions] = useState(null)
    const customer = useSelector((state) => state.customer)
    const guest = useSelector((state) => state.guest)
    const [hideShippingAddress, setHideShippingAddress] = useState(false)
    const {
        register, handleSubmit, formState: {errors},
    } = useForm();

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleSubmitStripe = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (!stripe || !elements) {
            return;
        }

        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });



        if (error) {
            setError(error.message);
        } else {
            const url = 'https://store.platformz.us/rest/V1/carts/mine/payment-information'
            // Handle successful payment
            if(guest.cart_id !== null) {
                const place_order = await axios.post(`https://store.platformz.us/rest/V1/guest-carts/${guest.cart_id}/payment-information`, {
                    cartId: guest.cart_id,
                    paymentMethod: {
                        method: "stripe_payments",
                        additional_data: {
                            payment_element: true,
                            payment_method: paymentMethod.id,
                            manual_authentication: "card"
                        }
                    },
                    billingAddress: {...shipppinGuestData.addressInformation.billing_address},
                    email: shipppinGuestData.addressInformation.billing_address.email


                },)

                if(place_order.status === 200) {
                    setLoading(false);
                    navigate('/checkout/thanks')
                }
            } else {
                const place_order = await axios.post(url, {
                    cartId: customer.quote_id,
                    paymentMethod: {
                        method: "stripe_payments",
                        additional_data: {
                            payment_element: true,
                            payment_method: paymentMethod.id,
                            manual_authentication: "card"
                        }
                    }
                }, {
                    headers: {
                        Authorization: "Bearer " + customer.accessToken
                    }
                })

                if(place_order.status === 200) {
                    dispatch(userSetTokenAsync(customer.accessToken));
                    setTimeout(() => {
                        setLoading(false);
                        dispatch(updateShoppingCart())
                        navigate('/checkout/thanks')
                    },1000)
                }
            }

        }
    };








    useEffect(() => {
        axios.get(`https://store.platformz.us/rest/V1/directory/countries`,)
            .then(res => {
                const region_sort = res.data.filter((i) => i.available_regions)
                setRegions(region_sort)
                console.log('region_sort', region_sort)
            })
    }, []);

    useEffect(() => {
        if(shipppinStatus === true) {
            window.scrollTo(0, 0);
        }
    }, [shipppinStatus]);

    const methods = useForm()
    return (

        <section className="w-full bg-[#F3F3F3] p-12">
                            <>
                                <div className={' mb-8'}>
                                    <p className={'text-2xl text-[#005EA5]'}>Credit Card</p>
                                    <p className={'text-[#434343] font-medium'}>
                                        Safe and instants payments with your credit or debit card
                                    </p>
                                </div>
                                <form onSubmit={handleSubmitStripe} className={''}>
                                    <div className={'pb-4 border-b'}>
                                        <CardElement options={{
                                            style: {
                                                base: {
                                                    fontSize: '22px',

                                                }
                                            }
                                        }}/>
                                    </div>
                                    <div className={'flex items-center flex-col lg:flex-row justify-center gap-6 mt-12'}>
                                        <Link to={'/checkout/details'}
                                                className={'features_template__section-row-list_item-cta w-full max-w-[300px]'}>
                                            Back
                                        </Link>
                                        <button type="submit" disabled={!stripe || loading}
                                                className={'features_template__section-row-list_item-cta w-full max-w-[300px]'}>
                                            {loading ? 'Processing...' : 'Pay'}
                                        </button>
                                    </div>
                                    {error &&
                                        <div>{error}</div>
                                    }
                                </form>


                            </>


        </section>
    );

}