import { strapi_url } from "../../tools/api/api";
import Marquee from "react-fast-marquee";

const TechnologySlider = ({ content }) => {
  if (content) {
    return (
      <Marquee speed={200} className={`p-0 md:py-12 overflow-hidden flex justify-between`}>
        {content.logo.map((item, index) => (
          <img
            className={"h-[150px] w-auto aspect-auto mx-10 object-center"}
            key={index}
            src={strapi_url + item.logo_pic?.data?.attributes?.url}
            alt={item.logo_name}
          />
        ))}
      </Marquee>
    );
  }
};

export default TechnologySlider;
