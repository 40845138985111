import React, { useEffect, useState, useRef } from "react";
import { strapi_url } from "../../../tools/api/api";
import axios from "axios";
import PageBanner from "../../../components/PageBanner/PageBanner";
import CKEditorRender from "../../../components/CKEditorText/CKEditorRender";
import { motion } from "framer-motion";
import AnimateIn from "../../../components/AnimateIn/AnimateIn";

const Processes = () => {
  const [content, setContent] = useState(null);
  const imageRefs = useRef([]);

  useEffect(() => {
    axios
      .get(`${strapi_url}/api/page-process?populate=deep`)
      .then((response) => {
        setContent(response.data.data.attributes);
      })
      .catch((error) => console.error("error", error));
  }, []);

  useEffect(() => {
    if (content) {
      imageRefs.current.forEach((imageRef, index) => {
        const height = imageRef.clientHeight;
        imageRef.parentNode.style.height = `${height}px`;
      });
    }
  }, [content]);

  if (!content) {
    return null; // Render nothing until content is fetched
  }

  console.log(
    "card?.icon?.data?.attributes?.url",
    content?.feature_cards[0]?.icon?.data?.attributes?.url
  );

  return (
    <div>
      <PageBanner content={content.banner} />
      <div className="md:px-24 px-6 md:mt-10 ">
        <CKEditorRender text={content.title} />
      </div>
      <div className="h-full flex flex-col items-center">
        {/* <div className="flex flex-col items-center">
          {content.stages.map((stage, index) => (
            <div
              key={index}
              className="relative w-full flex flex-col items-center"
            >
              {stage?.image?.data?.attributes.url && (
                <img
                  ref={(ref) => (imageRefs.current[index] = ref)}
                  className="w-auto absolute md:px-15"
                  style={{ top: stage?.top ? parseInt(stage?.top) : "" }}
                  src={strapi_url + stage.image.data.attributes.url}
                  alt=""
                />
              )}
            </div>
          ))}
        </div> */}
        {content?.stage_desktop?.data?.attributes?.url && (
          <img
            className="w-auto md:px-20 hidden md:block"
            src={strapi_url + content?.stage_desktop?.data?.attributes?.url}
            alt=""
          />
        )}
        {content?.stage_mobile?.data?.attributes?.url && (
          <img
            className="w-auto px-5 block md:hidden"
            src={strapi_url + content?.stage_mobile?.data?.attributes?.url}
            alt=""
          />
        )}
        <div className="md:px-24 px-6 md:mt-10 ">
          <CKEditorRender text={content.section_2} />
        </div>
        <div className="my-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 px-10 md:px-24">
          {content?.feature_cards?.map((card, idx) => (
            <div className="border border-[#959292] p-5 rounded-xl flex items-center">
              {card?.icon && (
                <img
                  className="w-[50px] mr-3"
                  src={strapi_url + card?.icon?.data?.attributes?.url}
                  alt=""
                />
              )}
              <h1 className="font-light">{card?.title}</h1>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Processes;
