import React, {useEffect, useState} from "react";


import {Link, useNavigate, useSearchParams} from "react-router-dom";

import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {userSetTokenAsync} from "../../../tools/store/slices/customerSlice";
import {addToCard, updateShoppingCart} from "../../../tools/store/slices/shippingCartSlice";


export default function OAuthPage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const customer = useSelector(state => state.customer)
    const shippingCart = useSelector(state => state.shippingCart)
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    const GOOGLE_ACCESS_TOKEN = searchParams.get('code')
    const provider = JSON.parse(localStorage.getItem('provider'))


    const [error, setError] = useState(false)


    // function getUserInfo(token) {
    //     console.log('token', token)
    //     axios.get('https://store.platformz.us/rest/V1/customers/me', {
    //         headers: {
    //             Authorization: `Bearer ${token}`
    //         }
    //     })
    //         .then(res => {
    //             if(res.data.extension_attributes.is_otp_verification === false) {
    //                 localStorage.removeItem('provider');
    //
    //                 setTimeout(() => {
    //                     navigate('/otp?type=oauth')
    //                 }, 5000)
    //             }
    //             else {
    //                 setTimeout(() => {
    //                     navigate('/dashboard')
    //                 }, 5000)
    //             }
    //
    //         })
    //         .catch(err => console.error(err))
    // }


    useEffect(() => {
        if (customer.type === 'customer' && customer.quote_id && shippingCart.products && shippingCart.products.length > 0) {
            console.log('shippingCart.products ', shippingCart.products)
            setTimeout(() => {
                shippingCart.products.forEach(product => {
                    dispatch(addToCard(product.sku, null, null, null, product.name, product.price))
                })
                dispatch(updateShoppingCart())
                navigate('/checkout')
            },0)

        }else if(customer.type === 'customer' && customer.accessToken) {
            navigate('/otp?type=oauth')
        }

    },[customer])

    useEffect(() => {
        if (GOOGLE_ACCESS_TOKEN) {
            axios.post('https://store.platformz.us/rest/V2/elightwalk/sociallogin/login', {
                input: {
                    provider: provider,
                    code: GOOGLE_ACCESS_TOKEN
                }
            }, { withCredentials: true,})
                .then((response) => {
                    if (response !== null) {
                        dispatch(userSetTokenAsync(response.data))


                    }
                })
                .catch((err) => {

                    setTimeout(() => {
                        setError(true)
                    }, 1000)

                })
        }
    }, [GOOGLE_ACCESS_TOKEN])

    return (
        <>
            {error && <section className="bg-gray-50 pt-80 pb-80 min-h-screen">
                <div
                    className="flex flex-col items-center justify-center mx-auto h-full  mx-auto h-full px-12 md:px-24 lg:w-3/4 xl:w-1/2 xl:px-0 ">
                    <div
                        className="w-full bg-white rounded-lg shadow">
                        <div className="pt-20 pb-16 flex justify-center">


                            <div>
                                <p className="text-3xl font-light text-gray-500">
                                    An error occurred - <Link to="/login"
                                                              className="text-3xl font-light  text-blue-400 hover:underline">try
                                    again
                                    here</Link>
                                </p>
                            </div>





                        </div>


                    </div>
                </div>


            </section>}
        </>

    );

}
