import PageBanner from "../../../components/PageBanner/PageBanner";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {strapi_url} from "../../../tools/api/api";
import {BlocksRenderer} from "@strapi/blocks-react-renderer";
import TechnologySlider from "../../../components/TechnologySlider/TechnologySlider";
import {useParams} from "react-router-dom";

function ServicesDetails() {
    const {id} = useParams()
    const [content, setContent] = useState(null)
    useEffect(() => {
        axios.get(`${strapi_url}/api/service-lists/${id}?populate=deep`)
            .then((response) => {
                setContent(response.data.data.attributes)
                console.log(response.data.data.attributes)
            })
            .catch(error => console.error(error))
    }, [id])
    if(content) {
    return (
        <>
            <PageBanner content={content.banner}/>
            <div className={'container pb-24'}>
                <BlocksRenderer content={content.content} blocks={{
                    heading: ({children}) => <h4 className="text-6xl pt-24 pb-6">{children}</h4>,
                    paragraph: ({children}) => <p className="text-4xl font-light pb-6 leading-10 ">{children}</p>,
                    list:({children}) => <ul className="text-6xl list-disc list list-inside  pb-12 font-light pb-6">{children}</ul>,
                    link: ({ children, url }) => <a href={url} className={'text-4xl text-cyan-500 underline'}>{children}</a>,
                }}/>
            </div>
            <TechnologySlider content={content.logo_scroller} />
        </>
    )}
}

export default ServicesDetails