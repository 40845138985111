import React from "react"


export function InputTextArea({register, options, name, label, placeholder, errors}) {
    const style = 'bg-gray-50 border text-xl border-gray-300 text-gray-900 rounded-lg font-medium   focus:ring-primary-600 focus:border-primary-600 block w-full p-6 pt-8 pb-8'

    return (
        <div className={'w-full'}>
            <label
                className="block mb-4 text-xl font-medium text-gray-900">{label}</label>
            <textarea rows="6" type="text"  {...register(name, options)} placeholder={placeholder} className={style}/>
            {errors[name] && (
                <p className={'text-xl text-red-600 font-light pt-4'}>{errors[name].message}</p>)}

        </div>


    )
}

export default InputTextArea