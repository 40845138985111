

import { configureStore,applyMiddleware } from "@reduxjs/toolkit";
import {thunk} from 'redux-thunk';

import customerReducer from "./slices/customerSlice";
import shippingCartReducer from "./slices/shippingCartSlice";
import guestReducer from "./slices/guestSlice";

export default configureStore({
  reducer: {
    customer: customerReducer,
    shippingCart: shippingCartReducer,
    guest: guestReducer,
  },


}, applyMiddleware(thunk));
