import React from "react";
import { useForm } from "react-hook-form";
import bgTop from "../../../assets/bg/platform_cost_estimator__BG.png";
import arrowDown from "../../../assets/pic/arrow.png";
import logoSelectBudget from "../../../assets/shapes/Shape_737.png";
import logoCalendar from "../../../assets/shapes/calendar.png";
import logoAddFile from "../../../assets/shapes/add_file.png";
import { Button } from "../../../components/Button/Button";
import "./PlatformCostEstimator.scss";

export default function PlatformCostEstimator() {
  const {
    register,
    handleSubmit,
    reset,

  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    reset();
  };

  return (
    <div className="platform_cost_estimator">
      <div className="platform_cost_estimator_wrapper_topBG">
        <img src={bgTop} alt="#" />
      </div>
      <div className="container platform_cost_estimator_content">
        <h5>Platform Cost Estimator</h5>
        <h3 className="over-title">Start</h3>
        <h3 className="title__h3">Your Project</h3>
        <p className="text-regular">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla et elit
          ut odio bibendum consequat ut a felis. Integer ut faucibus ligula, non
          ultrices nisi. Ut ac orci non metus viverra pharetra sit amet
          tincidunt nibh.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="wrapper_block_form">
            <h4 className="sub-title">Project Information</h4>
            <div>
              <h5>Project Name</h5>
              <p className="text-regular">
                A unique name for the website project.
              </p>
              <input
                type="text"
                {...register("project_name")}
                placeholder="Project Name"
              />
            </div>
            <div>
              <h5>Project Description</h5>
              <p className="text-regular">
                A brief description of the project's purpose and goals.
              </p>
              <textarea
                type="text"
                {...register("project_description")}
                placeholder="Project Description"
              />
            </div>
          </div>
          <div className="wrapper_block_form">
            <h4 className="sub-title">Contact Information</h4>
            <div>
              <h5>Client Name</h5>
              <p className="text-regular">Name of the client or company.</p>
              <input
                type="text"
                {...register("client_name")}
                placeholder="Client Name"
              />
            </div>
            <div className="box_block_form">
              <div>
                <h5>Client Email</h5>
                <p className="text-regular">
                  Email address of the client for communication.
                </p>
                <input
                  type="text"
                  {...register("client_email")}
                  placeholder="Client Email"
                />
              </div>
              <div>
                <h5>Client Phone</h5>
                <p className="text-regular">Phone number of the client.</p>
                <input
                  type="text"
                  {...register("client_phone")}
                  placeholder="Client Phone"
                />
              </div>
            </div>
          </div>
          <div className="wrapper_block_form">
            <h4 className="sub-title">Website Details</h4>
            <div className="box_block_form">
              <div>
                <h5>Website Type</h5>
                <div className="wrapper_select">
                  <div className="select_arrow_down">
                    <img src={arrowDown} alt="arrow down" />
                  </div>
                  <select
                    {...register("website_type")}
                    placeholder="Website Type"
                  >
                    <option value="A">Website Type</option>
                    <option value="B">Website Type2</option>
                    <option value="C`">Website Type3</option>
                  </select>
                </div>
              </div>
              <div>
                <h5>Target Audience</h5>
                <p className="text-regular">
                  Description of the intended audience for the website.
                </p>
                <input
                  type="text"
                  {...register("target_audience", {
                    required: true,
                  })}
                  placeholder="Target Audience"
                />
              </div>
            </div>
            <div>
              <h5>Competitor Websites</h5>
              <p className="text-regular">
                Links or names of competitor websiteS for reference.
              </p>
              <input
                type="text"
                {...register("competitor_websites")}
                placeholder="Competitor Websites"
              />
            </div>
          </div>
          <div className="wrapper_block_form">
            <h4 className="sub-title">Design Preferences</h4>
            <div className="box_block_form">
              <div>
                <h5>Color Scheme</h5>
                <p className="text-regular">Preferred colors for the website</p>
                <input
                  type="text"
                  {...register("color_scheme")}
                  placeholder="Color Scheme"
                />
              </div>
              <div>
                <h5>Typography</h5>
                <p className="text-regular">Preferred font styles and sizes.</p>
                <input
                  type="text"
                  {...register("typography")}
                  placeholder="Typography"
                />
              </div>
            </div>
            <div className="box_block_form">
              <div>
                <h5>Imagery Style</h5>
                <p className="text-regular">
                  Descriptive words like modern, minimalist, vibrant, etc.
                </p>
                <input
                  type="text"
                  {...register("imagery_style")}
                  placeholder="Imagery Style"
                />
              </div>
              <div>
                <h5>Layout Preferences</h5>
                <p className="text-regular">
                  Specifics about the layout, such as one-page or multi-page
                  design.
                </p>
                <input
                  type="text"
                  {...register("layout_preferences")}
                  placeholder="Layout Preferences"
                />
              </div>
            </div>
            <div>
              <h5>Inspiration Websites</h5>
              <p className="text-regular">
                Links to websites the client likes for inspiration.
              </p>
              <textarea
                type="text"
                {...register("inspiration_websites")}
                placeholder="Inspiration Websites"
              />
            </div>
          </div>
          <div className="wrapper_block_form">
            <h4 className="sub-title">Functionalities and Features</h4>
            <div className="box_block_form">
              <div>
                <h5>Required Features</h5>
                <div className="wrapper_checkbox">
                  <div>
                    <label htmlFor="сontact_form" className="checkbox">
                      <input
                        className="input_checkbox"
                        type="checkbox"
                        id="сontact_form"
                        {...register("сontact_form ")}
                      />
                      <span className="check_box"></span>
                      <p className="text-regular">Contact form </p>
                    </label>
                    <label htmlFor="blog" className="checkbox">
                      <input
                        className="input_checkbox"
                        type="checkbox"
                        id="blog"
                        {...register("blog")}
                      />
                      <span className="check_box"></span>
                      <p className="text-regular">Blog </p>
                    </label>
                    <label htmlFor="statistics" className="checkbox">
                      <input
                        className="input_checkbox"
                        type="checkbox"
                        id="statistics"
                        {...register("statistics")}
                      />
                      <span className="check_box"></span>
                      <p className="text-regular">Statistics</p>
                    </label>
                  </div>
                  <div>
                    <label htmlFor="gallery" className="checkbox">
                      <input
                        className="input_checkbox"
                        type="checkbox"
                        id="gallery"
                        {...register("gallery")}
                      />
                      <span className="check_box"></span>
                      <p className="text-regular">Gallery</p>
                    </label>
                    <label htmlFor="slider" className="checkbox">
                      <input
                        className="input_checkbox"
                        type="checkbox"
                        id="slider"
                        {...register("slider")}
                      />
                      <span className="check_box"></span>
                      <p className="text-regular">Slider</p>
                    </label>
                    <label htmlFor="light_box" className="checkbox">
                      <input
                        className="input_checkbox"
                        type="checkbox"
                        id="light_box"
                        {...register("light_box")}
                      />
                      <span className="check_box"></span>
                      <p className="text-regular">Light Box</p>
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <h5>Special Functionalities</h5>
                <p className="text-regular">
                  Any special functionalities like payment gateways, user login,
                  etc..
                </p>
                <input
                  type="text"
                  {...register("special_functionalities")}
                  placeholder="Special Functionalities"
                />
              </div>
            </div>
            <div>
              <h5>Content Management System</h5>
              <div className="wrapper_select">
                <div className="select_arrow_down">
                  <img src={arrowDown} alt="arrow down" />
                </div>
                <select {...register("select_CMS")}>
                  <option value="A">Select CMS</option>
                  <option value="B">Select CMS2</option>
                  <option value="C`">Select CMS3</option>
                </select>
              </div>
            </div>
          </div>
          <div className="wrapper_block_form">
            <h4 className="sub-title">Timeline and Budget</h4>
            <div className="box_block_form">
              <div>
                <h5>Project Deadline</h5>
                <div className="input_data">
                  <div className="logo_data_input">
                    <img src={logoCalendar} alt="#" />
                  </div>
                  <p className="text-regular">00</p>
                  <p className="text-regular">00</p>
                  <p className="text-regular">00</p>
                </div>
              </div>
              <div>
                <h5>Budgets</h5>
                <div className="wrapper_select">
                  <div className="select_arrow_down">
                    <img src={arrowDown} alt="arrow down" />
                  </div>
                  <div className="logo_select_budge">
                    <img src={logoSelectBudget} alt="#" />
                  </div>
                  <select {...register("website_type")}>
                    <option value="A">Select Budget</option>
                    <option value="B">Select Budget2</option>
                    <option value="C`">Select Budget3</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper_block_form">
            <h4 className="sub-title">Additional Information</h4>
            <div>
              <h5>Additional Notes</h5>
              <p className="text-regular">
                Any other specific requirements or notes about the project.
              </p>
              <textarea
                type="text"
                {...register("additional_notes", {
                  required: true,
                })}
                placeholder="Additional Notes"
              />
            </div>
          </div>
          <div className="wrapper_block_form">
            <h4 className="sub-title">File Uploads</h4>
            <div>
              <h5>Upload Project Files</h5>
              <p className="text-regular">
                Allows clients to upload logos, videos, mascots, bugs, marketing
                documents, sketches, or other relevant files.
              </p>
              <div className="add_file_wrapper">
                <img src={logoAddFile} alt="#" />
                <p className="text-regular">Drag & Drop files here</p>
                <h6>or</h6>
                <label>
                  Browse Files
                  <input type="file" />
                </label>
              </div>
            </div>
          </div>
          <Button
            buttonClass="btn_platform_cost_estimator_content"
            text="AN ESTIMATE"
          />
        </form>
      </div>
    </div>
  );
}
