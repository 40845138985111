import './VacancyDetails.scss'
import React, {useEffect, useState} from "react";
import axios from "axios";
import {strapi_url} from "../../../tools/api/api";
import {Link, useParams} from "react-router-dom";
import PageBanner from "../../../components/PageBanner/PageBanner";

import {UilArrowLeft} from "@iconscout/react-unicons";
import VacancyForm from "../../../components/VacancyForm/VacancyForm";
import CKEditorRender from "../../../components/CKEditorText/CKEditorRender";


const VacancyDetails = () => {

    let {id} = useParams();
    const [content, setContent] = useState(null);

    useEffect(() => {
        if (id) {
            axios.get(`${strapi_url}/api/vacancies/${id}?populate=deep`)
                .then(response => {
                    setContent(response.data.data.attributes)

                })
                .catch(error => console.error(error))
        }


    }, [id])
    if (content) {

        return (
            <>
                <section className="pb-12 lg:pb-48">
                    {content.banner && <PageBanner content={content.banner}/>}
                    <div className={'container'}>
                        <div className={'pt-12'}>
                            <Link to={'/careers'}
                                  className={'text-xl font-medium underline flex hover:opacity-70'}><UilArrowLeft/>Back
                                    to Careers page</Link>
                        </div>


                        <div className="flex flex-col lg:flex-row  lg:gap-24 mt-12">
                            <CKEditorRender text={content.overview} />
                        </div>
                        <VacancyForm position={content.name}/>
                    </div>

                </section>


            </>
        )
    }
}

export default VacancyDetails