import ReactGlobe from "react-globe";
import rotate from "../360-degree-rotate-icon.webp";
import {strapi_url} from "../../../../tools/api/api";
import React from "react";
import ReactPlayer from "react-player";

function SectionTypeC({item, currentPage, index, windowWidth}) {
    const WebGLPreview = () => {
        return (
            <div
                className="w-full h-fit relative cursor-grab active:cursor-grabbing"
                style={{background: item.section_picture.data !== null ? 'transparent' : 'lightgray'}}>

                <ReactGlobe width={'100%'}
                            style={{}}
                            height={windowWidth < 700 ? '300px' : '500px'}
                            globeBackgroundTexture={null} options={{
                    cameraAutoRotateSpeed: 5
                }}/>

                <img src={rotate} alt=""
                     className={'w-40 absolute bottom-0 right-0'}/>

            </div>
        )
    }
    const RegularPreview = () => {
        return (
            <div className="w-full"
                 style={{background: item.section_picture.data !== null ? 'transparent' : 'lightgray'}}>
                <img
                    src={item.section_picture.data && `${strapi_url}${item.section_picture.data.attributes.url}`}
                    alt={item.title}/>
            </div>
        )
    }
    const VideoPreview = (video) => {
        return (
            <div className="features_template__section-row-picture  flex items-center features_template__section-row-picture-video"
            >
                <ReactPlayer url={video.video.url} width={"100%"}/>


            </div>
        )
    }
    const ProductConfigurators = () => {
        return (
            <div>
                <div className="features_template__section-row-picture"
                     style={{background: item.section_picture.data !== null ? 'transparent' : 'lightgray'}}>
                    <img
                        src={item.section_picture.data && `${strapi_url}${item.section_picture.data.attributes.url}`}
                        alt={item.title}/>
                </div>
                <div className={'w-fit m-auto pt-12 md:pt-0 md:m-0'}>
                    <a href={'https://3d-product-configurator.vercel.app/'}
                       className="features_template__section-row-list_item-cta"
                       style={{backgroundColor: '#4ea0f2'}}>2/3D Configuration Demo
                        <svg width="8" height="14" viewBox="0 0 8 14"
                             fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.292893 13.7071C-0.0976311 13.3166 -0.0976312 12.6834 0.292893 12.2929L5.58579 7L0.292893 1.70711C-0.0976317 1.31658 -0.0976317 0.683417 0.292893 0.292893C0.683417 -0.0976315 1.31658 -0.0976315 1.70711 0.292893L7.70711 6.29289C8.09763 6.68342 8.09763 7.31658 7.70711 7.70711L1.70711 13.7071C1.31658 14.0976 0.683418 14.0976 0.292893 13.7071Z"
                                fill="#FFFFFF"/>
                        </svg>
                    </a>
                </div>

            </div>
        )
    }

    function imageComponent(currentPage, i,item) {
        let componentToRender;
        if (currentPage === '3d-virtualization') {
            console.log(i)
            switch (i) {
                case 1:
                    switch (item.video_url) {
                        case null:
                            return <WebGLPreview/>
                            break;
                        default:
                            return <VideoPreview video={item.video_url}/>
                            break;

                    }

                case 4:
                    switch (item.video_url) {
                        case null:
                            return <ProductConfigurators/>
                            break;
                        default:
                            return <VideoPreview video={item.video_url}/>
                            break;

                    }

                default:
                    switch (item.video_url) {
                        case null:
                            return <RegularPreview/>
                            break;
                        default:
                            return <VideoPreview video={item.video_url}/>
                            break;

                    }


            }

        } else {
            console.log(item)
            switch (item.video_url) {
                case null:
                    return <RegularPreview/>
                    break;
                default:
                    return <VideoPreview video={item.video_url}/>
                    break;

            }
            // return (
            //     <div className="features_template__section-row-picture"
            //          style={{background: item.section_picture.data !== null ? 'transparent' : 'lightgray'}}>
            //         <img
            //             src={item.section_picture.data && `${strapi_url}${item.section_picture.data.attributes.url}`}
            //             alt={item.title}/>
            //     </div>
            // )
        }
    }


    if (item) {
        return (<section
            className={`features_template__section ${item.section_color !== 'white' ? `features_template__section--${item.section_color}` : ''} `}>
            <div className="features_template__section-row-text text-center mx-auto mb-6">
                <p className="text-xl features_template__over-title pb-4">
                    {item.over_title}
                </p>
                <h3 className="text-4xl">
                    {item.title}
                </h3>
            </div>
            <div
                className={` relative`}
                key={index}>
                {imageComponent(currentPage, index, item)}


            </div>
        </section>)
    }

}


export default SectionTypeC