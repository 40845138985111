import robot from '../../../assets/pic/robotasdaswdas.png'
import {Link} from "react-router-dom";

export default function Thanks() {
    return (
        <section className="w-full bg-[#F3F3F3] p-12">
            <div>
                <div className={'w-[300px] mx-auto'}>
                    <img src={robot} alt=""/>
                </div>
                <h3 className={'text-3xl text-[#005EA5] text-center py-3'}>
                    Thank you for placing your order!
                </h3>
                <Link to={'/dashboard'}
                      className="features_template__section-row-list_item-cta mx-auto mt-3"
                >Go to Dashboard

                </Link>
            </div>

        </section>
    )
}