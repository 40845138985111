import { useState, useEffect } from "react";
import axios from "axios";
import { strapi_url } from "@/tools/api/api";
import Background from '@/assets/img/case-study.jpg';

export default function CaseStudy() {
  const [content, setContent] = useState();

  useEffect(() => {
    axios
      .get(`${strapi_url}/api/page-case-study?populate=deep`)
      .then((res) => {
        setContent(res.data.data);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <div className='font-neuehaas'>
      {/* Background Image with Text */}
      <div className="w-full relative">
        <img src={Background} alt='background' className='w-full h-72 md:h-[506px]' />
        <div className='text-black text-2xl sm:text-3xl absolute top-0 w-full h-72 md:h-[506px] font-normal flex flex-col justify-center'>
          <div className='px-10 md:px-48 font-light'>
            Explore our projects!
          </div>
          <div className='text-[#4EA0F2] text-3xl sm:text-4xl font-bold px-10 md:px-48 mt-2'>
            PLATFORMZ CASE STUDIES
          </div>
        </div>
      </div>

      <div dangerouslySetInnerHTML={{ __html: content.attributes.caption }} className="mt-32 mb-16" />
      {content.attributes?.sections.map(section => (
        <div key={section.id} className="container 2xl:max-w-screen-2xl grid grid-cols-1 md:grid-cols-2 gap-20 mb-40">
          <img src={strapi_url + section.image.data.attributes.url} alt="site" className="border border-[#DCDCDC]" />
          <div className="flex flex-col justify-center items-start">
            <div className="text-[#616B7E] text-2xl font-semibold" >
              Magna interdum amet
            </div>
            <div className="!text-[#616B7E] text-xl font-medium mt-6">
              Lorem ipsum dolor sit amet consectetur. Egestas quam ornare molestie magna interdum amet non. Ut viverra non amet sed in rhoncus in proin bibendum. Diam massa nunc mauris elit nulla.

              Ipsum nunc in rhoncus nunc consectetur. Est leo proin facilisi dictum montes mattis convallis.
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}