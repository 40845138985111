import React from "react";

import "./LoginPage.scss";
import {useSearchParams} from "react-router-dom";

import SSO from "../../../components/SSO/SSO";

import LoginForm from "../../../components/LoginForm/LoginForm";

export default function LoginPage() {
// eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();


    return (
        <section className="bg-gray-50 py-24 md:py-28">
            <div className="flex flex-col items-center justify-center  mx-auto h-full px-4 md:px-24 lg:w-3/4 xl:w-1/2 xl:px-0">
                <div
                    className="w-full bg-white rounded-lg shadow">

                    <div className="p-8 pt-20 ">
                        <p className="text-center leading-tight tracking-tight text-gray-900 text-2xl mb-6">
                            LOGIN WITH
                        </p>
                        <SSO/>
                        <div className={'my-12 relative h-10 w-full flex justify-center'}>
                            <div className="block absolute bottom-5 w-fit text-2xl font-medium bg-white px-4">
                                or
                            </div>
                            <div className={'w-full h-0.5 bg-gray-300 rounded-lg'}/>
                        </div>
                        <LoginForm/>


                    </div>
                </div>
            </div>


        </section>
    );
}
