import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";

//Components

import Footer from "./components/Footer/Footer";

import HeaderSecond from "./components/HeaderSecond/HeaderSecond";

//Pages
import About from "./pages/InfoPages/About/About";
import MediaCenter from "./pages/InfoPages/MediaCenter/MediaCenter";
import ContactUs from "./pages/InfoPages/ContactUs/ContactUs";
import ReferralProgram from "./pages/InfoPages/ReferralProgram/ReferralProgram";
import Services from "./pages/InfoPages/Services/Services";
import UnderConstruction from "./pages/UnderConstruction/UnderConstruction";
import RegistrationPage from "./pages/AuthPages/RegistrationPage/RegistrationPage.jsx";
import LoginPage from "./pages/AuthPages/LoginPage/LoginPage.jsx";
import VerificationComplete from "./pages/AuthPages/VerificationComplete/VerificationComplete.jsx";
import VerifyYourEmailPh from "./pages/AuthPages/VerifyYourEmailPh/VerifyYourEmailPh.jsx";
import Showcase from "./pages/InfoPages/Showcase/Showcase.jsx";

import Homepage from "./pages/Landing/Homepage";
import Subscribe from "./pages/Landing/sections/Subscribe";
import VentureProgram from "./pages/InfoPages/VentureProgram/VentureProgram.jsx";
import PlatformCostEstimator from "./pages/InfoPages/PlatformCostEstimator/PlatformCostEstimator.jsx";
import Error from "./pages/Error/Error.jsx";
import Policy from "./pages/InfoPages/Policy/Policy";
// import DashboardCustomer from "./pages/Dashboard/Dashboard.jsx";
import FeaturesTemplate from "./pages/InfoPages/FeaturesPage/FeaturesTemplate";
import Portfolio from "./pages/InfoPages/Portfolio/Portfolio";
import Pricing from "./pages/InfoPages/Pricing/Pricing";
import PortfolioDetails from "./pages/InfoPages/PortfolioDetails/PortfolioDetails";
import ServicesDetails from "./pages/InfoPages/ServicesDetails/ServicesDetails";

import Careers from "./pages/InfoPages/Careers/Careers";
import AddonsDetails from "./pages/InfoPages/AddonsDetails/AddonsDetails";

import VerticalDetails from "./pages/InfoPages/VerticalDetails/VerticalDetails";
import VacancyDetails from "./pages/InfoPages/VacancyDetails/VacancyDetails";
import { useDispatch } from "react-redux";
import {
  getUserDataFromStorage,
} from "./tools/store/slices/customerSlice";
import OAuthPage from "./pages/AuthPages/OAuthPage/OAuthPage";
import ForgotPasswordPage from "./pages/AuthPages/ForgotPasswordPage/ForgotPasswordPage";
import OTPPage from "./pages/AuthPages/OTPPage/OTPPage";
import EmailVerification from "./pages/AuthPages/EmailVerification/EmailVerification";
import Dashboard from "./pages/Customer/Dashboard/Dashboard.jsx";
import MyOrders from "./pages/Customer/Dashboard/MyOrders/MyOrders.jsx";
import MyOrdersView from "./pages/Customer/Dashboard/MyOrdersView/MyOrdersView.jsx";
import Address from "./pages/Customer/Dashboard/Address/Address.jsx";
import AddressNew from "./pages/Customer/Dashboard/Address/AddressNew/AddressNew.jsx";
import AddressEdit from "./pages/Customer/Dashboard/Address/AddressEdit/AddressEdit.jsx";
import AccountInformation from "./pages/Customer/Dashboard/AccountInformation/AccountInformation.jsx";
import Newsletter from "./pages/Customer/Dashboard/Newsletter/Newsletter.jsx";
import MySubscriptions from "./pages/Customer/Dashboard/MySubscriptions/MySubscriptions.jsx";
import RequestQuote from "./pages/InfoPages/RequestQuote/RequestQuote.jsx";
import CaseStudy from '@/pages/InfoPages/CaseStudy/CaseStudy';

import {
  userSetTokenAsync,
} from "./tools/store/slices/customerSlice";
import ThanksPage from "./pages/InfoPages/ThanksPage/ThanksPage";
import {
  createCartId,
  updateShoppingCart,
} from "./tools/store/slices/shippingCartSlice";
import Checkout from "./pages/Checkout/Checkout";
import Purchase from "./pages/Purchase/Purchase";
import Processes from "./pages/InfoPages/Processes/Processes.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const dispatch = useDispatch();
  const [gtm] = useState("G-B5WPG26KRW");

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: gtm,
    };
    TagManager.initialize(tagManagerArgs);
  }, [gtm]);

  const location = useLocation();

  const [isMaintenance] = useState(false);

  // useEffect(() => {
  //     axios.get(`${strapi_url}/api/maintenance`)
  //         .then(response => {
  //             setIsMaintenance(response.data.data.attributes.maintenance_mode)
  //         })
  // }, [])

  const isDashboard = location.pathname.includes("/dashboard");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    const accessToken = JSON.parse(localStorage.getItem("customer"));
    if (accessToken !== null) {
      dispatch(userSetTokenAsync(accessToken.accessToken));
    }
  }, [dispatch]);

  useEffect(() => {
    const customer = JSON.parse(localStorage.getItem("customer"));
    if (customer) {
      if (customer.type === "guest" && customer.cart_id === null) {
        dispatch(createCartId());
      } else {
        dispatch(getUserDataFromStorage());
        dispatch(updateShoppingCart());
      }
    } else {
      dispatch(createCartId());
    }
  }, [dispatch]);

  return (
    <>
      <ToastContainer />
      {!isDashboard && <HeaderSecond />}

      <div className={`wrapper lg:min-h-screen`}>
        {isMaintenance ? (
          <>
            <Routes>
              {" "}
              <Route path={"*"} element={<UnderConstruction />} />
            </Routes>
          </>
        ) : (
          <>
            <Routes>
              <Route path={"/"} element={<Homepage />} />
              <Route path={"/login"} element={<LoginPage />} />
              <Route path={"/registration"} element={<RegistrationPage />} />
              <Route
                path={"/forgot-password"}
                element={<ForgotPasswordPage />}
              />
              <Route
                path={"/email-verification"}
                element={<EmailVerification />}
              />
              <Route path={"/callback"} element={<OAuthPage />} />
              <Route path={"/otp"} element={<OTPPage />} />
              <Route
                path={"/phone-verification"}
                element={<VerifyYourEmailPh />}
              />
              <Route
                path={"/verification-approved"}
                element={<VerificationComplete />}
              />
              <Route path={"/about"} element={<About />} />
              <Route path={"/checkout"} element={<Checkout />} />
              <Route path={"/checkout/details"} element={<Checkout />} />
              <Route path={"/checkout/payment"} element={<Checkout />} />
              <Route path={"/checkout/thanks"} element={<Checkout />} />
              <Route path={"/purchase"} element={<Purchase />} />
              <Route path={"/purchase/bundle"} element={<Purchase />} />
              <Route path={"/purchase/templates"} element={<Purchase />} />
              <Route path={"/purchase/addons"} element={<Purchase />} />
              <Route path={"/careers"} element={<Careers />} />
              <Route path={"/thanks"} element={<ThanksPage />} />
              <Route path={"/media-center"} element={<MediaCenter />} />
              <Route path={"/contact-us"} element={<ContactUs />} />
              <Route path={"/referral-program"} element={<ReferralProgram />} />
              <Route path={"/ourprocess"} element={<Processes />} />
              <Route path={"/services"} element={<Services />} />
              <Route path={"/services/services-details"}>
                <Route path={":id"} element={<ServicesDetails />} />
              </Route>
              <Route path={"/showcase"} element={<Showcase />} />
              {/* <Route path={"/portfolio"} element={<Portfolio />} />
              <Route path={"/portfolio/portfolio-details"}>
                <Route path={":id"} element={<PortfolioDetails />} />
              </Route> */}
              <Route path={"/verticals"}>
                <Route path={":id"} element={<VerticalDetails />} />
              </Route>{" "}
              <Route path={"/careers-details"}>
                <Route path={":id"} element={<VacancyDetails />} />
              </Route>
              <Route path={"/venture-program"} element={<VentureProgram />} />
              <Route path={"/e-commerce"} element={<FeaturesTemplate />} />
              <Route
                path={"/animation-and-video"}
                element={<FeaturesTemplate />}
              />
              <Route
                path={"/3d-virtualization"}
                element={<FeaturesTemplate />}
              />
              <Route path={"/build-your-platform"} element={<Pricing />} />
              <Route path={"/addons/addons-details"}>
                <Route path={":id"} element={<AddonsDetails />} />
              </Route>
              <Route
                path={"/PlatformCostEstimator"}
                element={<PlatformCostEstimator />}
              />
              <Route path={"/privacy-policy"} element={<Policy />} />
              <Route path={"/terms-of-service"} element={<Policy />} />
              <Route path={"/refund-policy"} element={<Policy />} />
              <Route path={"/referral-policy"} element={<Policy />} />
              <Route path="/request" element={<RequestQuote />} />
              <Route path="/casestudy" element={<CaseStudy />} />
              <Route path={"/dashboard"} element={<Dashboard />} />
              <Route path={"/dashboard/my-orders"} element={<MyOrders />} />
              <Route
                path={"/dashboard/my-orders/:id"}
                element={<MyOrdersView />}
              />
              <Route path={"/dashboard/address"} element={<Address />} />
              <Route path={"/dashboard/address/new"} element={<AddressNew />} />
              <Route
                path={"/dashboard/address/edit/:id"}
                element={<AddressEdit />}
              />
              <Route
                path={"/dashboard/my-account/edit"}
                element={<AccountInformation />}
              />
              <Route path={"/dashboard/newsletter"} element={<Newsletter />} />
              <Route
                path={"/dashboard/my-subscriptions"}
                element={<MySubscriptions />}
              />
              <Route path={"/error"} element={<Error />} />
              <Route path={"*"} element={<UnderConstruction />} />
            </Routes>
          </>
        )}
      </div>
      {!isDashboard && <Subscribe />}
      {!isDashboard && <Footer />}
    </>
  );
}

export default App;
