import { useState } from "react";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { Button } from "@/components/ui/button"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { PhoneInput } from "react-international-phone";
import { useFilePicker } from "use-file-picker";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import Background from '../../../assets/img/request-a-quote.jpg';

export default function RequestQuote() {
  const [isModalOpen, setIsModalOpen] = useState({
    feature: false,
    language: false,
    currency: false
  });
  const formSchema = z
    .object({
      companyName: z.string().min(1, { message: "This field is required" }),
      contactPerson: z.string().min(1, { message: "This field is required" }),
      email: z.string().min(1, { message: "This field is required" }),
      phone: z.string().min(1, { message: "This field is required" }),
      companyWebsite: z.string().min(1, { message: "This field is required" }),
      describeProject: z.string().min(1, { message: "This field is required" }),
      problems: z.string().min(1, { message: "This field is required" }),
      targetUser: z.string().min(1, { message: "This field is required" }),
      projectName: z.string().optional(),
      companySize: z.string().optional(),
      industry: z.string().optional(),
      multiStore: z.boolean().default(false).optional(),
      quoteToInvoiceAutomation: z.boolean().default(false).optional(),
      consumerStore: z.boolean().default(false).optional(),
      b2bStore: z.boolean().default(false).optional(),
      action: z.boolean().default(false).optional(),
      salesAndRentals: z.boolean().default(false).optional(),
      subscriptionModels: z.boolean().default(false).optional(),
      productConfigurator: z.boolean().default(false).optional(),
      dealerPortal: z.boolean().default(false).optional(),
      referralPortal: z.boolean().default(false).optional(),
      volumeDiscountAutomation: z.boolean().default(false).optional(),
      amazonSellerCentral: z.boolean().default(false).optional(),
      googleAnalytics: z.boolean().default(false).optional(),
      marketingTools: z.boolean().default(false).optional(),
      erpIntegration: z.boolean().default(false).optional(),
      paymentGatewayIntegration: z.boolean().default(false).optional(),
      automatedShippingAndLogistics: z.boolean().default(false).optional(),
      warehouseManagementSystem: z.boolean().default(false).optional(),
      emailVerification: z.boolean().default(false).optional(),
      twoFactorAuthentication: z.boolean().default(false).optional(),
      socialAuthentication: z.boolean().default(false).optional(),
      multiFactorAuthentication: z.boolean().default(false).optional(),
      mandrill: z.boolean().default(false).optional(),
      recaptcha: z.boolean().default(false).optional(),
      referralFactory: z.boolean().default(false).optional(),
      chatbot: z.boolean().default(false).optional(),
      currencySwitcher: z.boolean().default(false).optional(),
      languageLocalizationAutomation: z.boolean().default(false).optional(),
      languageLocalizationMagento: z.boolean().default(false).optional(),
      languageLocalizationCMS: z.boolean().default(false).optional(),
      dealerLocator: z.boolean().default(false).optional(),
      english: z.boolean().default(false).optional(),
      spanish: z.boolean().default(false).optional(),
      french: z.boolean().default(false).optional(),
      german: z.boolean().default(false).optional(),
      chinese: z.boolean().default(false).optional(),
      portuguese: z.boolean().default(false).optional(),
      italian: z.boolean().default(false).optional(),
      dutch: z.boolean().default(false).optional(),
      japanese: z.boolean().default(false).optional(),
      russian: z.boolean().default(false).optional(),
      currencyUSD: z.boolean().default(false).optional(),
      currencyEuro: z.boolean().default(false).optional(),
      currencyGBP: z.boolean().default(false).optional(),
      currencyCAD: z.boolean().default(false).optional(),
      currencyAUD: z.boolean().default(false).optional(),
      currencyJPY: z.boolean().default(false).optional(),
      currencINR: z.boolean().default(false).optional(),
      currencyCNY: z.boolean().default(false).optional(),
      currencyMXN: z.boolean().default(false).optional(),
      currencyBRL: z.boolean().default(false).optional(),
      automaticVatManagement: z.boolean().default(false).optional(),
      countryTaxhandling: z.boolean().default(false).optional(),
      globalShipping: z.boolean().default(false).optional(),
      smoothAnimations: z.boolean().default(false).optional(),
      interactiveScrolling: z.boolean().default(false).optional(),
      threeProductView: z.boolean().default(false).optional(),
      animatedStroytelling: z.boolean().default(false).optional(),
      realtimeInteractions: z.boolean().default(false).optional(),
      fullscreenAnimations: z.boolean().default(false).optional(),
      interactiveChart: z.boolean().default(false).optional(),
      customLoadingScreens: z.boolean().default(false).optional(),
      dynamicBackgrounds: z.boolean().default(false).optional(),
      depthScrolling: z.boolean().default(false).optional(),
      interactiveVideos: z.boolean().default(false).optional(),
      isExistPlatform: z.enum(["yes", "no"]),
      currentPlatform: z.string().optional(),
      usedFramework: z.string().optional(),
      databaseSize: z.string().optional(),
    })

  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  const { openFilePicker, filesContent, loading, errors, plainFiles, clear } = useFilePicker({
    multiple: true,
    readAs: 'DataURL', // availible formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
    accept: ['.json', '.pdf', '.txt', '.doc']
  });

  const [features, setFeatures] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  // Function to handle adding a new input field
  const handleAddFeature = () => {
    setFeatures([...features, { id: 'feature' + (features.length + 1), name: '' }]);
  };

  const handleAddLanguage = () => {
    setLanguages([...languages, { id: 'language' + (languages.length + 1), name: '' }]);
  };

  const handleAddCurrency = () => {
    setCurrencies([...currencies, { id: 'currency' + (currencies.length + 1), name: '' }]);
  };

  // Function to handle removing an input field
  const handleRemoveFeature = (id) => {
    setFeatures(features.filter((feature) => feature.id !== id));
  };

  const handleRemoveLanguage = (id) => {
    setLanguages(languages.filter((language) => language.id !== id));
  };

  const handleRemoveCurrency = (id) => {
    setCurrencies(currencies.filter((currency) => currency.id !== id));
  };

  // Function to handle input changes
  const handleFeatureInputChange = (id, value) => {
    const updatedFeatures = features.map((feature) =>
      feature.id === id ? { ...feature, name: value } : feature
    );
    setFeatures(updatedFeatures);
  };

  const handleLanguageInputChange = (id, value) => {
    const updatedLanguages = languages.map((language) =>
      language.id === id ? { ...language, name: value } : language
    );
    setLanguages(updatedLanguages);
  };

  const handleCurrencyInputChange = (id, value) => {
    const updatedCurrencies = currencies.map((currency) =>
      currency.id === id ? { ...currency, name: value } : currency
    );
    setCurrencies(updatedCurrencies);
  };

  const handleSubmitFeature = () => {
    console.log(features)
  }

  const handleSubmitLanguage = () => {
    console.log(languages)
  }

  const handleSubmitCurrency = () => {
    console.log(currencies)
  }

  // Function to open/close modal
  const toggleModal = (name) => {
    setIsModalOpen({
      feature: name === 'feature' ? !isModalOpen[name] : false,
      language: name === 'language' ? !isModalOpen[name] : false,
      currency: name === 'currency' ? !isModalOpen[name] : false
    });
  };

  const onSubmit = (data) => {
    console.log(data)
  }

  return (
    <section className='font-neuehaas'>
      {/* Background Image with Text */}
      <div className="w-full relative">
        <img src={Background} alt='background' className='w-full h-72 md:h-[506px]' />
        <div className='text-black text-2xl sm:text-3xl absolute top-0 w-full h-72 md:h-[506px] font-normal flex flex-col justify-center'>
          <div className='px-10 md:px-48'>
            Estimate your Project Now!
          </div>
          <div className='text-[#4EA0F2] text-3xl sm:text-4xl font-bold px-10 md:px-48 mt-2'>
            REQUEST A QUOTE
          </div>
        </div>
      </div>

      {/* Introduction Section */}
      <div className='mt-[71px] w-full px-5'>
        <h2 className='text-black text-4xl font-semibold text-center'>Request a Quote</h2>
        <p className='mt-0.5 text-[#616B7E] text-xl font-medium text-center'>Let us show you how we can optimize your business for success!</p>
      </div>

      {/* Form */}
      <Form {...form} >
        <form onSubmit={form.handleSubmit(onSubmit)} className='w-full mt-[61px] px-5 lg:px-10 xl:px-20 2xl:px-48 items-center'>
          {/* Section: Basic Contact Information */}
          <div className='flex items-center'>
            <h3 className='text-[#4EA0F2] text-xl font-semibold min-w-64 ml-5'>Basic Contact Information</h3>
            <div className='w-full h-px border-dashed border-t border-[#DCDCDC] mt-2' />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mt-8 gap-[30px]">
            <FormFieldInput label="Company Name" name="companyName" placeholder="Enter company name" control={form.control} />
            <FormFieldInput label="Contact Person" name="contactPerson" placeholder="Name of contact person" control={form.control} />
            <FormFieldInput label="Email Address" name="email" placeholder="Enter email address" control={form.control} />
            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem className="space-y-2">
                  <FormLabel className="px-5 text-lg text-black font-medium">
                    Phone Number<span className="text-[#F25353] ml-1">*</span>
                  </FormLabel>
                  <FormControl>
                    <PhoneInput
                      name="phone"
                      {...field}
                      className={`w - full px-5 border-0 font-medium !font-neuehaas text-lg bg-[#FBFBFB] focus:border-[#535353] focus:ring-0 py-0.5 placeholder:text-[#C2C2C2] rounded-[5px] ${field.value
                        ? "text-[#535353]"
                        : "text-[#C2C2C2]"
                        }`}
                      placeholder={"(xxx) xxx-xxxx"}
                      inputClassName="!text-sm !border-none active:!border-none focus:!border-none focus:!ring-0 w-full bg-[#FBFBFB]"
                      countrySelectorStyleProps={{ buttonClassName: '!border-none active:!border-none focus:!border-none' }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormFieldInput label="Company Website" name="companyWebsite" placeholder="Company website URL" control={form.control} />
          </div>

          {/* Section: Project Overview */}
          <div className='flex items-center mt-[70px]'>
            <h3 className='text-[#4EA0F2] text-xl font-semibold min-w-40 ml-5'>Project Overview</h3>
            <div className='w-full h-px border-dashed border-t border-[#DCDCDC] mt-2' />
          </div>
          <div className="grid grid-cols-1 xl:grid-cols-2 mt-8 gap-[30px]">
            <div className="flex flex-col gap-[30px]">
              <FormField
                control={form.control}
                name="describeProject"
                render={({ field }) => (
                  <FormItem className="space-y-2">
                    <FormLabel className="px-5 text-lg text-black font-medium">
                      Describe Your Project
                      <span className="text-[#F25353] ml-1">*</span>
                    </FormLabel>
                    <FormControl>
                      <textarea
                        placeholder="Provide a brief overview of the platform you’re looking to build, key objectives, and any specific functionality you require..."
                        {...field}
                        className={`w-full px-5 border-none focus-visible:border-none focus-visible:ring-0 focus-visible:ring-offset-0 active:ring-0 min-h-[180px] pt-3.5 placeholder:text-[#9D9D9D] font-medium !font-neuehaas text-lg bg-[#FBFBFB] ${field.value
                          ? "text-[#545454]"
                          : "text-[#C2C2C2]"
                          }`}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="px-5">
                <div className="text-black text-lg font-medium">Upload Your Project Plan</div>
                <div className="text-[#9D9D9D] text-lg font-medium mt-1 max-w-[455px]">Attach any project documentation, mockups, or diagrams that will help us understand your vision.</div>
                <button type="button" className="bg-[#4EA0F2] rounded-[5px] text-white text-lg font-semibold flex justify-center items-center px-5 py-2 gap-1.5 mt-5" onClick={() => openFilePicker()}>
                  Upload
                  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                    <path d="M21.9858 15.666L21.9857 21.7052C21.9857 21.8559 21.8852 21.9772 21.7603 21.9772H1.22544C1.10054 21.9772 1 21.8559 1 21.7052V15.666" stroke="white" strokeOpacity="0.8" strokeWidth="2" strokeMiterlimit="6.2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11.4941 16V1" stroke="white" strokeOpacity="0.8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11.4946 1L6.30859 6.29838" stroke="white" strokeOpacity="0.8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11.4941 1L16.6801 6.29838" stroke="white" strokeOpacity="0.8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
              </div>
            </div>
            <div className="flex flex-col gap-[30px]">
              <FormField
                control={form.control}
                name="problems"
                render={({ field }) => (
                  <FormItem className="space-y-2">
                    <FormLabel className="px-5 text-lg text-black font-medium">
                      What problem does your project solve?
                      <span className="text-[#F25353] ml-1">*</span>
                    </FormLabel>
                    <FormControl>
                      <textarea
                        placeholder="Tell us everything you can about what your project stands for and what it aims to solve or deliver..."
                        {...field}
                        className={`w-full px-5 border-none focus-visible:border-none focus-visible:ring-0 focus-visible:ring-offset-0 active:ring-0 min-h-[180px] pt-3.5 placeholder:text-[#9D9D9D] font-medium !font-neuehaas text-lg bg-[#FBFBFB] ${field.value
                          ? "text-[#545454]"
                          : "text-[#C2C2C2]"
                          }`}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px]">
                <FormFieldInput label="Project Name (Optional)" name="projectName" placeholder="Enter project name" control={form.control} isRequired={false} />
                <FormFieldInput label="Target Users/Audience" name="targetUser" placeholder="Target users/audience" control={form.control} />
              </div>
            </div>
          </div>

          <div className='flex items-center mt-20'>
            <h3 className='text-[#4EA0F2] text-xl font-semibold w-full sm:min-w-[312px] ml-5'>Company Size & Industry <span className="!font-light">(Optional)</span></h3>
            <div className='w-full h-px border-dashed border-t border-[#DCDCDC] mt-2 hidden sm:block' />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 mt-8 gap-2.5 w-full xl:w-1/2">
            <FormField
              control={form.control}
              name="companySize"
              render={({ field }) => (
                <FormItem className="space-y-2">
                  <FormLabel className="px-5 text-lg text-black font-medium">
                    Company Size
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Select company size"
                      {...field}
                      className={`w-full px-5 border-none focus-visible:border-none focus-visible:ring-0 focus-visible:ring-offset-0 active:ring-0 py-5 placeholder:text-[#9D9D9D] font-medium !font-neuehaas text-lg bg-[#FBFBFB] ${field.value
                        ? "text-[#545454]"
                        : "text-[#C2C2C2]"
                        }`}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="industry"
              render={({ field }) => (
                <FormItem className="space-y-2">
                  <FormLabel className="px-5 text-lg text-black font-medium">
                    Industry
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Industry your company operates in"
                      {...field}
                      className={`w-full px-5 border-none focus-visible:border-none focus-visible:ring-0 focus-visible:ring-offset-0 active:ring-0 py-5 placeholder:text-[#9D9D9D] font-medium font-neuehaas text-lg bg-[#FBFBFB] ${field.value
                        ? "text-[#545454]"
                        : "text-[#C2C2C2]"
                        }`}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className='flex items-center mt-20'>
            <h3 className='text-[#4EA0F2] text-xl font-semibold w-full sm:min-w-[322px] ml-5'>Desired Platform Features <span className="!font-light">(Optional)</span></h3>
            <div className='w-full h-px border-dashed border-t border-[#DCDCDC] mt-2 hidden sm:block' />
          </div>
          <div className="mt-[18px] text-[#9D9D9D] text-lg font-medium px-5">Select the features and integrations you're interested in for your customized platform</div>
          <div className="px-5 mt-5">
            <h4 className="text-lg text-black font-medium">Core Functionality</h4>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mt-5 gap-x-[30px] gap-y-5">
              <FormFieldCheckBox label="Multi-Store/Marketplace Setup" name="multiStore" control={form.control} />
              <FormFieldCheckBox label="Quote-to-Invoice Automation" name="quoteToInvoiceAutomation" control={form.control} />
              <FormFieldCheckBox label="Consumer Store" name="consumerStore" control={form.control} />
              <FormFieldCheckBox label="Business to Business Store" name="b2bStore" control={form.control} />
              <FormFieldCheckBox label="Action" name="action" control={form.control} />
              <FormFieldCheckBox label="Sales And Rentals" name="salesAndRentals" control={form.control} />
              <FormFieldCheckBox label="Subscription Models" name="subscriptionModels" control={form.control} />
              <FormFieldCheckBox label="Product Configurator" name="productConfigurator" control={form.control} />
              <FormFieldCheckBox label="Dealer Portal(wholesale)" name="dealerPortal" control={form.control} />
              <FormFieldCheckBox label="Referral Portal (cash or discount referrals)" name="referralPortal" control={form.control} />
              <FormFieldCheckBox label="Volume Discount Automation" name="volumeDiscountAutomation" control={form.control} />
            </div>
          </div>
          <div className="px-5 mt-8">
            <h4 className="text-lg text-black font-medium">Integrations</h4>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mt-5 gap-x-[30px] gap-y-5">
              <FormFieldCheckBox label="Amazon Seller Central" name="amazonSellerCentral" control={form.control} />
              <FormFieldCheckBox label="Google Analytics, Events, and Tags" name="googleAnalytics" control={form.control} />
              <FormFieldCheckBox label="Mailchimp or Other Marketing Tools" name="marketingTools" control={form.control} />
              <FormFieldCheckBox label="ERP/CRM Integration (e.g., Salesforce, QuickBooks)" name="erpIntegration" control={form.control} />
              <FormFieldCheckBox label="Payment Gateway Integration (Stripe, PayPal, Plaid, etc.)" name="paymentGatewayIntegration" control={form.control} />
              <FormFieldCheckBox label="Automated Shipping and Logistics (e.g., ShipBob)" name="automatedShippingAndLogistics" control={form.control} />
              <FormFieldCheckBox label="Warehouse Management Systems" name="warehouseManagementSystem" control={form.control} />
              <FormFieldCheckBox label="Email Verification (e.g., SendGrid)" name="emailVerification" control={form.control} />
              <FormFieldCheckBox label="2-factor Authentication (e.g., Twilio)" name="twoFactorAuthentication" control={form.control} />
              <FormFieldCheckBox label="Social Registration and Login:(e.g., Google, Facebook, LinkedIn, Twitter, GitHub)" name="socialAuthentication" control={form.control} />
              <FormFieldCheckBox label="Multi-factor Authentication* (e.g., Duo Security, Okta)" name="multiFactorAuthentication" control={form.control} />
              <FormFieldCheckBox label="Mandrill for Transactional Email Handling in Mailchimp" name="mandrill" control={form.control} />
              <FormFieldCheckBox label="reCAPTCHA" name="recaptcha" control={form.control} />
              <FormFieldCheckBox label="Referral-Factory" name="referralFactory" control={form.control} />
              <FormFieldCheckBox label="Chatbot" name="chatbot" control={form.control} />
              <FormFieldCheckBox label="Currency Switcher with GEO-IP" name="currencySwitcher" control={form.control} />
              <FormFieldCheckBox label="Language Localization Automation (React)" name="languageLocalizationAutomation" control={form.control} />
              <FormFieldCheckBox label="Language Localization (Magento Catalog)" name="languageLocalizationMagento" control={form.control} />
              <FormFieldCheckBox label="Language Localization (CMS)" name="languageLocalizationCMS" control={form.control} />
              <FormFieldCheckBox label="Dealer Locator with 3D Street View, Map, and Satellite View" name="dealerLocator" control={form.control} />
            </div>
          </div>

          {features.length > 0 && <div className="px-5 mt-8">
            <h4 className="text-lg text-black font-medium">Other APIs / Features</h4>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mt-5 gap-x-[30px] gap-y-5">
              {features.map(feature => (
                <FormFieldCheckBox key={feature.id} label={feature.name} name={feature.id} control={form.control} />
              ))}
            </div>
          </div>}

          <Popover open={isModalOpen.feature}>
            <PopoverTrigger asChild>
              <button
                type="button"
                className="mt-8 bg-[#4EA0F2] rounded-[5px] ml-5 px-5 py-2 flex items-center justify-between w-[280px] sm:w-[340px] text-white text-lg font-semibold"
                onClick={() => toggleModal('feature')}
              >
                Add other APIs or Features
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M10 1V19" stroke="white" strokeWidth="2" strokeLinecap="round" />
                  <path d="M19 10L1 10" stroke="white" strokeWidth="2" strokeLinecap="round" />
                </svg>
              </button>
            </PopoverTrigger>
            <PopoverContent className="w-80 sm:w-[400px] md:w-[500px] xl:w-[717px] bg-[#FBFBFB] p-6" side={window.innerWidth < 768 ? 'bottom' : 'right'} sideOffset={window.innerWidth < 768 ? 20 : 40}>
              <div className="grid gap-4">
                <div className="space-y-2">
                  <h4 className="text-[#9D9D9D] text-lg font-semibold leading-none">Add other APIs or Features</h4>
                </div>
                <div className="grid gap-2.5">
                  {features.map(feature => (
                    <div key={feature.id} className="flex items-center gap-4">
                      <Input
                        id="maxWidth"
                        placeholder="Type here..."
                        value={feature.name}
                        onChange={(e) =>
                          handleFeatureInputChange(feature.id, e.target.value)
                        }
                        className="h-10 border-solid placeholder:text-[#9D9D9D] text-lg font-medium font-neuehaas"
                      />
                      <button className="h-5" onClick={() => handleRemoveFeature(feature.id)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="2" viewBox="0 0 20 2" fill="none">
                          <path d="M19 1L1 1" stroke="#9D9D9D" strokeWidth="2" strokeLinecap="round" />
                        </svg>
                      </button>
                    </div>
                  ))}

                  <button className="text-[#4EA0F2] text-lg font-medium underline text-start pl-5" onClick={handleAddFeature}>
                    Add more
                  </button>
                </div>
                <div className="flex w-full gap-[5px] items-center justify-end">
                  <button type="button" className="rounded-[5px] bg-white border border-solid border-[#DCDCDC] text-[#9D9D9D] text-lg font-semibold pl-7 pr-8 py-0.5" onClick={() => toggleModal('feature')}>Cancel</button>
                  <button type="button" className="rounded-[5px] bg-[#4EA0F2] text-white text-lg font-semibold pl-7 pr-8 py-0.5" onClick={handleSubmitFeature}>Submit</button>
                </div>
              </div>
            </PopoverContent>
          </Popover>

          <div className="mt-20">
            <div className='flex items-center'>
              <h3 className='text-[#4EA0F2] text-xl font-semibold w-full sm:min-w-[322px] ml-5'>Global Functionality <span className="!font-light">(Optional)</span></h3>
              <div className='w-full h-px border-dashed border-t border-[#DCDCDC] mt-2 hidden sm:block' />
            </div>
            <div className="mt-[18px] text-[#9D9D9D] text-lg font-medium px-5">
              If your business operates globally, please select which features you need. The top languages and currencies for an e-commerce platform depend on your target markets. Based on global usage and common e-commerce preferences, here are recommendations.
            </div>
            <div className="px-5 mt-8">
              <h4 className="text-lg text-black font-medium">Top Languages (Select all that apply)</h4>
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mt-5 gap-x-[30px] gap-y-5">
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">English</span> - U.S., U.K., Canada, Australia, and as a global lingua franca</div>} name="english" control={form.control} />
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">Spanish</span> - Spain, Mexico, Latin America, and the U.S. Hispanic market</div>} name="spanish" control={form.control} />
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">French</span> - France, Canada, and parts of Africa</div>} name="french" control={form.control} />
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">German</span> - Germany, Austria, and Switzerland</div>} name="german" control={form.control} />
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">Chinese</span> (Simplified) - China</div>} name="chinese" control={form.control} />
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">Portuguese</span> - Brazil and Portugal</div>} name="portuguese" control={form.control} />
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">Italian</span> - Italy</div>} name="italian" control={form.control} />
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">Dutch</span> - Netherlands and Belgium</div>} name="dutch" control={form.control} />
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">Japanese</span> - Japan</div>} name="japanese" control={form.control} />
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">Russian</span> - Russia and nearby regions</div>} name="russian" control={form.control} />
              </div>
            </div>

            {languages.length > 0 && <div className="px-5 mt-8">
              <h4 className="text-lg text-black font-medium">Other Languages</h4>
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mt-5 gap-x-[30px] gap-y-5">
                {languages.map(language => (
                  <FormFieldCheckBox key={language.id} label={language.name} name={language.id} control={form.control} />
                ))}
              </div>
            </div>}

            <Popover open={isModalOpen.language}>
              <PopoverTrigger asChild>
                <button
                  type="button"
                  className="mt-8 bg-[#4EA0F2] rounded-[5px] ml-5 px-5 py-2 flex items-center justify-between w-[280px] sm:w-[340px] text-white text-lg font-semibold"
                  onClick={() => toggleModal('language')}
                >
                  Add other Languages
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M10 1V19" stroke="white" strokeWidth="2" strokeLinecap="round" />
                    <path d="M19 10L1 10" stroke="white" strokeWidth="2" strokeLinecap="round" />
                  </svg>
                </button>
              </PopoverTrigger>
              <PopoverContent className="w-80 sm:w-[400px] md:w-[500px] xl:w-[717px] bg-[#FBFBFB] p-6" side={window.innerWidth < 768 ? 'bottom' : 'right'} sideOffset={window.innerWidth < 768 ? 20 : 40}>
                <div className="grid gap-4">
                  <div className="space-y-2">
                    <h4 className="text-[#9D9D9D] text-lg font-semibold leading-none">Add other Languages</h4>
                  </div>
                  <div className="grid gap-2.5">
                    {languages.map(language => (
                      <div key={language.id} className="flex items-center gap-4">
                        <Input
                          id="maxWidth"
                          placeholder="Type here..."
                          value={language.name}
                          onChange={(e) =>
                            handleLanguageInputChange(language.id, e.target.value)
                          }
                          className="h-10 border-solid placeholder:text-[#9D9D9D] text-lg font-medium font-neuehaas"
                        />
                        <button className="h-5" onClick={() => handleRemoveLanguage(language.id)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="2" viewBox="0 0 20 2" fill="none">
                            <path d="M19 1L1 1" stroke="#9D9D9D" strokeWidth="2" strokeLinecap="round" />
                          </svg>
                        </button>
                      </div>
                    ))}

                    <button className="text-[#4EA0F2] text-lg font-medium underline text-start pl-5" onClick={handleAddLanguage}>
                      Add more
                    </button>
                  </div>
                  <div className="flex w-full gap-[5px] items-center justify-end">
                    <button type="button" className="rounded-[5px] bg-white border border-solid border-[#DCDCDC] text-[#9D9D9D] text-lg font-semibold pl-7 pr-8 py-0.5" onClick={() => toggleModal('language')}>Cancel</button>
                    <button type="button" className="rounded-[5px] bg-[#4EA0F2] text-white text-lg font-semibold pl-7 pr-8 py-0.5" onClick={handleSubmitLanguage}>Submit</button>
                  </div>
                </div>
              </PopoverContent>
            </Popover>

            <div className="px-5 mt-12">
              <h4 className="text-lg text-black font-medium">Top Currencies (Select all that apply)</h4>
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mt-5 gap-x-[30px] gap-y-5">
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">USD</span> (United States Dollar) - for customers in the U.S</div>} name="currencyUSD" control={form.control} />
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">EUR</span> (Euro) - for European Union countries</div>} name="currencyEuro" control={form.control} />
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">GBP</span> (British Pound Sterling) - for the United Kingdom</div>} name="currencyGBP" control={form.control} />
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">CAD</span> (Canadian Dollar) - for customers in Canada</div>} name="currencyCAD" control={form.control} />
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">AUD</span> (Australian Dollar) -  for customers in Australia</div>} name="currencyAUD" control={form.control} />
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">JPY</span> (Japanese Yen) -  for customers in Japan</div>} name="currencyJPY" control={form.control} />
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">INR</span> (Indian Rupee) -  for customers in India</div>} name="currencINR" control={form.control} />
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">CNY</span> (Chinese Yuan) - for customers in China</div>} name="currencyCNY" control={form.control} />
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">MXN</span> (Mexican Peso) -  for customers in Mexico</div>} name="currencyMXN" control={form.control} />
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">BRL</span> (Brazilian Real) -  for customers in Brazil</div>} name="currencyBRL" control={form.control} />
              </div>
            </div>

            {currencies.length > 0 && <div className="px-5 mt-8">
              <h4 className="text-lg text-black font-medium">Other Currencies</h4>
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mt-5 gap-x-[30px] gap-y-5">
                {currencies.map(currency => (
                  <FormFieldCheckBox key={currency.id} label={currency.name} name={currency.id} control={form.control} />
                ))}
              </div>
            </div>}

            <Popover open={isModalOpen.currency}>
              <PopoverTrigger asChild>
                <button
                  type="button"
                  className="mt-8 bg-[#4EA0F2] rounded-[5px] px-5 py-2 flex items-center justify-between w-[280px] sm:w-[340px] text-white text-lg font-semibold"
                  onClick={() => toggleModal('currency')}
                >
                  Add other Currencies
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M10 1V19" stroke="white" strokeWidth="2" strokeLinecap="round" />
                    <path d="M19 10L1 10" stroke="white" strokeWidth="2" strokeLinecap="round" />
                  </svg>
                </button>
              </PopoverTrigger>
              <PopoverContent className="w-80 sm:w-[400px] md:w-[500px] xl:w-[717px] bg-[#FBFBFB] p-6" side={window.innerWidth < 768 ? 'bottom' : 'right'} sideOffset={window.innerWidth < 768 ? 20 : 40}>
                <div className="grid gap-4">
                  <div className="space-y-2">
                    <h4 className="text-[#9D9D9D] text-lg font-semibold leading-none">Add other Currencies</h4>
                  </div>
                  <div className="grid gap-2.5">
                    {currencies.map(currency => (
                      <div key={currency.id} className="flex items-center gap-4">
                        <Input
                          id="maxWidth"
                          placeholder="Type here..."
                          value={currency.name}
                          onChange={(e) =>
                            handleCurrencyInputChange(currency.id, e.target.value)
                          }
                          className="h-10 border-solid placeholder:text-[#9D9D9D] text-lg font-medium font-neuehaas"
                        />
                        <button className="h-5" onClick={() => handleRemoveCurrency(currency.id)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="2" viewBox="0 0 20 2" fill="none">
                            <path d="M19 1L1 1" stroke="#9D9D9D" strokeWidth="2" strokeLinecap="round" />
                          </svg>
                        </button>
                      </div>
                    ))}

                    <button className="text-[#4EA0F2] text-lg font-medium underline text-start pl-5" onClick={handleAddCurrency}>
                      Add more
                    </button>
                  </div>
                  <div className="flex w-full gap-[5px] items-center justify-end">
                    <button type="button" className="rounded-[5px] bg-white border border-solid border-[#DCDCDC] text-[#9D9D9D] text-lg font-semibold pl-7 pr-8 py-0.5" onClick={() => toggleModal('currency')}>Cancel</button>
                    <button type="button" className="rounded-[5px] bg-[#4EA0F2] text-white text-lg font-semibold pl-7 pr-8 py-0.5" onClick={handleSubmitCurrency}>Submit</button>
                  </div>
                </div>
              </PopoverContent>
            </Popover>

            <div className="mt-11 text-[#9D9D9D] text-lg font-medium px-5">
              This covers many of the major global markets and ensures broad accessibility for your e-commerce platform.
            </div>
            <div className="px-5 mt-10">
              <h4 className="text-lg text-black font-medium">Tax and Compliance</h4>
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mt-5 gap-x-[30px] gap-y-5">
                <FormFieldCheckBox label="Automatic VAT/GST Management" name="automaticVatManagement" control={form.control} />
                <FormFieldCheckBox label="Country-specific tax handling" name="countryTaxhandling" control={form.control} />
                <FormFieldCheckBox label="Global shipping and logistics integration" name="globalShipping" control={form.control} />
              </div>
            </div>
          </div>

          <div className="mt-20">
            <div className='flex items-center'>
              <h3 className='text-[#4EA0F2] text-xl font-semibold w-full sm:min-w-[322px] ml-5'>Design Options <span className="!font-light">(Optional)</span></h3>
              <div className='w-full h-px border-dashed border-t border-[#DCDCDC] mt-2 hidden sm:block' />
            </div>
            <div className="px-5 mt-7">
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mt-5 gap-x-[30px] gap-y-5">
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">Smooth Animations</span> - Make your website feel modern and engaging with simple movements and transitions</div>} name="smoothAnimations" control={form.control} />
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">Interactive Scrolling</span> - Bring your site to life with effects that change as users scroll down the page</div>} name="interactiveScrolling" control={form.control} />
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">3D Product View</span> - Let visitors explore your products in 3D, offering a full interactive experience</div>} name="threeProductView" control={form.control} />
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">Animated Storytelling</span> - Use animations to visually tell your brand’s story and captivate your audience</div>} name="animatedStroytelling" control={form.control} />
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">Real-Time Interactions</span> - Create elements that react instantly when visitors hover or click, adding interactivity</div>} name="realtimeInteractions" control={form.control} />
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">Full-Screen Animations</span> - Engage visitors with large, bold animations that capture attention across the entire page</div>} name="fullscreenAnimations" control={form.control} />
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">Interactive Charts and Data</span> - Make your data or visuals more engaging with interactive, clickable charts or graphics</div>} name="interactiveChart" control={form.control} />
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">Custom Loading Screens</span> - Keep visitors entertained with branded animations while your page loads</div>} name="customLoadingScreens" control={form.control} />
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">Dynamic Backgrounds</span> - Add subtle or bold animated backgrounds to make your site stand out</div>} name="dynamicBackgrounds" control={form.control} />
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">Depth Scrolling</span> - Create a sense of depth by adding layers that move as users scroll</div>} name="depthScrolling" control={form.control} />
                <FormFieldCheckBox label={<div><span className="font-semibold text-[#9D9D9D]">Interactive Videos</span> - Incorporate videos that users can interact with for a more immersive experience</div>} name="interactiveVideos" control={form.control} />
              </div>
            </div>
          </div>

          <div className="mt-20">
            <div className='flex items-center'>
              <h3 className='text-[#4EA0F2] text-xl font-semibold w-full sm:min-w-[400px] ml-5'>Current System and Data Migration <span className="!font-light">(Optional)</span></h3>
              <div className='w-full h-px border-dashed border-t border-[#DCDCDC] mt-2 hidden sm:block' />
            </div>
            <div className="flex flex-col md:flex-row gap-10 mt-10 md:items-center px-5">
              <div className="text-lg text-[#9D9D9D] font-medium">Do you have an existing system/platform that needs data migration?</div>
              <FormField
                control={form.control}
                name="isExistPlatform"
                render={({ field }) => (
                  <FormItem className="space-y-3">
                    <FormControl>
                      <RadioGroup
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        className="flex space-y-0 gap-10"
                      >
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="yes" className="border-solid border-[#DCDCDC]" />
                          </FormControl>
                          <FormLabel className="font-medium text-lg text-[#9D9D9D]">
                            Yes
                          </FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="no" className="border-solid border-[#DCDCDC]" />
                          </FormControl>
                          <FormLabel className="font-medium text-lg text-[#9D9D9D]">
                            No
                          </FormLabel>
                        </FormItem>
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="text-lg text-[#9D9D9D] font-medium mt-16 px-5">If Yes, describe the system</div>
            <div className="grid grid-cols-1 xl:grid-cols-2 mt-8 gap-[30px]">
              <FormField
                control={form.control}
                name="currentPlatform"
                render={({ field }) => (
                  <FormItem className="space-y-2">
                    <FormLabel className="px-5 text-lg text-black font-medium">
                      Current Platform
                    </FormLabel>
                    <FormControl>
                      <textarea
                        placeholder="Provide a brief overview of the platform you’re currently on..."
                        {...field}
                        className={`w-full px-5 border-none focus-visible:border-none focus-visible:ring-0 focus-visible:ring-offset-0 active:ring-0 min-h-[180px] pt-3.5 placeholder:text-[#9D9D9D] font-medium !font-neuehaas text-lg bg-[#FBFBFB] ${field.value
                          ? "text-[#545454]"
                          : "text-[#C2C2C2]"
                          }`}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="usedFramework"
                render={({ field }) => (
                  <FormItem className="space-y-2">
                    <FormLabel className="px-5 text-lg text-black font-medium">
                      Framework Used
                    </FormLabel>
                    <FormControl>
                      <textarea
                        placeholder="Provide a brief overview of the framework you’re currently using..."
                        {...field}
                        className={`w-full px-5 border-none focus-visible:border-none focus-visible:ring-0 focus-visible:ring-offset-0 active:ring-0 min-h-[180px] pt-3.5 placeholder:text-[#9D9D9D] font-medium !font-neuehaas text-lg bg-[#FBFBFB] ${field.value
                          ? "text-[#545454]"
                          : "text-[#C2C2C2]"
                          }`}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="databaseSize"
                render={({ field }) => (
                  <FormItem className="space-y-2">
                    <FormLabel className="px-5 text-lg text-black font-medium">
                      Approximate size of the database
                    </FormLabel>
                    <FormControl>
                      <textarea
                        placeholder="Provide a brief overview of the approximate size of the database you currently have..."
                        {...field}
                        className={`w-full px-5 border-none focus-visible:border-none focus-visible:ring-0 focus-visible:ring-offset-0 active:ring-0 min-h-[180px] pt-3.5 placeholder:text-[#9D9D9D] font-medium !font-neuehaas text-lg bg-[#FBFBFB] ${field.value
                          ? "text-[#545454]"
                          : "text-[#C2C2C2]"
                          }`}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <div className="flex w-full justify-center items-center flex-col gap-5 mb-28 mt-20">
            <Button type="submit" className="bg-[#4EA0F2] rounded-[5px] px-[60px] py-3 text-white text-lg font-semibold">Submit Your Request</Button>
            <div className="text-[#9D9D9D] text-lg font-medium max-w-[852px] text-center px-5">
              Once we receive your information, our team will review your project needs and get in touch within 1-2 business days to schedule a consultation and provide a quote.
            </div>
          </div>
        </form>
      </Form>
    </section>
  )
}

// Reusable FormFieldInput Component
const FormFieldInput = ({ label, name, placeholder, control, type = "text", isRequired = true }) => (
  <FormField
    control={control}
    name={name}
    render={({ field }) => (
      <FormItem className="space-y-2">
        <FormLabel className="px-5 text-lg text-black font-medium">
          {label}
          {isRequired && <span className="text-[#F25353] ml-1">*</span>}
        </FormLabel>
        <FormControl>
          <Input
            placeholder={placeholder}
            {...field}
            type={type}
            className={`w-full px-5 border-none focus-visible:border-none focus-visible:ring-0 focus-visible:ring-offset-0 active:ring-0 py-5 placeholder:text-[#9D9D9D] font-medium !font-neuehaas text-lg bg-[#FBFBFB] ${field.value
              ? "text-[#545454]"
              : "text-[#C2C2C2]"
              }`}
          />
        </FormControl>
        <FormMessage />
      </FormItem>
    )}
  />
);

const FormFieldCheckBox = ({ label, name, control }) => (
  <FormField
    control={control}
    name={name}
    render={({ field }) => (
      <FormItem className="flex flex-row items-start space-x-5 space-y-0 rounded-md ">
        <FormControl>
          <Checkbox
            checked={field.value}
            onCheckedChange={field.onChange}
            className="border border-solid border-[#DCDCDC] mt-1"
          />
        </FormControl>
        <div className="space-y-0 leading-none">
          <FormLabel className="text-lg text-[#9D9D9D] font-medium">
            {label}
          </FormLabel>
        </div>
      </FormItem>
    )}
  />
);