function AddonsDetails() {
    return (
        <>
            <div className={'bg-gray-100 pt-80 pb-40'}>
                <div className="container">
                    <div className={'flex-col lg:flex-row gap-12 lg:gap-0 flex justify-between'}>
                        <div className={'w-full lg:w-2/5'}>
                            <h3 className={'text-6xl'}>Make a 2D Platformer with State Machines in Unity</h3>
                            <p className={'text-3xl font-light pt-6'}>Lorem ipsum dolor sit amet, consectetur adipiscing
                                elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                        <div className={'rounded-xl overflow-hidden'}>
                            <img className={'object-cover w-full h-full'}
                                 src="https://strapi.platformz.us/uploads/gigaland_698d93c8f1.png" alt=""/>
                        </div>
                    </div>
                </div>


            </div>
            <div className={'mt-12 mb-48'}>
                <div className="container flex flex-col lg:flex-row gap-12">
                    <div className={'w-full lg:w-3/4 flex-shrink-0'}>

                        <div className={'bg-gray-100 pl-12 pr-12 pt-8 pb-6 flex gap-24'}>
                        <span
                            className={'text-3xl cursor-pointer pb-2 border-b-2 border-b-transparent hover:border-b-blue-700'}>
                            Overview
                        </span>

                            <span
                                className={'text-3xl cursor-pointer pb-2 border-b-2 border-b-transparent hover:border-b-blue-700'}>
                            Overview
                        </span>

                            <span
                                className={'text-3xl cursor-pointer pb-2 border-b-2 border-b-transparent hover:border-b-blue-700'}>
                            Overview
                        </span>


                        </div>

                        <div className={'mt-12 pl-12'}>
                            <p className={'text-4xl font-light'}>
                                <span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi eveniet ex facilis harum labore libero maxime quam reiciendis repellendus rerum. At distinctio eligendi eum eveniet numquam odit optio perferendis qui?</span><span>Aliquam eius eos esse expedita maiores odit officiis reiciendis repudiandae sequi voluptates. Alias asperiores deleniti dolorum eligendi facere in labore officia qui repudiandae sunt? Amet assumenda facilis reiciendis soluta veritatis.</span><span>Amet maiores minus temporibus! Alias, aliquam at, consequatur cumque deleniti dolore dolores ducimus esse in inventore, ipsa ipsam ipsum itaque numquam odit placeat quasi quo repellat soluta temporibus voluptatem voluptates.</span>
                            </p>
                        </div>
                    </div>
                    <div className={'w-full bg-gray-100 p-12'}>
                        <ul className={' flex flex-col gap-10'}>
                            <li className={'flex justify-between border-b-2 pb-4'}>
                                <span className={'text-3xl'}>Key:</span>
                                <span className={'text-3xl font-medium'}>Value</span>
                            </li>
                            <li className={'flex justify-between border-b-2 pb-4'}>
                                <span className={'text-3xl'}>Key:</span>
                                <span className={'text-3xl font-medium'}>Value</span>
                            </li>
                            <li className={'flex justify-between border-b-2 pb-4'}>
                                <span className={'text-3xl'}>Key:</span>
                                <span className={'text-3xl font-medium'}>Value</span>
                            </li>
                            <li className={'flex justify-between border-b-2 pb-4'}>
                                <span className={'text-3xl'}>Key:</span>
                                <span className={'text-3xl font-medium'}>Value</span>
                            </li>
                            <li className={'flex justify-between border-b-2 pb-4'}>
                                <span className={'text-3xl'}>Key:</span>
                                <span className={'text-3xl font-medium'}>Value</span>
                            </li>
                        </ul>
                        <a href={'/'}
                           className="features_template__section-row-list_item-cta mt-12" style={{width: '100%'}}>Add to cart
                            <svg width="8" height="14" viewBox="0 0 8 14"
                                 fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0.292893 13.7071C-0.0976311 13.3166 -0.0976312 12.6834 0.292893 12.2929L5.58579 7L0.292893 1.70711C-0.0976317 1.31658 -0.0976317 0.683417 0.292893 0.292893C0.683417 -0.0976315 1.31658 -0.0976315 1.70711 0.292893L7.70711 6.29289C8.09763 6.68342 8.09763 7.31658 7.70711 7.70711L1.70711 13.7071C1.31658 14.0976 0.683418 14.0976 0.292893 13.7071Z"
                                    fill="#FFFFFF"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddonsDetails