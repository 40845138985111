import React, {useEffect, useState} from "react";

import "./VentureProgram.scss";
import axios from "axios";
import {strapi_url} from "../../../tools/api/api";

import PageBanner from "../../../components/PageBanner/PageBanner";
import CKEditorRender from "../../../components/CKEditorText/CKEditorRender";

export default function VentureProgram() {
    const [content, setContent] = useState(null);
    useEffect(() => {
        axios.get(`${strapi_url}/api/venture-program?populate=deep`)
            .then(response => {
                setContent(response.data.data.attributes)
                console.log('venture-program', response.data.data.attributes)
            })
            .catch(error => console.error(error))

    }, [])
    if (content) {
        return (
            <div className="venture_program">
                <PageBanner content={content.banner}/>
                <div className="venture_program_content_top  ml-auto mr-auto my-12 w-full px-12 md:w-3/4 md:px-12">
                    <CKEditorRender text={content.section_01} />
                </div>
                <div className="wrapper_description_venture_program">
                    <div className="description_venture_program container">
                        <div className={'lg:w-1/3'}>
                            <CKEditorRender text={content.section_02} />
                        </div>


                    </div>
                    <div className="bg_description_venture_program"
                         style={{backgroundImage: `url(${strapi_url + content.section_02_picture.data.attributes.url})`}}></div>
                </div>
                <div className="choose_programs container">

                        <CKEditorRender text={content.section_3}/>

                    <h4 className="text-6xl font-bold text-black  mb-12">{content.section_03_title}</h4>

                    <div className="venture_program__list">

                        {content.list.map(point => {
                            return (
                                <div>
                                    <h5 className="text-3xl pb-3">{point.title}</h5>
                                    <CKEditorRender text={point.description}/>
                                </div>
                            )
                        })}


                    </div>

                </div>
                <div className="wrapper_join_us py-8">
                    <div className="join_us_content container ">
                        <CKEditorRender text={content.section_04}/>
                    </div>
                </div>
            </div>

        );
    }
}
