import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import './Sidebar.scss'
import robotImg from '../../../../src/assets/img/XMLID_71_.png'
import dashboardIcon from '../../../../src/assets/img/dashboard.svg'
import docIcon from '../../../../src/assets/img/doc-manage.svg'
import projIcon from '../../../../src/assets/img/project-manage.svg'
import credsIcon from '../../../../src/assets/img/creds-manage.svg'
import paymentIcon from '../../../../src/assets/img/payment-manage.svg'
import accountIcon from '../../../../src/assets/img/my-account.svg'
import logoutIcon from '../../../../src/assets/img/logout.svg'
import arrowIcon from '../../../../src/assets/img/arrow.svg'

const Sidebar = () => {
  // const [isVisible, setIsVisible] = useState(true);

  // const toggleVisibility = () => {
  //   setIsVisible(!isVisible);
  // };
  const location = useLocation();

  return (
    <div className='sidebar-main'>
      <div className='sidebar-container'>
        <Link to={'/dashboard'} className='active' >
          <div>
            <img src={dashboardIcon} alt='Dashboard' />
            <span>Dashboard</span>
          </div>
        </Link>

        <Link to={'#'} >
          <div>
            <img src={docIcon} alt='Document Management' />
            <span>Document Management</span>
          </div>
        </Link>

        <Link to={'#'} >
          <div>
            <img src={projIcon} alt='Project Management Ticketing' />
            <span>Project Management Ticketing</span>
          </div>
        </Link>

        <Link to={'#'} >
          <div>
            <img src={credsIcon} alt='Credential Management' />
            <span>Credential Management</span>
          </div>
        </Link>

        <Link to={'#'} >
          <div>
            <img src={paymentIcon} alt='Manage Payment and History' />
            <span>Manage Payment and History</span>
          </div>
        </Link>

        <Link to={'#'}>
          <div style={{ backgroundColor: '#005EA5' }}>
            <img src={accountIcon} alt='My Account' />
            <span>My Account</span>
            <img className='arrow' src={arrowIcon} alt='arrow'

            />
            <ul>
              <li className={location.pathname.includes('/dashboard/address') && 'active'}>
                <Link to={'/dashboard/address'} >
                  <div>Address Book</div>
                </Link>
              </li>
              <li className={location.pathname.includes('/dashboard/my-account/edit') && 'active'}>
                <Link to={'/dashboard/my-account/edit'} >
                  <div>Account Information</div>
                </Link>
              </li>
              <li className={location.pathname.includes('/dashboard/my-orders') && 'active'}>
                <Link to={'/dashboard/my-orders'} >
                  <div>My Orders</div>
                </Link>
              </li>
              <li className={location.pathname.includes('/dashboard/newsletter') && 'active'}>
                <Link to={'/dashboard/newsletter'} >
                  <div>Newsletter Subscriptions</div>
                </Link>
              </li>
              <li className={location.pathname.includes('/dashboard/my-subscriptions') && 'active'}>
                <Link to={'/dashboard/my-subscriptions'} >
                  <div>My Subscriptions</div>
                </Link>
              </li>
            </ul>
          </div>
        </Link>

        <Link to={'#'} >
          <div>
            <img src={logoutIcon} alt='Logout' />
            <span>Logout</span>
          </div>
        </Link>


        {/*
      <Link to={'/dashboard/my-orders'} >
        <div>
          <img src={dashboardIcon} alt='Dashboard' />
          <span>My Orders</span>
        </div>
      </Link>
      <Link to={'/dashboard/address'} >
        <div>Address Book</div>
      </Link>
      <Link to={'/dashboard/my-account/edit'} >
        <div>Account Information</div>
      </Link>
      <Link to={'/dashboard/newsletter'} >
        <div>Newsletter Subscriptions</div>
      </Link>
      <Link to={'/dashboard/paymentmethods'} >
        <div>Stored Payment Methods</div>
      </Link>
      <Link to={'/dashboard/my-subscriptions'} >
        <div>My Subscriptions</div>
      </Link>
      */
        }
      </div>
      <div className='robo-container'>
        <img className='robo-img' src={robotImg} alt='Robot' />
      </div>
    </div>
  )
}

export default Sidebar