import React from "react";
import ReactDOM from "react-dom/client";
import "./scss/style.scss";
import 'swiper/css';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./tools/store/store";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
// import { HelmetProvider } from 'react-helmet-async';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import Metadata from './components/Metadata/Metadata';

const stripePromise = loadStripe('pk_test_51N3TQzEFs7Vw7V4zuZU7cjkqDZP4VPeTVI5meLLcI1mbc1PN2Rlu85kmPQUPbUyORIVNGeWYhXqygbI04ByKGLKD00JsO629PX');


// import TagManager from 'react-gtm-module'


const root = ReactDOM.createRoot(document.getElementById("root"));
const options = {
    appearance: {
        theme: 'stripe',
    }

}


root.render(
    <React.StrictMode>
        <Metadata />
        <GoogleReCaptchaProvider
            reCaptchaKey="6LemiEopAAAAAHkuH5mdpLqrKe4iSt9bVI4feFzT"

        >
            <Elements stripe={stripePromise}>
                <Provider store={store}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </Provider>
            </Elements>
        </GoogleReCaptchaProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
