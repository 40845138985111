import './Footer.scss'
import Container from "../Container/Container";
import mail from "../../assets/shapes/mail_02.png";

import linkedin from "../../assets/social_icons/linkedin.svg";
import facebook from "../../assets/social_icons/Facebook.svg";
import twitter from "../../assets/social_icons/tweet.svg";
import pinterest from "../../assets/social_icons/Pinterest.svg";
import google from "../../assets/social_icons/google.svg";
import reddit from "../../assets/social_icons/reddit.svg";
import instagram from "../../assets/social_icons/instagram.svg";
import youtube from "../../assets/social_icons/youtube.svg";
import telegram from "../../assets/social_icons/telegram.svg";
import { useEffect, useState } from "react";
import axios from "axios";
import { PopupButton } from "react-calendly";
import ReactMarkdown from 'react-markdown';
import { Link } from "react-router-dom";


const socials = {
    google: google,
    twitter: twitter,
    pinterest: pinterest,
    facebook: facebook,
    linkedin: linkedin,
    reddit: reddit,
    instagram: instagram,
    youtube: youtube,
    telegram: telegram,
}

const Footer = () => {

    const [data, setData] = useState(null)

    function getData() {
        axios.get('https://strapi.platformz.us/api/footer?populate=deep')
            .then(res => {
                setData(res.data.data)
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        getData()

    }, []);
    const convertMarkdownToHTML = (markdownText) => {
        const components = {
            p: ({ children }) => <p className="footer__text">{children}</p>,
            u: ({ children }) => <u>{children}</u>,
        };

        return <ReactMarkdown components={components}>{markdownText}</ReactMarkdown>;
    };

    if (data) {
        return (
            <footer className={'footer relative'}
                style={{ backgroundImage: `url('')` }}>
                <img onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} className={'absolute bottom-6 right-14 lg:bottom-14 lg:right-16 w-[270px] cursor-pointer'} src={`https://strapi.platformz.us${data.attributes.footer_robot.data.attributes.url}`} />
                <Container>
                    <div className="footer__content">
                        <div className="footer__column footer__column-first">
                            <p className="footer__title">ABOUT PLATFORMZ</p>

                            {convertMarkdownToHTML(data.attributes.about_platformz.description)}

                            <div className="footer__contact">
                                <p className="footer__contact-title">CONTACT</p>
                                <a className="footer__contact-email"
                                    href={`mailto:${data.attributes.contact_email}`}><img src={mail}
                                        alt="" />{data.attributes.contact_email}
                                </a>

                                {/* <PopupButton
                                    className="footer__contact-tel"
                                    url="https://calendly.com/platformz/30min"

                                    rootElement={document.getElementById("root")}
                                    text="Schedule a Call Now"
                                /> */}

                                <div className="footer__contact-social">
                                    {data.attributes.social_links.social_link.map((item, index) => {
                                        return (<a href={item.url} key={index} rel="noreferrer" target={'_blank'}><img
                                            src={socials[item.social_network]}
                                            alt={item.social_network} /></a>)
                                    })}

                                </div>
                            </div>
                        </div>
                        {data.attributes.footer_menu.map((item, index) => {
                            return (
                                <div className="footer__column" key={index}>
                                    <p className="footer__title">{item.title}</p>
                                    <nav className="footer__nav">
                                        {item.footer_menu_links.map((link, indx) => {
                                            if (link.out_side_website === true) {
                                                return (
                                                    <a className={`text-2xl text-white font-medium hover:opacity-80`}
                                                        href={link.url}
                                                        key={indx}>{link.title}</a>)
                                            } else {
                                                return (
                                                    <Link className={`text-2xl text-white font-medium hover:opacity-80`}
                                                        to={link.url}
                                                        key={indx}>{link.title}</Link>)
                                            }
                                        })}

                                    </nav>
                                </div>
                            )
                        })}


                    </div>
                </Container>
            </footer>
        )
    }
}

export default Footer