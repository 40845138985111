// customerSlice.js
import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";

export const userSetTokenAsync = (accessToken) => async (dispatch, getState) => {
    if (accessToken) {
        try {
            const response = await axios.get(`https://store.platformz.us/rest/V1/customers/me`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            if (response) {
                const quote = await axios.post(`https://store.platformz.us/rest/V1/carts/mine`, {}, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setTimeout(() => {
                    const data = {
                        quote_id: quote.data,
                        ...response.data,
                        accessToken: accessToken
                    }
                    dispatch(updateUserData(data));
                }, 3000)
            }

        } catch (error) {
            console.error("Error when retrieving user data:", error);
        }
    }
};


const customer = createSlice({
    name: "customer",
    initialState: {
        type: 'guest',
        id: null,
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        accessToken: null,
        quote_id: null,
        cart_id: null,

        extension_attributes: {
            is_otp_verification: false,
            is_email_verification: false,
        }
    },
    reducers: {
        getUserDataFromStorage(state) {
            const customer = JSON.parse(localStorage.getItem('customer'))
            if (customer) {
                state.type = customer.type
                state.email = customer.email;
                state.id = customer.id;
                state.firstname = customer.firstname;
                state.lastname = customer.lastname;
                state.accessToken = customer.accessToken;
                state.quote_id = customer.quote_id;
                state.cart_id = customer.cart_id
                state.extension_attributes.is_otp_verification = customer.extension_attributes.is_otp_verification;
                state.extension_attributes.is_email_verification = customer.extension_attributes.is_email_verification;
            }

        },

        updateUserData(state, action) {
            state.type = 'customer'
            state.email = action.payload.email;
            state.id = action.payload.id;
            state.firstname = action.payload.firstname;
            state.lastname = action.payload.lastname;
            state.accessToken = action.payload.accessToken;
            state.quote_id = action.payload.quote_id;
            state.cart_id = null;
            state.extension_attributes.is_otp_verification = action.payload.extension_attributes.is_otp_verification;
            state.extension_attributes.is_email_verification = action.payload.extension_attributes.is_email_verification;

            localStorage.setItem('customer', JSON.stringify(state))
        },

        updaterCartId(state, action) {
            state.cart_id = action.payload.cart_id
            localStorage.setItem('customer', JSON.stringify(state))
        },

        customerLogout(state) {
            state.type = 'guest'
            state.email = null;
            state.id = null;
            state.firstname = null;
            state.lastname = null;
            state.accessToken = null;
            state.quote_id = null;
            state.cart_id = null;
            state.extension_attributes.is_otp_verification = null;
            state.extension_attributes.is_email_verification = null;
            localStorage.removeItem("customer");

        },
    },
});

export const {updateUserData, customerLogout, getUserDataFromStorage, getUserData, updaterCartId} = customer.actions;

export default customer.reducer;
