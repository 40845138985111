import React, { useEffect, useState } from 'react'
import Sidebar from '../../../../components/CustomerDashboard/Sidebar/Sidebar'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Stripe from 'stripe';
import amex from '../../../../assets/cards/amex.svg'
import cartes_bancaires from '../../../../assets/cards/cartes_bancaires.svg'
import diners from '../../../../assets/cards/diners.svg'
import discover from '../../../../assets/cards/discover.svg'
import generic from '../../../../assets/cards/generic.svg'
import jcb from '../../../../assets/cards/jcb.svg'
import mastercard from '../../../../assets/cards/mastercard.svg'
import unionpay from '../../../../assets/cards/unionpay.svg'
import visa from '../../../../assets/cards/visa.svg'
import Topbar from '../Topbar/Topbar';
import Subscribe from '../../../Landing/sections/Subscribe';
import Copyright from '../../../../components/CustomerDashboard/Copyright/Copyright';
import Tvisa from "../../../../assets/cards/trans-visa.png";
import Tmaster from "../../../../assets/cards/trans-master.png";
import Visacard from "../../../../assets/cards/visa-cart.png";
import Mastercard from "../../../../assets/cards/master-cart.png";
import Expresscard from "../../../../assets/cards/express-cart.png";
import Discovercard from "../../../../assets/cards/discover-cart.png";

import Cardno from "../../../../assets/cards/card-no.svg";

const stripe = new Stripe('sk_test_51N3TQzEFs7Vw7V4zl3sCYP6hOJZQSJCpLeKRTdX5nyJyXxy0xpdImQpgIiaT5tEiQ7qaeKMOf1NuX6l7dwMdb3us00NB1ud48X');

const MySubscriptions = () => {
    const [customer, setCustomer] = useState(null);
    const [customerSubscriptions, setCustomerSubscriptions] = useState(null);
    const [subscriptionData, setSubscriptionData] = useState(null);
    const [paymentData, setPaymentData] = useState(null);
    const [orderData, setOrderData] = useState(null);

    const customerState = useSelector((state) => state.customer);
    // const navigate = useNavigate();

    const getCustomerData = async (token) => {
        try {
            const res = await axios.get('https://store.platformz.us/rest/V1/customers/me', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (res.data) {
                setCustomer(res.data);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }
    useEffect(() => {
        if (customerState.accessToken) {
            getCustomerData(customerState.accessToken);
        }
    }, [customerState.accessToken]);

    const getCustomerSubscriptionData = async (token, customerId) => {
        try {
            const res = await axios.get('https://store.platformz.us/rest/V1/customers/' + customerId + '/stripe-subscriptions', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (res.data) {
                // console.log(res.data);
                setCustomerSubscriptions(res.data);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }
    useEffect(() => {
        if (customerState.accessToken && customer?.id) {
            getCustomerSubscriptionData(customerState.accessToken, customer?.id);
        }
        // console.log(customerState);
    }, [customerState, customerState.accessToken, customer?.id])

    // useEffect(() => {

    const fetchData = async (item) => {
        const subscriptionPromise = fetchStripeSubscriptionData(item.subscription_id);
        const paymentPromise = fetchStripePaymentData(item.payment_method_id);
        const customerOrderData = getCustomerOrderData("05dot6lrxguf0e7pr8eptoa1yu8lj9gb", item.order_increment_id);
        const [subscriptionData, paymentData, orderData] = await Promise.all([subscriptionPromise, paymentPromise, customerOrderData]);
        return { subscriptionData, paymentData, orderData };
    };

    useEffect(() => {
        customerSubscriptions?.forEach(async (item) => {
            const { subscriptionData, paymentData, orderData } = await fetchData(item);
            setSubscriptionData(subscriptionData);
            setPaymentData(paymentData);
            setOrderData(orderData);
        });
    }, [customerSubscriptions]);


    const getCustomerOrderData = async (token, orderId) => {
        try {
            const res = await axios.get('https://store.platformz.us/rest/V1/orders?searchCriteria[filter_groups][0][filters][0][field]=increment_id&searchCriteria[filter_groups][0][filters][0][value]=' + orderId, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (res.data) {
                // console.log(res.data);
                return res.data;
            }
        } catch (error) {
            return ['Error', error];
        }
    }

    const fetchStripeCustomerData = async (id) => {
        try {
            const retrievedCustomer = await stripe.customers.retrieve(id);
            return retrievedCustomer;
            // console.log();
        } catch (error) {
            console.error('Error fetching customer data:', error);
            return false;
        }
    };

    const fetchStripeSubscriptionData = async (id) => {
        try {
            const retrievedSubscription = await stripe.subscriptions.retrieve(id);
            return retrievedSubscription;
        } catch (error) {
            console.error('Error fetching subscription data:', error);
            return false;
        }
    };

    const fetchStripePaymentData = async (id) => {
        try {
            const retrievedPaymentMethod = await stripe.paymentMethods.retrieve(id);
            return retrievedPaymentMethod;
        } catch (error) {
            console.error('Error fetching payment data:', error);
            return false;
        }
    };
    // }, [])

    return (

        <>
            <Topbar />
            <div className={'min-h-screen'}>
                <div className={'page-main'}>
                    <Sidebar />
                    <div className={'main-content'} style={{
                        width: "calc(100% - 385px)"
                    }}>

                        <div className='address-sec'>
                            <h1 className='text-4xl font-medium'>My <span>Subscription</span></h1>
                        </div>

                        <div className='mb-12 default-address mysubs-info'>

                            <div className='block-mysubs-info' style={{ marginRight: "25px" }}>
                                <div className='mt-10 mb-6 section-title'>
                                    <div className='inner-title'>
                                        <span className='text'>Monthly Plan</span>
                                        <div className='toggle'>
                                            <label className="switch">
                                                <input type="checkbox" />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                        <span className='text'>Annual Plan</span>
                                    </div>
                                    <hr></hr>
                                </div>
                            </div>

                            <div className="subscription-box">
                                <div className="premium-boxes">
                                    <div className="subscription-details-info">
                                        <div className="classic">
                                            <div className='icon-txt'>
                                                <span className="ext-icon">&#x2716;</span>
                                                <strong className="activated">Inactive</strong>
                                            </div>
                                            <p className="state-well">
                                                Starter Package Bundle- <span>Basic Features</span>
                                            </p>
                                            <strong className="activated">Free</strong>
                                            <div className="bottom-details">
                                                <div className="arrow-shift-right">
                                                    <a href="#">Show Features</a>
                                                    <span className="double-line-arrow"></span>
                                                    <button className="patron">
                                                        <a href="#">Subscribe</a>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="classic">
                                            <div className='icon-txt'>
                                                <span className="ext-icon">&#x2716;</span>
                                                <strong className="activated">Inactive</strong>
                                            </div>
                                            <p className="state-well">
                                                Basic Package Bundle - <span>Quick Start Starter Site</span>
                                            </p>
                                            <strong className="activated">$7500</strong>
                                            <p>/Monthly</p>
                                            <div className="bottom-details">
                                                <div className="arrow-shift-right">
                                                    <a href="#">Show Features</a>
                                                    <span className="double-line-arrow"></span>
                                                    <button className="patron">
                                                        <a href="#">Downgrade</a>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="classic">
                                            <div className='icon-txt'>
                                                <span className="ext-icon">&#x2716;</span>
                                                <strong className="activated">Active</strong>
                                            </div>
                                            <p className="state-well">
                                                Advanced Package Bundle - <span>Shared Team</span>
                                            </p>
                                            <strong className="activated">$15000</strong>
                                            <p>/Monthly</p>
                                            <div className="bottom-details">
                                                <div className="arrow-shift-right">
                                                    <a href="#">Show Features</a>
                                                    <span className="double-line-arrow"></span>
                                                    <button className="patron">
                                                        <a href="#">Cancel Subscription</a>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="classic active-blue">
                                            <div className='icon-txt'>
                                                <span className="ext-icon">&#10003;</span>
                                                <strong className="activated">Active</strong>
                                            </div>
                                            <p className="state-well">
                                                Premium Package Bundle - <span>Dedicated Team</span>
                                            </p>
                                            <strong className="activated">$25000</strong>
                                            <p>/Monthly</p>
                                            <div className="bottom-details">
                                                <div className="arrow-shift-right">
                                                    <a href="#">Show Features</a>
                                                    <span className="double-line-arrow"></span>
                                                    <button className="patron">
                                                        <a href="#">Upgrade</a>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='block-address-info' style={{ marginRight: "25px" }}>
                                <div className='section-title'>
                                    <span className=''>Your Payment</span>
                                    <hr></hr>
                                </div>
                            </div>

                            <div className='your-payment'>
                                <div className="left-area">
                                    <div className="col-x1">
                                        <div className='toggle-sec'>
                                            <div className='toggle'>
                                                <label clasNames="switch">
                                                    <input type="checkbox" />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="img-text">
                                            <img src={Tvisa} alt="" />
                                            <p className='password'>**** **** **** 2687</p>
                                        </div>
                                    </div>
                                    <div className="col-x1">
                                        <div className='toggle-sec'>
                                            <div className="toggle">
                                                <label clasNames="switch">
                                                    <input type="checkbox" />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="img-text">
                                            <img src={Tmaster} alt="" />
                                            <p className='password'>**** **** **** 1538</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='right-area'>
                                    <div className='col-xl'>
                                        <img src={Visacard} alt='' />
                                        <img src={Mastercard} alt='' />
                                        <img src={Expresscard} alt='' />
                                        <img src={Discovercard} alt='' />
                                    </div>
                                    <div className='col-xl col-xm'>
                                        <div className="img-txt">
                                            <img src={Cardno} alt="" />
                                            <input type='text' placeholder='Card No.' />
                                        </div>
                                    </div>
                                    <div className='col-xl col-gnm'>
                                        <div className='col-xm spx-0'>
                                            <div className="img-txt">
                                                <input type='text' placeholder='Expiration Date' />
                                            </div>
                                        </div>
                                        <div className='col-xm spx-0'>
                                            <div className="img-txt">
                                                <input type='text' placeholder='CVV' />
                                            </div>
                                        </div>

                                    </div>
                                    <div className='col-xl col-xm spx-0'>
                                        <div className="img-txt">

                                            <input type='text' placeholder='Card Holder Name' />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div>
                                {customerSubscriptions?.length === 0 ?
                                    <div>You have placed no orders.</div>
                                    :
                                    <table style={{ width: "100%", borderCollapse: "separate", borderSpacing: "15px" }}>
                                        <thead>
                                            <tr style={{ borderBottom: "1px solid lightgrey" }}>
                                                <th style={{ textAlign: "left", width: "150px" }}>Order #</th>
                                                <th style={{ textAlign: "left" }}>Subscription</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {customerSubscriptions?.map((item) => {
                                                const date = new Date(subscriptionData?.plan.created * 1000);
                                                const day = date.getDate();
                                                const month = date.toLocaleString('default', { month: 'long' });

                                                const ordinalDay = day + (
                                                    [, 'st', 'nd', 'rd'][/1?.$/.exec(day)] || 'th'
                                                );
                                                let iconComponent;

                                                switch (paymentData?.card.brand.toLowerCase()) {
                                                    case 'amex':
                                                        iconComponent = amex;
                                                        break;
                                                    case 'cartes_bancaires':
                                                        iconComponent = cartes_bancaires;
                                                        break;
                                                    case 'diners':
                                                        iconComponent = diners;
                                                        break;
                                                    case 'discover':
                                                        iconComponent = discover;
                                                        break;
                                                    case 'generic':
                                                        iconComponent = generic;
                                                        break;
                                                    case 'jcb':
                                                        iconComponent = jcb;
                                                        break;
                                                    case 'mastercard':
                                                        iconComponent = mastercard;
                                                        break;
                                                    case 'unionpay':
                                                        iconComponent = unionpay;
                                                        break;
                                                    case 'visa':
                                                        iconComponent = visa;
                                                        break;
                                                    default:
                                                        iconComponent = null;
                                                }
                                                return (
                                                    <tr key={item.id}>
                                                        <td>
                                                            <Link to={`/dashboard/my-orders/${orderData?.items[0].entity_id}`}>{orderData?.items[0].increment_id}</Link>
                                                        </td>
                                                        <td>
                                                            <p>{orderData?.items[0].items[0].name}</p>
                                                            <p>{`${subscriptionData?.plan.nickname} last billed ${ordinalDay} ${month}`}</p>
                                                            <div>
                                                                <img
                                                                    src={iconComponent}
                                                                    alt={paymentData?.card.brand}
                                                                    style={{
                                                                        width: "32px",
                                                                        height: "32px"
                                                                    }}
                                                                />
                                                                <span>{`•••• ${paymentData?.card.last4}`}</span>
                                                                <span style={{ marginLeft: "15px" }}>{`${paymentData?.card.exp_month}/${paymentData?.card.exp_year}`}</span>
                                                                {console.log(orderData)}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>

                        <div style={{
                            marginRight: "-85px",
                            marginLeft: "-100px",
                            marginTop: "126px",
                            marginBottom: "-126px",
                        }}>
                            <Subscribe />
                            <Copyright />
                        </div>

                    </div>
                </div>

            </div>
        </>

    )
}

export default MySubscriptions