import {strapi_url} from "../../../../tools/api/api";
import React from "react";
import ReactGlobe from "react-globe";
import rotate from "../360-degree-rotate-icon.webp";
import ReactPlayer from "react-player";
import {Stream} from "@cloudflare/stream-react";

function SectionTypeB({item, currentPage, index, windowWidth}) {

    const RegularPreview = () => {
        return (
            <div className="features_template__section-row-picture"
                 style={{background: item.section_picture.data !== null ? 'transparent' : 'lightgray'}}>
                <img
                    src={item.section_picture.data && `${strapi_url}${item.section_picture.data.attributes.url}`}
                    alt={item.title}/>
            </div>
        )
    }
    const VideoPreview = (video) => {
        return (
            <div className="features_template__section-row-picture features_template__section-row-picture-video flex items-center "
                 style={{background: item.section_picture.data !== null ? 'transparent' : ''}}>

                <ReactPlayer url={video.video.url} width={"100%"} />


            </div>
        )
    }

    const VideoPreviewStream = (video) => {
        console.log(video)
        return (
            <div className="features_template__section-row-picture features_template__section-row-picture-video ">
                <div className={'lg:h-full lg:grid'}>
                    <Stream controls src={video.video} width={'100%'} className={'my-auto'}/>
                </div>
            </div>
        )
    }

    function imageComponent(currentPage, i, item) {
        if (item.video_id !== null) {
            switch (item.video_url) {
                case null:
                    return <RegularPreview/>
                    break;
                default:

                    return <VideoPreviewStream video={item.video_id}/>
                    break;


            }
        }
        else {
            switch (item.video_url) {
                case null:
                    return <RegularPreview/>
                    break;
                default:
                    return <VideoPreview video={item.video_url}/>
                    break;


            }
        }

    }

    return (
        <section
            className={`features_template__section ${item.section_color !== 'white' ? `features_template__section--${item.section_color}` : ''} `}>
            <div

                className={`features_template__section-row-content ${index % 2 === 1 && 'features_template__section-row-content--reverse'} features_template__section-row-content--ai-center`}>
                {imageComponent(currentPage, index, item)}

                <div className="features_template__section-row-text">
                    <p className="text-xl features_template__over-title--accent pb-4">
                        {item.over_title}
                    </p>
                    <h3 className="text-4xl">
                        {item.title}
                    </h3>
                    {item.section_list && (

                        <ul className="features_template__section-row-list features_template__section-row-list--flat">

                            {item.section_list.map((point, index) => {
                                return (
                                    <li className="features_template__section-row-list_item features_template__section-row-list_item--flat"
                                        key={index}>
                                        <div
                                            className="features_template__section-row-list_item-svg">
                                            <svg width="18" height="14"
                                                 viewBox="0 0 18 14"
                                                 fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M17.6644 0.252601C18.0772 0.619519 18.1143 1.25159 17.7474 1.66437L7.08075 13.6644C6.89099 13.8779 6.61898 14 6.33334 14C6.04771 14 5.7757 13.8779 5.58593 13.6644L0.252601 7.66437C-0.114317 7.25159 -0.077136 6.61952 0.335647 6.2526C0.74843 5.88568 1.3805 5.92286 1.74742 6.33565L6.33334 11.4948L16.2526 0.335647C16.6195 -0.077136 17.2516 -0.114317 17.6644 0.252601Z"
                                                    fill="#4ea0f2"/>
                                            </svg>
                                        </div>
                                        <p className={'text-xl'}>{point.point}</p>
                                    </li>
                                )
                            })}


                        </ul>
                    )}

                    {
                        item.btn_name && (<a href={item.btn_url}
                                             className="features_template__section-row-list_item-cta">{item.btn_name}
                            <svg width="8" height="14" viewBox="0 0 8 14"
                                 fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0.292893 13.7071C-0.0976311 13.3166 -0.0976312 12.6834 0.292893 12.2929L5.58579 7L0.292893 1.70711C-0.0976317 1.31658 -0.0976317 0.683417 0.292893 0.292893C0.683417 -0.0976315 1.31658 -0.0976315 1.70711 0.292893L7.70711 6.29289C8.09763 6.68342 8.09763 7.31658 7.70711 7.70711L1.70711 13.7071C1.31658 14.0976 0.683418 14.0976 0.292893 13.7071Z"
                                    fill="#FFFFFF"/>
                            </svg>
                        </a>)
                    }
                </div>
            </div>
        </section>
    )
}

export default SectionTypeB