import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import PageBanner from "../../../components/PageBanner/PageBanner";
import PricingSlider from "../../../components/PricingSlider/PricingSlider";
import { strapi_url } from "../../../tools/api/api";
import PricingTemplates from "../../InfoPages/Pricing/PricingComponents/PricingTemplates/PricingTemplates";
import PricingAddons from "../../InfoPages/Pricing/PricingComponents/PricingAddons/PricingAddons";
import TechnologySlider from "../../../components/TechnologySlider/TechnologySlider";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import Plan from "../../InfoPages/Pricing/Plan";

export default function DevSubscription() {
  const swiperRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const [plans, setPlans] = useState(null);
  const [isCheckhed, setIsCheckhed] = useState(false);

  const [plansType, setPlansType] = useState(
    "e-commerce-development-subscriptions"
  );

  const dynamicClass = `
  w-11 h-6
  rounded-full
  peer
  peer-checked:after:translate-x-full
  rtl:peer-checked:after:-translate-x-full
  peer-checked:after:border-white
  after:content-['']
  after:absolute
  after:top-[2px]
  after:start-[2px]
  after:bg-white
  after:border-gray-300
  after:border
  after:rounded-full
  after:h-5
  after:w-5
  after:transition-all

`;
  function getDataFromMagento(category) {
    const request = "https://strapi.platformz.us/api/plans?populate=deep";
    axios.get(request).then((res) => {
      const filtred = res.data.data.filter(
        (item) => item.attributes.category === category
      );
      const ordered = filtred.sort(
        (a, b) => a.attributes.rank - b.attributes.rank
      );
      setPlans(ordered);
    });
  }

  function getPlans(type) {
    //e-commerce-fixed-price-bundles
    //e-commerce-development-subscriptions
    const request = `https://strapi.platformz.us/api/e-commerce-development-subscriptions?populate=deep`;
    axios.get(request).then((res) => {
      const ordered = res.data.data.sort(
        (a, b) => a.attributes.rank - b.attributes.rank
      );
      setPlans(ordered);
    });
  }

  useEffect(() => {
    getPlans(plansType);
  }, []);

  useEffect(() => {
    if (plans) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [plans]);
  if (plans) {
    return (
      <>
        <div className={"my-swiper-container !p-0 md:!p-24"}>
          <div className={"mb-8"}>
            <p className={`text-center text-3xl font-semibold`}>
              SELECT YOUR DEVELOPMENT SUBSCRIPTIONS
            </p>
          </div>
          {loading && (
            <Swiper
              ref={swiperRef}
              modules={[Navigation]}
              grabCursor={true}
              navigation={{
                prevEl: ".pricing__button-prev",
                nextEl: ".pricing__button-next",
              }}
              loop={true}
              spaceBetween={24}
              slidesPerView={3}
              breakpoints={{
                0: { slidesPerView: 1 },
                786: { slidesPerView: plans.length < 3 ? 1 : 2 },
                1000: { slidesPerView: plans.length < 3 ? 1 : 3 },
              }}
            >
              {plans &&
                plans.map((plan) => (
                  <SwiperSlide>
                    <Plan
                      data={plan}
                      plansType={plansType}
                      nextStep={"/purchase/bundle"}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          )}
          {plans.length > 3 && (
            <div className="flex gap-12 ml-auto mt-12">
              <div className="pricing__button pricing__button-prev">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.7071 5.29289C15.0976 5.68342 15.0976 6.31658 14.7071 6.70711L9.41421 12L14.7071 17.2929C15.0976 17.6834 15.0976 18.3166 14.7071 18.7071C14.3166 19.0976 13.6834 19.0976 13.2929 18.7071L7.29289 12.7071C6.90237 12.3166 6.90237 11.6834 7.29289 11.2929L13.2929 5.29289C13.6834 4.90237 14.3166 4.90237 14.7071 5.29289Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="pricing__button pricing__button-next">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.29289 18.7071C8.90237 18.3166 8.90237 17.6834 9.29289 17.2929L14.5858 12L9.29289 6.70711C8.90237 6.31658 8.90237 5.68342 9.29289 5.29289C9.68342 4.90237 10.3166 4.90237 10.7071 5.29289L16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L10.7071 18.7071C10.3166 19.0976 9.68342 19.0976 9.29289 18.7071Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
