import React, {useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import InputText from "../FormComponents/InputText";
import InputEmail from "../FormComponents/InputEmail";
import InputPhone from "../FormComponents/InputPhone";
import InputTextArea from "../FormComponents/InputTextArea";
import InputFiles from "../FormComponents/InputFiles";
import axios from "axios";
import GoogleProtectedInfo from "../Forms/GoogleProtectedInfo/GoogleProtectedInfo";

function VacancyForm({position}) {
    const [status, setStatus] = useState(false)
    const {
        register, handleSubmit, getValues, setError, formState: {errors},
    } = useForm();
    const methods = useForm()




    const onSubmit = (data) => {

        const formData = new FormData();
        formData.append('fullName', data.fullName);
        formData.append('email', data.email);
        formData.append('phone', data.phone);
        formData.append('position', position);
        formData.append('address', data.address);

        Array.from(data.files).forEach((file, index) => {
            formData.append('attachment', file);
        });

        axios.post('https://devapi.platformz.us/api/sendgrid/send-message-vacansy', formData)
            .then(response => {

                if (response.data.status === "Email sent") {
                    setStatus(true)
                    window.scrollTo(0, 0);
                }
            })
            .catch(err => console.error(err));
    };
    if (position) {
        return (

            <FormProvider {...methods}>
                {!status ? <>
                    <p className={'text-3xl text-center mt-24'}>Leave an application</p>
                        <form action="#" className="space-y-8 w-2/3 m-auto pt-6" onSubmit={handleSubmit(onSubmit)}>
                            <InputText register={register} name={'fullName'} label={"Full Name"} placeholder={'Your Name'}
                                       errors={errors}
                                       options={{required: 'This field is required'}}/>
                            <InputEmail register={register} name={'email'} label={"Email"}
                                        placeholder={'email@platformz.us'}
                                        errors={errors}
                                        options={{required: 'This field is required'}}/>

                            <InputPhone register={register} name={'phone'} label={"Phone"} placeholder={'+14845691532'}
                                        errors={errors}
                                        options={{required: 'This field is required'}}/>
                            <InputText register={register} name={'address'} label={"Address"} placeholder={'Your Address'}
                                       errors={errors}
                                       options={{required: 'This field is required'}}/>

                            <InputTextArea register={register} name={'message'} label={"Your message"}
                                           placeholder={'Your message'}
                                           errors={errors}
                                           options={{required: 'This field is required'}}/>

                            <InputFiles register={register} name={'files'} label={"Files"} placeholder={'Your Name'}
                                        errors={errors}
                                        options={{required: 'This field is required'}}/>

                            <GoogleProtectedInfo/>
                            <button type="submit"
                                    className="features_template__section-row-list_item-cta">
                                APPLY
                            </button>
                        </form>
                    </> :
                    <>
                        <p className={'text-4xl text-center p-24'
                        }>Thank you for your interest in our vacancy !
                            We will contact you shortly</p></>}

            </FormProvider>)
    }
}

export default VacancyForm