import React, {useEffect, useState} from "react";
import axios from "axios";
import PageBanner from "../../../components/PageBanner/PageBanner";
import PricingSlider from "../../../components/PricingSlider/PricingSlider";
import {strapi_url} from "../../../tools/api/api";
import PricingTemplates from "../../InfoPages/Pricing/PricingComponents/PricingTemplates/PricingTemplates";
import PricingAddons from "../../InfoPages/Pricing/PricingComponents/PricingAddons/PricingAddons";
import TechnologySlider from "../../../components/TechnologySlider/TechnologySlider";
import {Link} from "react-router-dom";

export default function Addons() {
    const [content, setContent] = useState(null)
    const [templates, setTemplates] = useState(null)
    const [addons, setAddons] = useState(null)


    function getTemplaOptions() {
        axios.get('https://strapi.platformz.us/api/ecommerce-template-options?populate=deep')
            .then(res => {
                setTemplates(res.data.data)

            })
            .catch(err => console.error(err))
    }

    function getAddons() {
        axios.get('https://strapi.platformz.us/api/ecommerce-add-ons-and-features?populate=deep')
            .then(res => {
                setAddons(res.data.data)
                console.log('res.data.data', res.data.data)

            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        getTemplaOptions()
        getAddons()
        axios.get('https://strapi.platformz.us/api/build-your-platform?populate=deep')
            .then(res => {
                setContent(res.data.data.attributes)
            })
            .catch(err => console.error(err))
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        console.log(templates)
    }, [templates]);
    if (content) {


        return (
            <>
                <section className="pricing">

                    {addons && <PricingAddons title={content.addons_and_features_title}
                                              subtitle={content.addons_and_features_subtitle} addons={addons}/>}
                    <div className={'flex gap-4 justify-center'}>
                        <Link to={'/purchase/templates'}
                              className="features_template__section-row-list_item-cta mt-3"
                        >Back

                        </Link>
                        <Link to={'/checkout'}
                              className="features_template__section-row-list_item-cta mt-3"
                        >Next

                        </Link>
                    </div>
                </section>
                <TechnologySlider content={content.logo_scroller}/>
            </>
        )
    }
}