import './VerticalDetails.scss'
import React, {useEffect, useState} from "react";
import axios from "axios";
import {strapi_url} from "../../../tools/api/api";
import {BlocksRenderer} from "@strapi/blocks-react-renderer";
import {Link, useParams} from "react-router-dom";
import PageBanner from "../../../components/PageBanner/PageBanner";

import {UilArrowLeft} from "@iconscout/react-unicons";
import PortfolioDetailsGallery from "./PageComponents/PortfolioDetailsGallery/PortfolioDetailsGallery";

const VerticalDetails = () => {

    let {id} = useParams();
    const [content, setContent] = useState(null);

    useEffect(() => {
        if (id) {
            axios.get(`${strapi_url}/api/verticals/${id}?populate=deep`)
                .then(response => {
                    setContent(response.data.data.attributes)
                    console.log('verticals', response.data.data.attributes)
                })
                .catch(error => console.error(error))
        }


    }, [id])
    if (content) {
        return (
            <>
                <section className="pb-12 lg:pb-48">
                    {content.banner && <PageBanner content={content.banner}/>}
                    <div className={'container'}>
                        <div className={'pt-12'}>
                            <Link to={'/'} className={'text-3xl font-medium underline flex hover:opacity-70'}><UilArrowLeft/>Back to home</Link>
                        </div>

                        <div className="flex flex-col lg:flex-row  lg:gap-24 mt-12">
                            <div className="w-full lg:w-1/3 flex-shrink-0">
                                <div className="w-full">
                                    <img
                                        src={content.images.data && strapi_url + content.images.data.attributes.url}
                                        alt=""/>
                                </div>

                                {content.list && <ul className="portfolio_details__list text-4xl mt-12 mb-12">
                                    {content.list.map((item, index) => {
                                        return (
                                            <li key={index} className={''}>
                                                <p className={'font-bold uppercase text-4xl '}>{item.title}:</p>
                                                <p className={'font-medium text-gray-600 text-3xl'}>{item.text}</p>

                                            </li>
                                        )

                                    })}

                                </ul>}
                            </div>
                            <div className="">
                                {content.main_description !== null && <BlocksRenderer
                                    content={content.main_description}
                                    blocks={{
                                        // You can use the default components to set class names...
                                        paragraph: ({children}) => <p
                                            className="text-3xl font-medium pt-4 leading-10	">{children}</p>,
                                        list: ({children}) =>
                                            <ul className="text-5xl leading-10	 list-disc  list-outside font-medium flex flex-col gap-4 font-ligh pl-4">{children}</ul>,
                                        // ...or point to a design system
                                        heading: ({children, level}) => {
                                            switch (level) {
                                                case 1:
                                                    return <h1 className={'text-7xl pb-4 pt-10'}>{children}</h1>
                                                case 2:
                                                    return <h2 className={'text-6xl pb-4 pt-10'}>{children}</h2>
                                                case 3:
                                                    return <h3 className={'text-6xl pb-4 pt-10'}>{children}</h3>
                                                case 4:
                                                    return <h4 className={'text-6xl pb-4 pt-10'}>{children}</h4>
                                                case 5:
                                                    return <h5 className={'text-6xl pb-4 pt-10'}>{children}</h5>
                                                case 6:
                                                    return <h6 className={'text-6xl pb-4 pt-10'}>{children}</h6>
                                                default:
                                                    return <h1 className={'text-7xl pb-4 pt-10'}>{children}</h1>
                                            }
                                        },

                                        link: ({children, url}) => <Link to={url}
                                                                         className={'text-4xl underline'}>{children}</Link>,
                                    }}
                                    modifiers={{
                                        bold: ({children}) => <strong className={'text-4xl'}>{children}</strong>,
                                        italic: ({children}) => <span className="italic text-4xl">{children}</span>,
                                    }}
                                />}


                                {/*<a rel="noreferrer"  href={content.url && `${content.url}`} target={'_blank'}*/}
                                {/*   className={'block mt-12 text-3xl bg-blue-500 hover:bg-blue-600 w-fit pl-12 pr-12 pt-6 pb-6 rounded-md text-white'}>Check*/}
                                {/*    Live Link {}</a>*/}


                            </div>

                        </div>
                        {content.gallery.data !== null && <PortfolioDetailsGallery pictures={content.gallery.data}/>}
                    </div>

                </section>


            </>
        )
    }
}

export default VerticalDetails