import React, { useEffect, useState } from "react";
import axios from "axios";
import Showreel2 from "./sections/Showreel-2";
import TextSection from "./sections/TextSection";
import RecentWork from "./sections/RecentWork";
import Project from "./sections/Project";
import Testimonial from "./sections/Testimonial";
import Robot from "./sections/Robot";
import TechnologySlider from "../../components/TechnologySlider/TechnologySlider";
import Loader from "../../components/Loader/Loader";
import OurTeam from "./sections/OurTeam";
import MagentoSolution from "./sections/ShowCase/MagentoSolution";

const Homepage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  function getPageData() {
    axios
      .get("https://strapi.platformz.us/api/homepage?populate=deep")
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    getPageData();
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 2000);
  }, []);

  return (
    <>
      <Loader isActive={isLoading} />
      {data !== null && (
        <>
          <Showreel2 data={data.attributes.showreel} />

          <TechnologySlider content={data.attributes.Certifications} />

          <Robot data={data.attributes.robot_section} classMod={"pt-3"} />

          <TextSection data={data.attributes.section_02} classMod={` `} />

          <RecentWork data={data.attributes.recent_work} />

          <MagentoSolution />

          <Project data={data.attributes.estimate_your_project} />
          <OurTeam />
          <Testimonial data={data.attributes.testimonial} />

          <TechnologySlider content={data.attributes.logo_scroller} />
        </>
      )}
    </>
  );
};
export default Homepage;
