import React, { useEffect, useState } from 'react'
import Sidebar from '../../../../components/CustomerDashboard/Sidebar/Sidebar'
import './../Dashboard.scss'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Topbar from '../Topbar/Topbar'
import Subscribe from '../../../Landing/sections/Subscribe'
import Copyright from '../../../../components/CustomerDashboard/Copyright/Copyright'

const MyOrders = () => {
    const [customerOrders, setCustomerOrders] = useState(null);
    const [customer, setCustomer] = useState(null);

    const customerState = useSelector((state) => state.customer);


    const getCustomerData = async (token) => {
        try {
            const res = await axios.get('https://store.platformz.us/rest/V1/customers/me', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (res.data) {
                setCustomer(res.data);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }
    useEffect(() => {
        if (customerState.accessToken) {
            getCustomerData(customerState.accessToken);
        }
    }, [customerState.accessToken])

    const getCustomerOrderData = async (token, email) => {
        try {
            const res = await axios.get('https://store.platformz.us/rest/V1/orders?searchCriteria[filterGroups][0][filters][0][field]=customer_email&searchCriteria[filterGroups][0][filters][0][value]=' + email, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (res.data) {
                console.log(res.data);
                setCustomerOrders(res.data);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }
    useEffect(() => {
        if (customerState.accessToken && customer?.email) {
            getCustomerOrderData("05dot6lrxguf0e7pr8eptoa1yu8lj9gb", customer?.email);
        }
        // console.log(customerState);
    }, [customerState, customerState.accessToken, customer?.email])


    return (

        <>
            <Topbar />
            <div className={'min-h-screen'}>
                <div className={'page-main'}>
                    <Sidebar />
                    <div className={'main-content'} style={{
                        width: "calc(100% - 385px)"
                    }}>

                        <div>
                            <h1 className='text-4xl font-medium'>My <span>Orders</span></h1>
                        </div>
                        <div className='order-info change-name' style={{ clear: "none" }}>
                            <div className='section-title mt-10'>
                                <h1 className="text-4xl font-medium">View and manage all your orders</h1>
                                <hr></hr>
                            </div>
                        </div>
                        <div className='search-field'>
                            <p>Search Orders</p>
                            <input type='search' placeholder='Search All Orders' name='search' />
                        </div>

                        {customerOrders?.total_count === 0 ?
                            <div>You have placed no orders.</div>
                            :
                            <table className='dashboard-table' style={{ width: "100%", borderCollapse: "separate", borderSpacing: "0 15px" }}>
                                <thead>
                                    <tr style={{ borderBottom: "1px solid lightgrey" }}>
                                        <th style={{ textAlign: "left" }}>Order#</th>
                                        <th style={{ textAlign: "left" }}>Date</th>
                                        <th style={{ textAlign: "left" }}>Order Total</th>
                                        <th style={{ textAlign: "left" }}>Status</th>
                                        <th style={{ textAlign: "left" }}>Action</th>
                                    </tr>
                                </thead>
                                <hr></hr>
                                <tbody>
                                    {customerOrders?.items?.map((item) => {
                                        const inputDate = new Date(item?.created_at);

                                        const outputDate = `${inputDate.getMonth() + 1}/${inputDate.getDate()}/${inputDate.getFullYear().toString().slice(2)}`;
                                        return (
                                            <tr>
                                                <td>#{item?.increment_id}</td>
                                                <td>{outputDate}</td>
                                                <td><span>${item?.grand_total}</span></td>
                                                <td>{item?.status.charAt(0).toUpperCase() + item?.status.slice(1)}</td>
                                                <td className='edit'>
                                                    <Link to={'/dashboard/my-orders/' + item?.entity_id}>
                                                        <span style={{ paddingRight: "10px" }}>View Order</span>
                                                    </Link>
                                                </td>
                                                <td className='del'>
                                                    <span style={{ paddingLeft: "10px" }}>Reorder</span>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        }

                        <div style={{
                            marginRight: "-85px",
                            marginLeft: "-100px",
                            marginTop: "126px",
                            marginBottom: "-126px",
                        }}>
                            <Subscribe />
                            <Copyright />
                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}

export default MyOrders