import Container from "../../../components/Container/Container";
import {BlocksRenderer} from "@strapi/blocks-react-renderer";
import React from "react";

const TextSection = ({data, classMod}) => {
    if(data) {
    return (
        <section className={`text-section${classMod === "expertise" ? ' text-section text-section_expertise' : classMod}`}>
            <Container>
                <div className="">
                    <p className="text-3xl text-gray-500 font-medium text-center">
                        {data.overhead}
                    </p>
                    <div  dangerouslySetInnerHTML={{__html: data.description}} className={'max-w-full mx-auto font-light prose-gray prose lg:prose-xl w-full'}/>

                </div>
            </Container>
        </section>
    )}
}

export default TextSection