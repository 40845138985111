import Container from "../../components/Container/Container";
import './UnderConstuction.scss';
import robot from '../../assets/pic/robot_02.png'
import { useState } from "react";
import axios from "axios";

const UnderConstruction = () => {
    // eslint-disable-next-line
    const statuses = {
        done: 'subscribe__form_done',
        error: 'subscribe__form_err',
        inProgress: '',
    }
    const [email, setEmail] = useState('');
    // eslint-disable-next-line
    const [status, setStatus] = useState('inProgress');

    const handleSubmit = (e) => {
        e.preventDefault();


        const url = 'https://platformz.us21.list-manage.com/subscribe/post?u=b7e088bf16c7f73a2fcbf20ce&id=c8f5e48e16&f_id=0020e9e6f0';


        axios.post(`${url}&EMAIL=${email}`, {}, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
            .then(response => {

                setEmail('')
                setStatus('done')
                setTimeout(() => {
                    setStatus('inProgress')
                }, 1600)
            })
            .catch(error => {
                setStatus('err')
                setTimeout(() => {
                    setStatus('inProgress')
                }, 1600)
                console.error('Form err', error);
            });
    };
    return (<>

        <section className={'under_constuction'}>
            <Container>
                <div className="under_constuction__content">
                    <div className="under_constuction__pic">
                        <img src={robot} alt="" />
                    </div>
                    <div className="under_constuction__texts">
                        <p className="over-title">
                            We're
                        </p>
                        <h3 className="title__h3">Coming Soon!</h3>
                        <p className="sub-title">
                            Our website is under construction, we are working very hard to give you the best experience
                            with this one.
                        </p>
                        <form className="under_constuction__form" onSubmit={handleSubmit}>
                            <label>

                                <input placeholder={'Your Email ID...'} type="email"
                                    name="EMAIL"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)} required />
                            </label>
                            <button className={`under_constuction__send`}>
                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M12.1094 0C18.7988 0 24.2188 5.41992 24.2188 12.1094C24.2188 18.7988 18.7988 24.2188 12.1094 24.2188C5.41992 24.2188 0 18.7988 0 12.1094C0 5.41992 5.41992 0 12.1094 0ZM10.6982 7.01172L14.3848 10.5469H5.46875C4.81934 10.5469 4.29688 11.0693 4.29688 11.7188V12.5C4.29688 13.1494 4.81934 13.6719 5.46875 13.6719H14.3848L10.6982 17.207C10.2246 17.6611 10.2148 18.418 10.6787 18.8818L11.2158 19.4141C11.6748 19.873 12.417 19.873 12.8711 19.4141L19.3506 12.9395C19.8096 12.4805 19.8096 11.7383 19.3506 11.2842L12.8711 4.7998C12.4121 4.34082 11.6699 4.34082 11.2158 4.7998L10.6787 5.33203C10.2148 5.80078 10.2246 6.55762 10.6982 7.01172Z"
                                        fill="#2A2A2A" />
                                </svg>
                            </button>
                        </form>
                    </div>
                    <a href={'/'} className={'under_constuction__link'}>
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_5_1464)">
                                <path fillRule="evenodd" clipRule="evenodd"
                                    d="M20.625 13.75V19.25C20.625 20.7688 19.3938 22 17.875 22H2.75C1.23122 22 0 20.7688 0 19.25V4.125C0 2.60622 1.23122 1.375 2.75 1.375H8.25V4.125H2.75V19.25H17.875V13.75H20.625ZM19.246 4.69454L10.5933 13.3473L8.64874 11.4027L17.3015 2.75H12.371V0H21.996V9.625H19.246V4.69454Z"
                                    fill="black" />
                            </g>
                            <defs>
                                <clipPath id="clip0_5_1464">
                                    <rect width="22" height="22" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        Go to Home Page</a>


                </div>
            </Container>
        </section>
    </>)
}
export default UnderConstruction
