import { strapi_url } from "../../../../../tools/api/api";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updaterCartId } from "../../../../../tools/store/slices/guestSlice";
import { addToCard } from "../../../../../tools/store/slices/shippingCartSlice";

function PricingAddons({ addons, title, subtitle }) {
    const dispatch = useDispatch()
    const customer = useSelector(state => state.customer)
    const guest = useSelector(state => state.guest)


    const [count, setCount] = useState('12')
    const [content, setContent] = useState([])

    useEffect(() => {
        if (count === '12') {
            setContent(addons.slice(0, 12))
        } else if (count === '48') {
            setContent(addons.slice(0, 48))
        } else if (count === '96') {
            setContent(addons.slice(0, 96))
        } else if (count === 'all') {
            setContent(addons)
        }


    }, [addons, count])

    const addNext10Elements = () => {
        if (addons && addons.length > 0) {

            const next10Elements = addons.slice(content.length, content.length + 12);


            setContent((prevContent) => [...prevContent, ...next10Elements]);
        }
    };
    if (content.length !== 0) {


        return (
            <div className="pricing__templates">
                <div className={'lg:flex items-start justify-between'}>


                    <div>
                        <h3 className="text-4xl">
                            {title}
                        </h3>   <p className="text-xl pt-4 pb-8">
                            {subtitle}</p>
                    </div>

                    <div className={'mb-12 lg:mb-0'}>
                        <label htmlFor="countries"
                            className="block mb-2 text-xl font-medium text-gray-900 dark:text-white">Select
                            an option</label>
                        <select id="countries"
                            onChange={(e) => setCount(e.target.value)}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-xl font-medium  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option selected value="12">VIEW 12</option>
                            <option value="48">VIEW 48</option>
                            <option value="96">VIEW 96</option>
                            <option value="all">VIEW ALL</option>
                        </select>
                    </div>
                </div>


                <div className="pricing__templates-grid">
                    {content.map(i => {
                        return (
                            <div className="pricing__template">
                                <div className="pricing__template-content">
                                    <h5 className="text-xl text-white my-auto text-center mt-28">{i.attributes.name}</h5>
                                    <div className={'pricing__template-price'}>
                                        <p>${i.attributes.price}</p>
                                        <div className="flex gap-6">
                                            <button
                                                onClick={() => dispatch(addToCard(i.attributes.sku, customer.quote_id, customer.accessToken, guest.cart_id, i.attributes.name, i.attributes.price,))
                                                }>
                                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_1_2824)">
                                                        <path
                                                            d="M4 16.6667V4.66675H2V2.66675H5C5.26522 2.66675 5.51957 2.7721 5.70711 2.95964C5.89464 3.14718 6 3.40153 6 3.66675V15.6667H18.438L20.438 7.66675H8V5.66675H21.72C21.872 5.66675 22.022 5.70141 22.1586 5.76808C22.2952 5.83476 22.4148 5.9317 22.5083 6.05153C22.6019 6.17137 22.6668 6.31095 22.6983 6.45966C22.7298 6.60837 22.7269 6.7623 22.69 6.90975L20.19 16.9097C20.1358 17.126 20.011 17.3179 19.8352 17.4551C19.6595 17.5922 19.4429 17.6667 19.22 17.6667H5C4.73478 17.6667 4.48043 17.5614 4.29289 17.3739C4.10536 17.1863 4 16.932 4 16.6667ZM6 23.6667C5.46957 23.6667 4.96086 23.456 4.58579 23.081C4.21071 22.7059 4 22.1972 4 21.6667C4 21.1363 4.21071 20.6276 4.58579 20.2525C4.96086 19.8775 5.46957 19.6667 6 19.6667C6.53043 19.6667 7.03914 19.8775 7.41421 20.2525C7.78929 20.6276 8 21.1363 8 21.6667C8 22.1972 7.78929 22.7059 7.41421 23.081C7.03914 23.456 6.53043 23.6667 6 23.6667ZM18 23.6667C17.4696 23.6667 16.9609 23.456 16.5858 23.081C16.2107 22.7059 16 22.1972 16 21.6667C16 21.1363 16.2107 20.6276 16.5858 20.2525C16.9609 19.8775 17.4696 19.6667 18 19.6667C18.5304 19.6667 19.0391 19.8775 19.4142 20.2525C19.7893 20.6276 20 21.1363 20 21.6667C20 22.1972 19.7893 22.7059 19.4142 23.081C19.0391 23.456 18.5304 23.6667 18 23.6667Z"
                                                            fill="white" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1_2824">
                                                            <rect width="24" height="24" fill="white"
                                                                transform="translate(0 0.666748)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </button>


                                        </div>

                                    </div>

                                    <div className="pricing__template-details">
                                        <p className={'text-lg  text-white'}>{i.attributes.name}</p>
                                        <p className={'text-base font-light text-white'}>{i.attributes.description}</p>
                                    </div>
                                </div>
                                <div className="pricing__template-picture pricing__template-picture--object-contain">
                                    <img
                                        className={'object-contain'}
                                        src={strapi_url + i.attributes.picture.data.attributes.url}
                                        alt="" />
                                </div>
                            </div>
                        )
                    })}

                </div>

                {content.length !== addons.length && <div
                    className="features_template__section-row-list_item-cta cursor-pointer mt-12 ml-auto mr-auto"
                    onClick={addNext10Elements}>More

                </div>}

            </div>
        )
    }
}


export default PricingAddons