import React from "react";


import {Link, useNavigate} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import InputText from "../../../components/FormComponents/InputText";
import InputEmail from "../../../components/FormComponents/InputEmail";
import InputPhone from "../../../components/FormComponents/InputPhone";
import InputPassword from "../../../components/FormComponents/InputPassword";
import CheckBox from "../../../components/FormComponents/CheckBox";
import axios from "axios";

import {useDispatch} from "react-redux";

export default function OTPPage() {
    const {
        register, handleSubmit, getValues, setError, formState: {errors},
    } = useForm();

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const onSubmit = (data) => {

        navigate(`/phone-verification?phone=${data.phone.replace(/[^\d]/g, '')}`)


    };
    const methods = useForm()
    return (

        <section className="bg-gray-50 pt-80 pb-80 min-h-screen">
            <div className="flex flex-col items-center justify-center  mx-auto h-full px-12 md:px-24 lg:w-3/4 xl:w-1/2 xl:px-0  ">
                <div
                    className="w-full bg-white rounded-lg shadow">

                    <div className="pt-20 pb-16 flex justify-center">

                        <FormProvider {...methods}>
                            <form
                                className="space-y-2 w-3/4"
                                onSubmit={handleSubmit(onSubmit)}
                            >


                                <InputPhone register={register} name={'phone'} label={"Your Phone"}
                                            placeholder={'+14845691532'}
                                            errors={errors}
                                            options={{required: 'This field is required'}}/>

                                <p className="text-basic font-light text-gray-500">
                                    Enter your phone number for the One-Time Verification (OTP) procedure. This number
                                    will be used to send a unique confirmation code that is required to complete the
                                    verification process. We guarantee the confidentiality of your data and use it
                                    solely for the security of your account.
                                </p>


                                <button className="features_template__section-row-list_item-cta mx-auto">Send code
                                </button>
                                <Link to="/dashboard"  className="text-lg font-light  underline text-blue-400 hover:opacity-80 mx-auto text-center block pt-3">ENABLE LATER</Link>

                            </form>
                        </FormProvider>


                    </div>

                </div>
            </div>


        </section>
    );

}
