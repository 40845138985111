import Container from "../../../components/Container/Container";
import send from "../../../assets/shapes/send.png";
import axios from "axios";
import { useEffect, useState } from "react";
import { url } from "../../../tools/api/api";
import { useForm } from "react-hook-form";
import Notification from "../../../components/Notification";

const Subscribe = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const statuses = {
    done: "subscribe__form_done",
    error: "subscribe__form_err",
    inProgress: "",
  };
  // eslint-disable-next-line
  const { register, handleSubmit, errors } = useForm();
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("inProgress");

  const [data, setData] = useState(null);

  function getPageData() {
    axios
      .get("https://strapi.platformz.us/api/subscribe?populate=deep")
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    getPageData();
  }, []);

  const onSubmit = (data) => {
    axios
      .post(`${url}/api/customer/mailchimp-subscribe`, { email: data.email })
      .then((response) => {
        setEmail("");
        setStatus("done");
        debugger;
        Notification("success", "Newsletter subscribed successfully!");
        // setTimeout(() => {
        //   setStatus("inProgress");
        // }, 4000);
      })
      .catch((error) => {
        setStatus("error");
        debugger;
        Notification("error", "Newsletter not subscribed!");
        console.error("Form err", error);
      });
  };

  const largeScreenStyles = {
    background: `url(https://strapi.platformz.us${
      data && data.attributes.first_picture.data.attributes.url
    }) left 0 top 50% / 30% no-repeat, url(https://strapi.platformz.us${
      data && data.attributes.second_picture.data.attributes.url
    }) right 0 top 50%/ auto no-repeat`,
  };
  const smallScreenStyles = {
    background: `url(https://strapi.platformz.us${
      data && data.attributes.first_picture.data.attributes.url
    }) left 50% top 0 / 300px no-repeat, url(https://strapi.platformz.us${
      data && data.attributes.second_picture.data.attributes.url
    }) right 50% top 80%/ 300px no-repeat`,
  };

  return (
    <section className="subscribe">
      {status === "done" && (
        <div className="fixed w-full h-screen top-0 left-0 bg-zinc-900/[.6] flex align-middle z-50">
          <div className="container bg-white rounded-2xl h-fit mt-auto mb-auto w-3/6 pt-24 pb-24 pl-12 pr-12">
            <p className={"text-xl font-medium text-center"}>
              Thank you for joining us! Now you'll be the first to know about
              the latest news and promotions. We appreciate your interest and
              are eager to share exciting information with you.
            </p>
          </div>{" "}
        </div>
      )}

      <Container>
        <div className="subscribe__content">
          <div
            className="subscribe__subscribe"
            style={windowWidth <= 1300 ? smallScreenStyles : largeScreenStyles}
          >
            <div className={"subscribe__wrapper"}>
              <div className="subscribe__texts">
                <p className="subscribe__over-title text-2xl">
                  Subscribe to our
                </p>
                <h3 className="subscribe__title text-5xl">Newsletter</h3>
                <p className="subscribe__subtitle text-2xl">
                  Subscribe to our newsletter for updates!
                </p>
              </div>
              <form
                className={`subscribe__form ${statuses[status]}`}
                onSubmit={handleSubmit(onSubmit)}
              >
                <label>
                  <input
                    type="email"
                    {...register("email", { required: true })}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </label>
                <button className={`subscribe__send`}>
                  <img src={send} alt="" />
                </button>
              </form>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Subscribe;
