import Container from "../../../components/Container/Container";
import { Link } from "react-router-dom";
import React from "react";


const Project = ({ data }) => {
    const wrapFirstWordInSpan = (inputString) => {
        const words = inputString.split(' ');
        const firstWord = words[0];
        const restOfString = words.slice(1).join(' ');

        return (
            <><span>{firstWord}</span> {restOfString}</>
        );
    };
    return (
        <section className={`project mt150 `}>
            <Container>
                <div className="project__content max-w-full">
                    <div className="project__texts">
                        <div dangerouslySetInnerHTML={{ __html: data.description }}
                            className={'mx-auto  py-5 lg:pr-24'} />

                        <a href={data.url}
                            className="features_template__section-row-list_item-cta uppercase"
                            style={{ backgroundColor: '#4ea0f2' }}> REQUEST A QUOTE

                        </a>

                    </div>
                    <div className="project__picture">
                        <div className="project__robot bg-white border rounded-full h-fit p-2 animate-bounce lg:animate-none">
                            <img src={`https://strapi.platformz.us${data.robot.data.attributes.url}`} alt="" />
                        </div>

                        {data.picture.data &&
                            <img src={`https://strapi.platformz.us${data.picture.data.attributes.url}`}
                                alt={data.title} />}
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default Project