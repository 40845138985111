import React, { useEffect, useState } from 'react'
import Sidebar from '../../../../components/CustomerDashboard/Sidebar/Sidebar'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Topbar from '../Topbar/Topbar';
import Subscribe from '../../../Landing/sections/Subscribe';
import Copyright from '../../../../components/CustomerDashboard/Copyright/Copyright';
import emailsubs from "../../../../assets/shapes/emailsubs.svg";


const Newsletter = () => {
    const [customer, setCustomer] = useState(null);
    const [isSubscribe, setIsSubscribe] = useState(false);

    const customerState = useSelector((state) => state.customer);
    const navigate = useNavigate();

    const getCustomerData = async (token) => {
        try {
            const res = await axios.get('https://store.platformz.us/rest/V1/customers/me', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (res.data) {
                setCustomer(res.data);
                setIsSubscribe(res.data.extension_attributes.is_subscribed)
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }
    useEffect(() => {
        if (customerState.accessToken) {
            getCustomerData(customerState.accessToken);
        }
    }, [customerState.accessToken])


    const updateCustomer = () => {
        let payload = {
            "customer": {
                "email": customer?.email,
                "firstname": customer?.firstname,
                "lastname": customer?.lastname,
                "extension_attributes": {
                    "is_subscribed": isSubscribe
                }
            }
        }

        console.log(customer);
        axios.put(
            `https://store.platformz.us/rest/V1/customers/me`,
            payload,
            {
                headers: {
                    'Authorization': `Bearer ${customerState.accessToken}`,
                    'Content-Type': 'application/json',
                },
            }
        )
            .then(response => {
                // console.log('Customer updated successfully:', response.data);
                setCustomer(response.data)
                navigate('/dashboard')
            })
            .catch(error => {
                console.error('Error updating customer:', error);
            });
    }

    return (

        <>
            <Topbar />
            <div className={'min-h-screen'}>
                <div className={'page-main'}>
                    <Sidebar />
                    <div className={'main-content'} style={{
                        width: "calc(100% - 385px)"
                    }}>

                        <div className='address-sec'>
                            <h1 className='text-4xl font-medium'>Newsletter <span>Subscription</span></h1>
                        </div>

                        <div className='mb-12 default-address newsletter-subs'>
                            <div>
                                <div className='block-address-info' style={{ marginRight: "25px" }}>
                                    <div className='mt-10 mb-6 section-title'>
                                        <span className=''>Subscription option</span>
                                        <hr></hr>
                                    </div>

                                    <div className='subscription'>
                                        <div className='pic-txt'>
                                            <input className='sub-inp' type="checkbox" style={{ border: "1px solid grey" }} checked={isSubscribe} onChange={(e) => setIsSubscribe(!isSubscribe)} />
                                            <img src={emailsubs} alt="" />
                                            <label>General Subscription</label>
                                        </div>
                                        <div className='toggle'>
                                            <label className="switch">
                                                <input type="checkbox" />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>

                                </div>

                                <div className="mt-6 mb-6" style={{ clear: "both", float: "left" }}>
                                    <button className='save' style={{ padding: "10px 20px", color: "#fff", backgroundColor: "#1979c3", borderRadius: "5px" }} onClick={updateCustomer}>Save</button>
                                </div>
                            </div>
                        </div>


                        <div style={{
                            marginRight: "-85px",
                            marginLeft: "-100px",
                            marginTop: "126px",
                            marginBottom: "-126px",
                        }}>
                            <Subscribe />
                            <Copyright />
                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}

export default Newsletter