import facebook from "../../assets/social_icons/3225194_app_facebook_logo_media_popular_icon.gif";
import google from "../../assets/social_icons/1298745_google_brand_branding_logo_network_icon.gif";
import linkedin from "../../assets/social_icons/social_icon_loginPage/linkedin-icon.svg";
import React from "react";

function SSO() {
    const handleGoogleLogin = () => {
        localStorage.setItem('provider', JSON.stringify('google'));

        // const clientId = '721171443402-8j1p03r4i4uvnijnqgib0lb2ijb6bd9a.apps.googleusercontent.com';
        const clientId = '779340022013-5rub5ih6ejnjqvs6djp9pgh4g1dvf52c.apps.googleusercontent.com';

        const redirectUri = 'https://platformz.us/callback';
        // const redirectUri = 'http://localhost:3000/callback';

        const scopes = 'profile email';

        const authUrl = `https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes}&state&include_granted_scopes=true&response_type=code&access_type=offline&prompt=select_account&service=lso&o2v=1&flowName=GeneralOAuthFlow`;

        window.location.href = authUrl;
    };

    const handleFacebookLogin = () => {
        localStorage.setItem('provider', JSON.stringify('facebook'));
        // const clientId = '905454797846940';
        const clientId = '918627406321868';

        const redirectUri = 'https://platformz.us/callback';
        // const redirectUri = 'http://localhost:3000/callback';
        const url = 'https://www.facebook.com/v18.0/dialog/oauth?client_id=918627406321868&redirect_uri=https://platformz.us/callback&scope=email'
        const authUrl = `https://www.facebook.com/v18.0/dialog/oauth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=email;`;

        window.location.href = url;
    };

    const handleLinkedinLogin = () => {
        localStorage.setItem('provider', JSON.stringify('linkedin'));
        // const clientId = '77h42vlfprj5fu';
        const clientId = '7715d5fostq7bw';
        const redirectUri = 'https://platformz.us/callback';
        // const redirectUri = 'http://localhost:3000/callback';

        const authUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=foobar&scope=openid%20profile%20email`;

        window.location.href = authUrl;
    };

    return (
        <>
            <div className="mx-auto w-fit flex flex-col gap-6 md:flex-row w-full sm:w-fit">
                <button onClick={handleGoogleLogin}
                    className="text-white bg-[#4285F4] hover:bg-[#4285F4]/90 font-medium rounded-lg text-xl px-7 py-5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 mr-2 mb-2">
                    <svg className="mr-2 -ml-1 w-8 h-8" aria-hidden="true" focusable="false" data-prefix="fab"
                        data-icon="google" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512">
                        <path fill="currentColor"
                            d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"></path>
                    </svg>
                    Sign in with Google
                </button>


                <button
                    onClick={handleFacebookLogin}
                    className="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 font-medium rounded-lg text-xl px-7 py-5
                    text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 mr-2 mb-2">
                    <svg className="mr-2 -ml-1  w-8 h-8" aria-hidden="true" focusable="false" data-prefix="fab"
                        data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                        <path fill="currentColor"
                            d="M279.1 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.4 0 225.4 0c-73.22 0-121.1 44.38-121.1 124.7v70.62H22.89V288h81.39v224h100.2V288z"></path>
                    </svg>
                    Sign in with Facebook
                </button>


                <button
                    onClick={handleLinkedinLogin}
                    className="text-white bg-[#006699] hover:bg-[#006698]/90 font-medium rounded-lg text-xl px-7 py-5 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 mr-2 mb-2">
                    <svg className="mr-2 -ml-1  w-8 h-8" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M2 3.43268C2 2.64165 2.65752 2 3.46864 2H20.4053C21.2164 2 21.8739 2.64165 21.8739 3.43268V20.5673C21.8739 21.3586 21.2164 22 20.4053 22H3.46864C2.65752 22 2 21.3586 2 20.5673V3.43268Z"
                            fill="#006699" />
                        <path fill-rule="evenodd" clipRule="evenodd"
                            d="M8.02444 18.7422V9.71107H5.02266V18.7422H8.02444ZM6.52354 8.47802C7.57032 8.47802 8.22185 7.78453 8.22185 6.91787C8.20234 6.0317 7.57032 5.35746 6.5434 5.35746C5.51658 5.35746 4.84521 6.0317 4.84521 6.91787C4.84521 7.78453 5.49661 8.47802 6.50397 8.47802H6.52354Z"
                            fill="white" />
                        <path fill-rule="evenodd" clipRule="evenodd"
                            d="M9.6853 18.7422H12.6871V13.6988C12.6871 13.4289 12.7066 13.1593 12.7858 12.9663C13.0028 12.427 13.4967 11.8685 14.326 11.8685C15.4122 11.8685 15.8467 12.6967 15.8467 13.9108V18.7422H18.8482V13.5639C18.8482 10.7899 17.3673 9.49915 15.3924 9.49915C13.773 9.49915 13.0621 10.4043 12.6671 11.0208H12.6871V9.71107H9.68538C9.72478 10.5585 9.6853 18.7422 9.6853 18.7422Z"
                            fill="white" />
                    </svg>
                    Sign in with Linkedin
                </button>
                {/*<button className="btn_google">*/}
                {/*    <div className="box_img_btn_login">*/}
                {/*        <img src={google} alt="google"/>*/}
                {/*    </div>*/}
                {/*    <p>Sign in with Google</p>*/}
                {/*</button>*/}
                {/*<button>*/}
                {/*    <div className="box_img_btn_login">*/}
                {/*        <img src={linkedin} alt="linkedin"/>*/}
                {/*    </div>*/}
                {/*    <p>Sign in with LinkedIn</p>*/}
                {/*</button>*/}
            </div>
        </>
    )
}


export default SSO