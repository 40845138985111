import {useSelector} from "react-redux";

export default function OrderSummary() {
    const products = useSelector(state => state.shippingCart.products)

    return (

            <div className={'hidden xl:block max-w-[384px] w-full bg-[#F9F9F9] flex-shrink-0 py-12 px-6'}>
                <p className={'text-3xl font-medium text-[#005EA5]'}>Order Summary</p>
                {products && (
                    <>
                        <p className={'text-2xl font-medium text-[#005EA5] 4 pt-4'}>Items:</p>
                        <ul className={'space-y-4'}>
                            {
                                products.map(item => {
                                    return (
                                        <li key={item.item_id}>
                                            <p className={'text-lg font-medium'}>{item.name}</p>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        <div className={'flex justify-between text-lg mt-4 pt-4 border-t border-t-gray-300'}>
                            <p>Total</p>
                            <p>${products.reduce((a, b) => a + b.price, 0)}</p>
                        </div>
                    </>
                )}

            </div>

    )
}