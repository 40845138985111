import React from "react";
import {UilAngleLeft, UilAngleRight} from "@iconscout/react-unicons";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation} from "swiper/modules";
import {strapi_url} from "../../../../../tools/api/api";


function PortfolioDetailsGallery({pictures}) {
    const breakpoints = {
        1200: {
            slidesPerView: 3,
            loop: true,
        },
        0: {
            slidesPerView: 1,
        },
    };
    return (
        <div className={'w-full relative mt-24'}>


            <div className={'grid w-full relative'}>
                <Swiper
                    className={'w-full z-40'}
                    modules={[Navigation, Autoplay]}
                    navigation={{
                        prevEl: '.slider-btn-left',
                        nextEl: '.slider-btn-right',
                    }}
                    spaceBetween={48}
                    loop={true}
                    breakpoints={breakpoints}
                    autoplay={true}
                    grabCursor={true}

                >
                    {pictures.map((picture, index) => {
                        return (
                            <SwiperSlide>
                                <img src={strapi_url + picture.attributes.url} alt="" key={index}
                                     className={'object-cover w-full h-full aspect-square'}/>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
            <div className={'z-50 flex justify-end'}>
                <button className={'slider-btn-left hover:opacity-80'}><UilAngleLeft size={'70'}/></button>
                <button className={'slider-btn-right hover:opacity-80'}>
                    <UilAngleRight size={'70'}/>
                </button>
            </div>
        </div>
    )
}

export default PortfolioDetailsGallery