import { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import OrderSummary from "./components/OrderSummary";
import { useSelector } from "react-redux";

import robot from "../../assets/pic/robot312312.png";
import DevelopmentSubscription from "./subpages/DevelopmentSubscription";
import BundleSubscription from "./subpages/BundleSubscription";
import DevSubscription from "./subpages/DevSubscription";
import Templates from "./subpages/Templates";
import Addons from "./subpages/Addons";

const steps = {
  development_subscription: <DevSubscription />,
  bundle: <BundleSubscription />,
  templates: <Templates />,
  addons: <Addons />,
};

export default function Purchase({ state }) {
  const customer = useSelector((state) => state.customer);
  const location = useLocation();
  const [step, setStep] = useState("development_subscription");

  useEffect(() => {
    if (location.pathname === "/purchase/bundle") {
      setStep("bundle");
    } else if (location.pathname === "/purchase/templates") {
      setStep("templates");
    } else if (location.pathname === "/purchase/addons") {
      setStep("addons");
    } else {
      setStep("development_subscription");
    }
  }, [location]);

  return (
    <div>
      <nav
        className={
          "container mx-auto mt-12 md:mt-48 flex flex-col lg:flex-row items-center gap-12 justify-between relative"
        }
      >
        <div
          className={
            "hidden lg:block w-full h-[1px] absolute top-0 bottom-0 left-0 bg-[#D9D9D9] my-auto"
          }
        ></div>
        <div className={"px-4 bg-white relative z-40"}>
          <p
            className={`text-lg font-medium ${
              step === "development_subscription"
                ? "text-[#005EA5]"
                : "text-[#CACBCF]"
            }`}
          >
            Development Subscription
          </p>
        </div>
        <div className={"px-4 bg-white relative z-40"}>
          <p
            className={`text-lg font-medium ${
              step === "bundle" ? "text-[#005EA5]" : "text-[#CACBCF]"
            }`}
          >
            Fixed Price Bundle{" "}
          </p>
        </div>
        <div className={"px-4 bg-white relative z-40"}>
          <p
            className={`text-lg font-medium ${
              step === "templates" ? "text-[#005EA5]" : "text-[#CACBCF]"
            }`}
          >
            Template Options
          </p>
        </div>
        <div className={"px-4 bg-white relative z-40"}>
          <p
            className={`text-lg font-medium ${
              step === "addons" ? "text-[#005EA5]" : "text-[#CACBCF]"
            }`}
          >
            Add-ons and Features
          </p>
        </div>
      </nav>
      <div className={"container"}>{steps[step]}</div>
    </div>
  );
}
