import './FeaturesTemplate.scss'
import axios from "axios";
import {strapi_url} from "../../../tools/api/api";
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import TechnologySlider from "../../../components/TechnologySlider/TechnologySlider";
import PageBanner from "../../../components/PageBanner/PageBanner";

import SectionTypeA from "./components/SectionTypeA";
import SectionTypeB from "./components/SectionTypeB";
import SectionTypeC from "./components/SectionTypeC";

const FeaturesTemplate = () => {
    const location = useLocation();
    const [content, setContent] = useState(null)
    const [contentExtra, setContentExtra] = useState(null)

    const [currentPage, setCurrentPage] = useState(null);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };


    }, []);

    useEffect(() => {
        setCurrentPage(location.pathname.substring(1));
    }, [location.pathname]);

    function c_p(p) {
        if (p === '3d-virtualization') {
            return 'test-02'
        } else if (p === 'e-commerce') {
            return 'e-commerce'
        } else if (p === 'animation-and-video') {
            return 'animation-and-video'
        }
    }

    function getContent(cp) {

        axios.get(`${strapi_url}/api/${c_p(cp)}?populate=deep`)
            .then((response) => {
                setContent(response.data.data.attributes)
                console.log(response.data.data.attributes)
                if(cp === '3d-virtualization') {
                    axios.get(`${strapi_url}/api/animation-and-video?populate=deep`)
                        .then((res) =>     setContentExtra(res.data.data.attributes.section)  )
                }


            })
            .catch(error => console.error(error))
    }

    useEffect(() => {
        if (currentPage !== null) {
            // eslint-disable-next-line
            getContent(currentPage)
        }
        // eslint-disable-next-line
    }, [currentPage])

    if (content ) {
        return (


            <>
                <section className={'features_template'}>
                    <PageBanner content={content.banner}/>

                    <div className="features_template__wrapper pt-24 lg:pt-12">

                        {
                            // eslint-disable-next-line
                            contentExtra !== null ?
                            [...content.section, ...contentExtra].map((item, index) => {
                                if (item.section_type === 'type_a') {
                                    return (
                                        <SectionTypeA item={item} currentPage={currentPage} index={index}
                                                      windowWidth={windowWidth}/>
                                    )
                                } else if (item.section_type === "type_b") {
                                    return (
                                        <SectionTypeB item={item} currentPage={currentPage} index={index}
                                                      windowWidth={windowWidth}/>


                                    )
                                }
                                else if (item.section_type === "type_c") {
                                    return (
                                        <SectionTypeC item={item} currentPage={currentPage} index={index}
                                                      windowWidth={windowWidth}/>


                                    )
                                }

                            }) :
                                content.section.map((item, index) => {
                                    if (item.section_type === 'type_a') {
                                        return (
                                            <SectionTypeA item={item} currentPage={currentPage} index={index}
                                                          windowWidth={windowWidth}/>
                                        )
                                    } else if (item.section_type === "type_b") {
                                        return (
                                            <SectionTypeB item={item} currentPage={currentPage} index={index}
                                                          windowWidth={windowWidth}/>


                                        )
                                    }
                                    else if (item.section_type === "type_c") {
                                        return (
                                            <SectionTypeC item={item} currentPage={currentPage} index={index}
                                                          windowWidth={windowWidth}/>


                                        )
                                    }

                                })


                        }


                    </div>


                </section>
                <TechnologySlider content={content.logo_scroller}/>

            </>
        )
    }
}

export default FeaturesTemplate