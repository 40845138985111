import React, {useEffect, useState} from "react";
import axios from "axios";
import {strapi_url} from "../../../tools/api/api";
import {BlocksRenderer} from "@strapi/blocks-react-renderer";
import {Link} from "react-router-dom";
import PageBanner from "../../../components/PageBanner/PageBanner";
import CKEditorRender from "../../../components/CKEditorText/CKEditorRender";

function ReferralProgram() {
    const [content, setContent] = useState(null);
    useEffect(() => {
        axios.get(`${strapi_url}/api/referral-program?populate=deep`)
            .then(response => {
                setContent(response.data.data.attributes)
                console.log('venture-program', response.data.data.attributes)
            })
            .catch(error => console.error(error))

    }, [])
    if (content) {
        return (
            <div className="venture_program ">
                <PageBanner content={content.banner}/>
                <div className="container  ml-auto mr-auto  pb-12 pt-12 w-3/4">
                    <CKEditorRender text={content.section_01} />


                </div>
                <div className="container relative">
                        <CKEditorRender text={content.section_02_description} />
                </div>
                <div className="container mt-12">
                    <CKEditorRender text={content.section_02} />
                    <div className="venture_program__list py-12">

                        {content.list.map(point => {
                            return (
                                <div>
                                    <h5 className="text-xl pb-2">{point.title}</h5>
                                    <CKEditorRender text={point.description} />

                                </div>
                            )
                        })}


                    </div>

                </div>
                <div className="wrapper_join_us">
                    <div className="join_us_content container">
                        <CKEditorRender text={content.section_03} />
                        <Link to="/registration?referral" className="features_template__section-row-list_item-cta mt-12"><span className={'text-white text-3xl'}>JOIN NOW</span></Link>
                    </div>
                </div>
            </div>

        );
    }
}

export default ReferralProgram