

const Robot = ({data}) => {
    return (
        // src={`https://strapi.platformz.us${item.picture.data.attributes.url}`}

        <section className={`section-robot`}>
            <div className="section-robot__image">
                <img src={`https://strapi.platformz.us${data.picture.data.attributes.url}`} alt=""/>
            </div>

        </section>)
}
export default Robot