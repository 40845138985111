import {useState} from 'react';
import {PhoneInput} from 'react-international-phone';
import 'react-international-phone/style.css';
import {Controller, useForm} from "react-hook-form";
import {log} from "three/examples/jsm/nodes/math/MathNode";

export function InputPhone({register, options, name, label, placeholder, errors}) {

    const { control, setValue} = useForm()

    const [phone, setPhone] = useState('');

    const style = 'bg-gray-50 border text-xl border-gray-300 text-gray-900 rounded-lg font-medium  focus:ring-primary-600 focus:border-primary-600 block w-full p-6 pt-8 pb-8'


    return (
        <div className={'w-full relative'}>
            <label
                className="block mb-4 text-xl font-medium text-gray-900">{label}</label>
            <Controller
                control={control}

                name={name}
                render={({ field: { onChange, value } }) => (
                    <>
                        <PhoneInput
                            {...register(name, {...options})}

                            value={value}
                            onChange={onChange}
                            placeholder="Enter phone number"
                            inputProps={{className: style,}}
                            countrySelectorStyleProps={{
                                buttonClassName: '!border-0 !h-full !bg-gray-50 !pl-2',
                            }}
                        /></>
                )}
            />




            {errors[name] && (
                <p className={'text-base text-red-600 font-light pt-4'}>{errors[name].message}</p>)}


        </div>


    )
}

export default InputPhone