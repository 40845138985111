import React from "react"
import {Link} from "react-router-dom";


export function CheckBox({register, options, name, label, errors, path}) {
    const style = 'bg-gray-50 border text-xl border-gray-300 text-gray-900 rounded-lg font-medium   focus:ring-primary-600 focus:border-primary-600 block w-full p-6 pt-8 pb-8'

    return (
        <div>
            <div className="flex items-start">
                <div className="flex items-center h-8">
                    <input

                        {...register(name, {
                            ...options
                        })}
                        type="checkbox"
                        className="w-8 h-8 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                    />
                </div>

                {path ? <div className="ml-3 text-sm">
                        <label htmlFor="terms" className="font-light text-xl text-gray-500">
                            I accept{' '}
                            <Link className="font-light text-blue-400  text-xl hover:underline"
                                  to={path}>{label}</Link>
                        </label>
                    </div> :
                    <div className="ml-3 text-sm">
                        <label htmlFor="terms" className="font-light text-xl text-gray-500">
                            {label}
                        </label>
                    </div>}


            </div>

            {errors[name] && (<p className={'text-base text-red-600 font-light pt-4'}>{errors[name].message}</p>)}
        </div>


    )
}

export default CheckBox