import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/CustomerDashboard/Sidebar/Sidebar'
import './Dashboard.scss'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { countries } from 'countries-list'
import Topbar from './Topbar/Topbar'
import editIcon from '../../../assets/img/edit.svg'
import Subscribe from '../../Landing/sections/Subscribe'
import Copyright from '../../../components/CustomerDashboard/Copyright/Copyright'

const Dashboard = () => {
  const [customer, setCustomer] = useState(null);

  const customerState = useSelector((state) => state.customer);
  const getCustomerData = async (token) => {
    try {
      const res = await axios.get('https://store.platformz.us/rest/V1/customers/me', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (res.data) {
        setCustomer(res.data);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
  useEffect(() => {
    if (customerState.accessToken) {
      getCustomerData(customerState.accessToken);
    }
  }, [customerState.accessToken])


  return (
    <>
      <Topbar />
      <div className={'min-h-screen'}>
        <div className={'page-main'}>
          <Sidebar />
          <div className={'main-content'} style={{
            width: "calc(100% - 385px)"
          }}>
            <div className='page-heading'>
              <h1><span>My</span> <span className='highlight'>Account</span></h1>
            </div>

            <div className='block-dashboard-info'>
              <div className='section-title'>
                <span>Account Information</span>
                <hr />
              </div>
              <div className='block-content'>
                <div className='block-left'>
                  <h4 className='block-title'>Contact Information</h4>
                  <div className='block-data-name'>
                    <span>{customer?.firstname} {customer?.lastname}</span>
                    <span><Link to={'/dashboard/my-account/edit'} >Edit
                      <img className='edit' src={editIcon} alt='edit' />

                    </Link></span>
                  </div>
                  <div className='block-data-email'>
                    <span>{customer?.email}</span>
                  </div>
                  <div className='block-data-change'>
                    <span>........</span>
                    <span>
                      <Link to={'/dashboard/my-account/edit'} >Change Password
                        <img className='edit' src={editIcon} alt='edit' />
                      </Link>
                    </span>
                  </div>
                </div>
                <div className='block-right'>
                  <h4 className='block-title'>Newsletters</h4>
                  <div className='block-data-subs'>

                    {customer?.extension_attributes?.is_subscribed ?
                      <span>You are subscribed to "General Subscription".</span> :
                      <span>You aren't subscribed to our newsletter.</span>

                    }
                    <span>
                      <Link to={'/dashboard/newsletter'} >
                        Edit
                        <img className='edit' src={editIcon} alt='edit' />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>


            <div className='block-address-info'>
              <div className='section-title'>
                <span>Default Address</span>
                <hr />
              </div>
              <div className='block-content'>
                <div className='block-left'>
                  <h4 className='block-title'>Default Billing Address</h4>
                  {customer?.addresses?.filter(address => address.default_billing === true).map((address) => {
                    console.log(address);
                    return (
                      <div className='default-address'>
                        <p>{address.firstname} {address.lastname}</p>
                        {address.company && <p>{address.company}</p>}
                        {address.street[0] && <p>{address.street[0]}</p>}
                        {address.street[1] && <p>{address.street[1]}</p>}
                        <p>{address.city}{address.region.region && ", " + address.region.region}{address.postcode && ", " + address.postcode}</p>
                        <p>{address.country_id && ", " + countries[address.country_id]?.name}</p>
                        <p>T: <Link to={'tel:' + address.telephone}>{address.telephone}</Link></p>
                        <Link to={'edit/' + address.id}>
                          <p>Edit Address</p>
                          <img className='edit' src={editIcon} alt='edit' />

                        </Link>
                      </div>

                    )
                  })}
                  {customer?.addresses?.filter(address => address.default_billing === true).length === 0 &&
                    <div className='default-address'>
                      <p style={{
                        display: "inline-block"
                      }}>You have not set a default billing address.</p>
                      <span className="edit-address-container">
                        <Link to={'/dashboard/my-account/edit'} >Edit Address
                          <img className='edit' src={editIcon} alt='edit' />
                        </Link>
                      </span>
                    </div>
                  }

                </div>
                <div className='block-right'>
                  <h4 className='block-title'>Default Shipping Address</h4>
                  {customer?.addresses?.filter(address => address.default_shipping === true).map((address) => {
                    return (
                      <div className='default-address'>
                        <p>{address.firstname} {address.lastname}</p>
                        {address.company && <p>{address.company}</p>}
                        {address.street[0] && <p>{address.street[0]}</p>}
                        {address.street[1] && <p>{address.street[1]}</p>}
                        <p>{address.city}{address.region.region && ", " + address.region.region}{address.postcode && ", " + address.postcode}</p>
                        <p>{address.country_id && ", " + countries[address.country_id]?.name}</p>
                        <p>T: <Link to={'tel:' + address.telephone}>{address.telephone}</Link></p>
                        <Link to={'edit/' + address.id}>
                          <p>Edit Address</p>
                          <img className='edit' src={editIcon} alt='edit' />
                        </Link>
                      </div>

                    )
                  })}
                  {customer?.addresses?.filter(address => address.default_shipping === true).length === 0 &&
                    <div className='default-address'>
                      <p style={{
                        display: "inline-block"
                      }}>You have not set a default shipping address.</p>
                      <span className="edit-address-container">
                        <Link to={'/dashboard/my-account/edit'} >Edit Address
                          <img className='edit' src={editIcon} alt='edit' />
                        </Link>
                      </span>
                    </div>
                  }
                </div>
              </div>
            </div>

            <div style={{
              marginRight: "-85px",
              marginLeft: "-100px",
              marginTop: "126px",
              marginBottom: "-126px",
            }}>
              <Subscribe />
              <Copyright />
            </div>

          </div>
        </div>

      </div>
    </>
  )
}

export default Dashboard