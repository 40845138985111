import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import axios from "axios";
import {removeFromLocalCart, updateShoppingCart} from "../../../tools/store/slices/shippingCartSlice";

export default function ShoppingCart() {
    const dispatch = useDispatch()
    const customer = useSelector(state => state.customer)
    const shippingCart = useSelector(state => state.shippingCart)
    function removeFromCard(id, accessToken, cart_id) {
        if (accessToken) {
            axios.delete(`https://store.platformz.us/rest/V1/carts/mine/items/${id}`, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            })
                .then(response => {
                    dispatch(updateShoppingCart(customer.accessToken, cart_id))
                })

        } else {
            axios.delete(`https://store.platformz.us/rest/V1/guest-carts/${cart_id}/items/${id}`, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            })
                .then(response => {
                    dispatch(updateShoppingCart(customer.accessToken, cart_id))
                })
        }
    }
    return (
        <>
            {shippingCart.products && shippingCart.products.length !== 0 &&
                <div className={'container bg-[#F9F9F9] '}>
                    <ul className={`mt-24 space-y-4 ${shippingCart.products.length > 4 && "w-full max-h-[500px] overflow-y-scroll"}`}>
                        {shippingCart.products.map(item => {
                            return (
                                <li className={'text-3xl border-2 border-[#005EA5] p-4 rounded-xl'}>
                                    <div className="lg:flex w-full justify-between">
                                        <p className={'text-2xl text-[#005EA5]'}>{item.name}</p>
                                        <p className={'text-2xl'}>${item.price}</p>
                                    </div>
                                    <div className="">
                                        <button
                                            onClick={() => {
                                                dispatch(removeFromLocalCart({sku: item.sku}))
                                                removeFromCard(item.item_id, customer.accessToken, customer.cart_id)
                                            }}
                                            className={'text-xl font-medium text-red-700 opacity-70 hover:opacity-100 pointer'}>Remove
                                        </button>
                                    </div>

                                </li>
                            )
                        })}


                    </ul>
                    <div>
                        <div className={'text-3xl border-t border-t-gray-300 pt-6 my-12'}>
                            <div className="flex w-full justify-between">
                                <p className={'text-3xl text-[#005EA5]'}>Total</p>
                                <p className={'text-2xl'}>${shippingCart.products.reduce((a, b) => a + b.price, 0)}</p>

                            </div>

                        </div>
                    </div>
                    <div className={'flex items-center flex-col lg:flex-row justify-center gap-6 mt-12'}>
                        <Link to={'/'}
                              className="features_template__section-row-list_item-cta w-full max-w-[300px]"
                        >Back

                        </Link><Link to={'/checkout/details'}
                                     className="features_template__section-row-list_item-cta w-full max-w-[300px]"
                    >Proceed to checkout

                    </Link>
                    </div>

                </div>
            }
        </>
    )
}