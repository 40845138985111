
export default function MagentoSolution() {
  return (
    <section className="mt-20 relative flex justify-center items-center">
      <video
        src="/background.mp4"
        className="w-screen h-[590px] max-w-full object-fill"
        autoPlay
        muted
        loop
      />
      <div className="absolute top-0 w-full h-full flex justify-center items-center">
        <div class="sketchfab-embed-wrapper !w-[590px] h-full">
          <iframe
            title="A Windy Day"
            allowfullscreen
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            src="https://sketchfab.com/models/fb78f4cc938144e6902dd5cff354d525/embed?autostart=1&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_watermark_link=0&ui_watermark=0&ui_hint=0&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&dnt=1"
            className="w-full h-full"
          >
          </iframe>
        </div>
        <div className="font-bold font-helvetica">
          <div className="text-white text-5xl text-center">Empowering Your Business with</div>
          <div className="text-white text-[64px] text-center">Enterprise-Class</div>
          <div className="text-[#3DC9E8] text-[64px] text-center">Magento Solutions</div>
        </div>
      </div>
    </section>
  )
}