import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useState } from "react";
import axios from "axios";
import { strapi_url } from "../../../tools/api/api";
import Container from "../../../components/Container/Container";
import arrow_blue_left from "../../../assets/shapes/left-arrow-blue.svg";
import EffectCarousel from "../effect-carousel.esm";
import arrow_blue_right from "../../../assets/shapes/right-arrow-blue.svg";

import noPhoto from "../../../assets/pic/no-photo.webp";

function Member({ member, onClick }) {
  return (
    <>
      <div
        onClick={() => onClick()}
        className={
          "bg-[#005FA6] rounded-xl overflow-hidden cursor-pointer border-2 transition-all ease-linear border-transparent hover:border-blue-400"
        }
      >
        <div className={"aspect-square w-full bg-[#2997E8] overflow-hidden"}>
          <img
            src={
              member.attributes.photo.data !== null
                ? strapi_url + member.attributes.photo.data.attributes.url
                : noPhoto
            }
            alt=""
            className={"w-full h-full object-cover"}
          />
        </div>
        <div className={"px-4 pt-3 pb-6"}>
          <h3 className={"text-[40px] text-white"}>{member.attributes.name}</h3>
          <p className={"text-lg text-white"}>{member.attributes.position}</p>
        </div>
      </div>
    </>
  );
}

export default function OurTeam() {
  const [data, setData] = useState(null);
  const [popup, setPopup] = useState(false);
  const [currentMebmer, setCurrentMebmer] = useState(0);

  useEffect(() => {
    axios.get(`${strapi_url}/api/our-teams?populate=deep`).then((res) =>
      setData(
        res.data.data.sort(function (a, b) {
          return a.id - b.id;
        })
      )
    );
  }, []);

  function nextMember() {
    console.log(currentMebmer, data.length);
    if (currentMebmer === data.length - 1) {
      setCurrentMebmer(0);
    } else {
      setCurrentMebmer(currentMebmer + 1);
    }
  }

  if (data) {
    return (
      <>
        {popup && (
          <div
            className={
              "w-full h-full bg-black bg-opacity-90 fixed z-50 flex items-center justify-center"
            }
          >
            <button
              className={
                "absolute top-12 right-12 hover:opacity-70 hover:scale-125 transition-all ease-linear"
              }
              onClick={() => setPopup(false)}
            >
              <svg
                width="49"
                height="49"
                viewBox="0 0 49 49"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L47.6875 47.6875"
                  stroke="#4EA0F2"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1 47.6875L47.6875 1"
                  stroke="#4EA0F2"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <div
              className={
                "container pt-28 lg:pt-0 lg:flex items-center justify-center gap-12"
              }
            >
              <div
                className={
                  "max-w-[500px] rounded-xl overflow-hidden flex-shrink-0 bg-blue-300"
                }
              >
                <img
                  src={
                    data[currentMebmer].attributes.photo.data !== null
                      ? strapi_url +
                      data[currentMebmer].attributes.photo.data.attributes.url
                      : noPhoto
                  }
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
              <div
                className={"px-4 pt-3 pb-6 max-w-[700px] w-full flex-shrink-0"}
              >
                <h3 className={"text-[40px] text-white"}>
                  {data[currentMebmer].attributes.name}
                </h3>
                <p className={"text-xl text-[#4EA0F2]"}>
                  {data[currentMebmer].attributes.position}
                </p>
                <p className={"text-white text-[25px] font-medium pt-5"}>
                  {data[currentMebmer].attributes.bio}
                </p>
                <p
                  className={
                    "text-white text-xl underline font-medium pt-5 cursor-pointer hover:opacity-70"
                  }
                  onClick={() => nextMember()}
                >
                  Next member →
                </p>
              </div>
            </div>
          </div>
        )}

        <section className="testimonial py-10 md:py-32">
          <Container>
            <div className="testimonial__content">
              <div className="testimonial__titles">
                {/*<p className="text-6xl font-medium">our</p>*/}
                <h3 className="text-7xl text-[#4ea0f2]">Team</h3>
              </div>
            </div>
            <div className="grid mt-10 md:mt-20 relative">
              <div className="testimonial__btn arrow_blue_left">
                <img src={arrow_blue_left} alt="" />
              </div>

              <Swiper
                modules={[EffectCarousel, Pagination, Navigation]}
                effect={"carousel"}
                navigation={{
                  prevEl: ".arrow_blue_left",
                  nextEl: ".arrow_blue_right",
                }}
                slidesPerView={1}
                centeredSlides={true}
                loop={true}
                carouselEffect={{
                  // opacity change per side slide
                  opacityStep: 0,
                  // scale change per side slide
                  scaleStep: 0.2,
                  // amount of side slides visible, can be 1, 2 or 3
                  sideSlides: 1,
                }}
                // loopAdditionalSlides={0}
                pagination={true}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    carouselEffect: {
                      sideSlides: 1,
                    },
                  },
                  786: {
                    slidesPerView: 2,
                    carouselEffect: {
                      sideSlides: 1,
                    },
                  },
                  1200: {
                    slidesPerView: 3,
                    carouselEffect: {
                      sideSlides: 1,
                    },
                  },
                }}
              >
                {[...data, ...data, ...data].map((member, index) => {
                  return (
                    <>
                      <SwiperSlide key={index}>
                        <Member
                          member={member}
                          onClick={() => {
                            setPopup(true);
                            setCurrentMebmer(index);
                          }}
                        />
                      </SwiperSlide>
                    </>
                  );
                })}
              </Swiper>

              <div className="testimonial__btn arrow_blue_right">
                <img src={arrow_blue_right} alt="" />
              </div>
            </div>
          </Container>
        </section>
      </>
    );
  }
}
