// customerSlice.js
import {createSlice} from "@reduxjs/toolkit";


const guest = createSlice({
    name: "guest",
    initialState: {
        cart_id: null
    },
    reducers: {

        removeCartId(state) {
            state.cart_id = null
            localStorage.removeItem('guest_cart_id')
        },

    },
});

export const {removeCartId} = guest.actions;

export default guest.reducer;
