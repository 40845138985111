import {useEffect, useState} from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {
    addToCard,
    closeCart,
    removeFromLocalCart,
    updateShoppingCart
} from "../../../../tools/store/slices/shippingCartSlice";

function ShoppingCart({close}) {
    const dispatch = useDispatch()
    const customer = useSelector(state => state.customer)
    const shippingCart = useSelector(state => state.shippingCart)
    const [cartItems, setCartItems] = useState([])


    useEffect(() => {

        // if (customer.type === 'customer' && customer.quote_id && shippingCart.products && shippingCart.products.length > 0) {
        //     console.log('shippingCart.products ', shippingCart.products)
        //     setTimeout(() => {
        //         shippingCart.products.forEach(product => {
        //             dispatch(addToCard(product.sku, null, null, null, product.name, product.price))
        //         })
        //     },1000)
        //
        // }

    }, [customer]);


    function removeFromCard(id, accessToken, cart_id) {
        if (accessToken) {
            axios.delete(`https://store.platformz.us/rest/V1/carts/mine/items/${id}`, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            })
                .then(response => {
                    dispatch(updateShoppingCart(customer.accessToken, cart_id))
                })

        } else {
            axios.delete(`https://store.platformz.us/rest/V1/guest-carts/${cart_id}/items/${id}`, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            })
                .then(response => {
                    dispatch(updateShoppingCart(customer.accessToken, cart_id))
                })
        }
    }

    return (
        <div
            className={`fixed top-0 right-0 w-full h-screen bg-white px-4 lg:w-[350px] z-50 animate-[show-slide-left_1s_ease-in-out_1] shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#33BBED,0_0_15px_#33BBED,0_0_30px_#33BBED] z-[150]`}>
            <div className={'block absolute top-12 right-12 cursor-pointer'}
                 onClick={() => dispatch(closeCart())}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                        fill="black"/>
                </svg>

            </div>
            {shippingCart.products && shippingCart.products.length !== 0 ?
                <>
                    <ul className={`mt-24 space-y-8 ${shippingCart.products.length > 4 && "max-h-[600px] overflow-hidden overflow-y-scroll pr-4"}`}>
                        {shippingCart.products.map(item => {
                            return (
                                <li className={'text-3xl border-b border-b-gray-300 pb-4'}>
                                    <div className="flex w-full justify-between">
                                        <p className={'text-xl'}>{item.name}</p>
                                        <p className={'text-xl'}>${item.price}</p>
                                    </div>
                                    <div className="">
                                        <button
                                            onClick={() => {
                                                dispatch(removeFromLocalCart({sku: item.sku}))
                                                removeFromCard(item.item_id, customer.accessToken, customer.cart_id)
                                            }}
                                            className={'text-xl font-medium text-red-700 underline opacity-70 hover:opacity-100 pointer'}>Remove
                                        </button>
                                    </div>

                                </li>
                            )
                        })}


                    </ul>
                    <div>
                        <div className={'text-3xl border-b-gray-300 mt-12'}>
                            <div className="flex w-full justify-between">
                                <p className={'text-2xl'}>Total</p>
                                <p className={'text-2xl'}>${shippingCart.products.reduce((a, b) => a + b.price, 0)}</p>

                            </div>


                        </div>
                    </div>

                    <Link to={`/checkout`} onClick={() => dispatch(closeCart())}
                          className="features_template__section-row-list_item-cta mx-auto mt-12"
                    >Proceed to checkout
                        <svg width="8" height="14" viewBox="0 0 8 14"
                             fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.292893 13.7071C-0.0976311 13.3166 -0.0976312 12.6834 0.292893 12.2929L5.58579 7L0.292893 1.70711C-0.0976317 1.31658 -0.0976317 0.683417 0.292893 0.292893C0.683417 -0.0976315 1.31658 -0.0976315 1.70711 0.292893L7.70711 6.29289C8.09763 6.68342 8.09763 7.31658 7.70711 7.70711L1.70711 13.7071C1.31658 14.0976 0.683418 14.0976 0.292893 13.7071Z"
                                fill="#FFFFFF"/>
                        </svg>
                    </Link>
                </> : <div className={'flex h-full items-center justify-center'}>
                    <p className={'text-xl'}>Your shopping cart is empty</p>
                </div>
            }


        </div>
    )
}

export default ShoppingCart