import React, {useEffect, useState} from "react";
import VerifyYourEmail from "../../../assets/pic/Verify_Your_Email.svg";
import VerifyYourPhone from "../../../assets/pic/Verify_Your_Phone.svg";
import {useForm} from "react-hook-form";
import {Button} from "../../../components/Button/Button";
import "./VerifyYourEmailPh.scss";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {useNavigate, useSearchParams} from "react-router-dom";
import {url} from "../../../tools/api/api";

import Timer from "../../../components/Timer/Timer";
import {log} from "three/examples/jsm/nodes/math/MathNode";

export default function VerifyYourEmailPh() {
    //Tools
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const oauth = searchParams.get('oauth')
    const type = searchParams.get('type')
    const phone = searchParams.get('phone')
    //States
    const customer = useSelector((state) => state.customer);
    const [otpStatus, setOtpStatus] = useState();
    const [seconds, setSeconds] = useState(120);


    useEffect(() => {
        const timer = setInterval(() => {
            setSeconds(prevSeconds => (prevSeconds > 0 ? prevSeconds - 1 : 0));
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    // Form
    const {
        register, handleSubmit, formState: {errors}, setError

    } = useForm();


    function sendCode(phone, code, type, customer_id) {
        axios.post(`https://devapi.platformz.us/api/customer/verify-otp`, {to: phone, code: code, type: type, customer_id:customer_id})
            .then(response => {


                if (response.data.status === 'approved') {
                    navigate('/dashboard')
                }


            })
            .catch(error => {
                console.error(error)
            })
    }

    function sendPhoneNumberForVerification(phone) {
        setSeconds(120)
        axios
            .post(`https://devapi.platformz.us/api/customer/send-verification`, {to: phone})
            .then((response) => {
                setOtpStatus(response.data.status);
            })
            .catch((error) => {
                console.error("/send-verification:", error)
                setError('code', {type: 'custom', message: "Incorrect code or what went wrong"});
            });
    }





    useEffect(() => {
        if (phone !==null) {
            setTimeout(() => {
                sendPhoneNumberForVerification(`+${phone}`)
            }, 5000)
        }

    }, [])

    const onSubmit = (data) => {
        sendCode(`+${phone}`, data.code, "phone", customer.id)
    }


    return (<div className="verify_your_email_ph container">
        <h3 className="text-3xl">Verify Your</h3>
        <h3 className="text-3xl">{searchParams.get('type')}</h3>
        <p className="text-2xl">
            Please check your mobile SMS for CODE.
        </p>
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="form_verify_email_phone"
        >
            <div className="box_block_verify">
                <div className="block_verify">
                    <div className="top_block_verify">
                        <div className="box_img_block_verify">
                            {(searchParams.get('type') === "Phone No." &&
                                <img src={VerifyYourPhone} alt="#"/>) || (searchParams.get('type') === "Email" &&
                                <img src={VerifyYourEmail} alt="#"/>)}
                        </div>
                        <div className="content_block_verify">
                            <p className="text-regular">Verify Your</p>
                            <h4>{(searchParams.get('type') === "Email") ? 'Email ID' : 'Phone No'}</h4>
                        </div>
                    </div>
                    <input
                        className={''
                        }
                        type="text"
                        {...register("code", {required: 'Enter code please'})}
                        placeholder={(searchParams.get('type') === "Phone No." && "Verify Phone SMS OTP") || (searchParams.get('type') === "Email" && "Verify Email OTP")}
                    />
                    {errors.code && (
                        <p className={'text-xl text-red-600 font-light pt-4'}>{errors.code.message}</p>)}
                </div>
            </div>

            <Timer seconds={seconds}/>
            <div className="box_btn_form">
                <Button text="Verify OTP" type={"submit"}/>

                {/* For testing*/}
                {/*<Button text="Login" type={"submit"} onClick={() => userLogin(customer.email, customer.password)}/>*/}

                <Button text="Resend OTP" type={"submit"} disabled={seconds === 0 ? false : true}
                        onClick={() => sendPhoneNumberForVerification(`+${phone}`)}/>


            </div>
            <p className={'text-xl font-light text-gray-500 pt-12'}>Code can take upto 2 minutes to arrive.</p>
        </form>

    </div>);
}
