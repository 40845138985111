import React, { useEffect, useState } from 'react'
import Sidebar from '../../../../components/CustomerDashboard/Sidebar/Sidebar'
import './../Dashboard.scss'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { countries } from 'countries-list'
import Subscribe from '../../../Landing/sections/Subscribe'
import Copyright from '../../../../components/CustomerDashboard/Copyright/Copyright'
import Topbar from '../Topbar/Topbar'

const MyOrdersView = () => {
    const { id } = useParams();
    const [orderId, setOrderId] = useState(id);
    const [customerOrder, setCustomerOrder] = useState(null);

    const customerState = useSelector((state) => state.customer);
    const getCustomerOrderData = async (token, orderId) => {
        try {
            const res = await axios.get('https://store.platformz.us/rest/V1/orders/' + orderId, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (res.data) {
                console.log(res.data);
                setCustomerOrder(res.data);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }
    useEffect(() => {
        setOrderId(id);
    }, [id])

    useEffect(() => {
        if (customerState.accessToken && orderId) {
            getCustomerOrderData("05dot6lrxguf0e7pr8eptoa1yu8lj9gb", orderId);
        }
        // console.log(customerState);
    }, [customerState, customerState.accessToken, orderId])


    return (

        <>
            <Topbar />
            <div className={'min-h-screen'}>
                <div className={'page-main'}>
                    <Sidebar />
                    <div className={'main-content'} style={{
                        width: "calc(100% - 385px)"
                    }}>

                        <div>
                            <h1 className='text-4xl font-medium'>Orders # {customerOrder?.increment_id}</h1>

                            <span>{customerOrder?.status.toUpperCase()}</span>

                        </div>
                        <div>
                            <div>{new Date(customerOrder?.created_at).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })}</div>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}>
                                <Link to={''}>
                                    Reorder
                                </Link>
                                <Link to={''}>
                                    Print Order
                                </Link>
                            </div>

                            <div>
                                <div>
                                    <span>Items Ordered</span>
                                </div>
                                <div>
                                    <table style={{ width: "100%" }}>
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: "left" }}>Product Name</th>
                                                <th style={{ textAlign: "left" }}>SKU</th>
                                                <th style={{ textAlign: "right" }}>Price</th>
                                                <th style={{ textAlign: "right" }}>Qty</th>
                                                <th style={{ textAlign: "right" }}>Subtotal</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {customerOrder?.items?.map((item) => {
                                                console.log(item);
                                                return (
                                                    <tr>
                                                        <td>{item?.name}</td>
                                                        <td>{item?.sku}</td>
                                                        <td style={{ textAlign: "right" }}>${item?.price}</td>
                                                        <td style={{ textAlign: "right" }}>{item?.qty_ordered}</td>
                                                        <td style={{ textAlign: "right" }}>${item?.row_total}</td>
                                                    </tr>
                                                )
                                            })}

                                            <tr>
                                                <td colSpan={4} style={{ textAlign: "right" }}>Subtotal</td>
                                                <td style={{ textAlign: "right" }}>${customerOrder?.subtotal_incl_tax}</td>
                                            </tr>

                                            <tr>
                                                <td colSpan={4} style={{ textAlign: "right" }}>Shipping & Handling</td>
                                                <td style={{ textAlign: "right" }}>${customerOrder?.shipping_amount}</td>
                                            </tr>

                                            <tr>
                                                <td colSpan={4} style={{ textAlign: "right" }}>Grand Total</td>
                                                <td style={{ textAlign: "right" }}>${customerOrder?.grand_total}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className='mb-12 block-dashboard-info'>
                                <div className='pb-2 mt-10 mb-6 border-b'>
                                    <span>Order Information</span>
                                </div>
                                <div className='block-content'>
                                    <div style={{
                                        fontWeight: "500",
                                        clear: "none",
                                        float: "left",
                                        width: "25%"
                                    }}>
                                        <h4 className='font-bold'>Shipping Address</h4>
                                        <p>{customerOrder?.customer_firstname} {customerOrder?.customer_lastname}</p>
                                        {customerOrder?.billing_address?.street?.map((item) => {
                                            return (
                                                <p>{item}</p>
                                            )
                                        })}
                                        <p>{customerOrder?.extension_attributes?.shipping_assignments[0]?.shipping?.address?.city && customerOrder?.extension_attributes?.shipping_assignments[0]?.shipping?.address?.city}{customerOrder?.extension_attributes?.shipping_assignments[0]?.shipping?.address?.region && ", " + customerOrder?.extension_attributes?.shipping_assignments[0]?.shipping?.address?.region}{customerOrder?.extension_attributes?.shipping_assignments[0]?.shipping?.address?.postcode && ", " + customerOrder?.extension_attributes?.shipping_assignments[0]?.shipping?.address?.postcode}</p>
                                        <p>{countries[customerOrder?.extension_attributes?.shipping_assignments[0]?.shipping?.address?.country_id]?.name}</p>
                                    </div>
                                    <div style={{
                                        fontWeight: "500",
                                        clear: "none",
                                        float: "left",
                                        width: "25%"
                                    }}>
                                        <h4 className='font-bold'>Shipping Method</h4>
                                        <p>{customerOrder?.shipping_description}</p>
                                    </div>
                                    <div style={{
                                        fontWeight: "500",
                                        clear: "none",
                                        float: "left",
                                        width: "25%"
                                    }}>
                                        <h4 className='font-bold'>Billing Address</h4>
                                        <p>{customerOrder?.customer_firstname} {customerOrder?.customer_lastname}</p>
                                        {customerOrder?.billing_address?.street?.map((item) => {
                                            return (
                                                <p>{item}</p>
                                            )
                                        })}
                                        <p>{customerOrder?.billing_address?.city && customerOrder?.billing_address?.city}{customerOrder?.billing_address?.region && ", " + customerOrder?.billing_address?.region}{customerOrder?.billing_address?.postcode && ", " + customerOrder?.billing_address?.postcode}</p>
                                        <p>{countries[customerOrder?.billing_address?.country_id]?.name}</p>
                                    </div>
                                    <div style={{
                                        fontWeight: "500",
                                        clear: "none",
                                        float: "left",
                                        width: "25%"
                                    }}>
                                        <h4 className='font-bold'>Payment Method</h4>
                                        <p>{customerOrder?.extension_attributes?.payment_additional_info[0]?.value}</p>
                                        <p></p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div style={{
                            marginRight: "-85px",
                            marginLeft: "-100px",
                            marginTop: "126px",
                            marginBottom: "-126px",
                        }}>
                            <Subscribe />
                            <Copyright />
                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}

export default MyOrdersView