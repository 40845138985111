import React from "react";
import errorPicture from "../../assets/pic/ObjectError.png";
import "./Error.scss";

export default function Error() {
  return (
    <div className="error container">
      <div className="error_picture">
        <img src={errorPicture} alt="#" />
      </div>
      <h3 className="over-title">Opps! Page not found</h3>
      <p className="text-regular">
        Sorry, the page you're looking for doesn't exist. If you think something
        is broken, report a problem.
      </p>
      <a href="/">Go to Home Page</a>
    </div>
  );
}
