import React, {useState, useEffect} from 'react';


const CustomCursor = ({animation, fullscreen, isHovered}) => {
    const [position, setPosition] = useState({x: 0, y: 0});
    const [show, setShow] = useState(false)
    const [opacity, setOpacity] = useState(0)
    useEffect(() => {
        const updateCursorPosition = (e) => {
            setPosition({x: e.clientX, y: e.clientY});
        };

        document.addEventListener('mousemove', updateCursorPosition);

        return () => {
            document.removeEventListener('mousemove', updateCursorPosition);
        };
    }, []);

    useEffect(() => {


        setTimeout(() => {
            setOpacity(1)
            setShow(true)
            setTimeout(() => {    setShow(false)}, 300)
        }, 200)

    }, [isHovered]);

    return (
        <div
            className={`custom-cursor ${animation ? 'custom-cursor-anim' : ''}${show ? ' custom-cursor-anim-show' : ''}`}
            style={{
                left: `${position.x}px`,
                top: `${position.y}px`,
                opacity: opacity,
                zIndex: fullscreen ? 1000 : 400
            }}>
            {fullscreen ? <p>close</p> : <p>play <br/>reel</p>}
        </div>
    );
};

export default CustomCursor;
