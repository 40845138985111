import {Button} from "../Button/Button";
import React, {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";

import axios from "axios";
import InputText from "../FormComponents/InputText";
import InputEmail from "../FormComponents/InputEmail";
import InputPhone from "../FormComponents/InputPhone";
import InputPassword from "../FormComponents/InputPassword";
import CheckBox from "../FormComponents/CheckBox";
import {userSetTokenAsync} from "../../tools/store/slices/customerSlice";
import GoogleProtectedInfo from "../Forms/GoogleProtectedInfo/GoogleProtectedInfo";
import {removeCartId} from "../../tools/store/slices/guestSlice";
import {addToCard, updateShoppingCart} from "../../tools/store/slices/shippingCartSlice";

function RegistrationForm() {
    const [loading, setLoading] = useState(false)
    const shopping_cart = useSelector((state) => state.shippingCart)
    const customer = useSelector((state) => state.customer)
    const navigate = useNavigate()
    const shippingCart = useSelector(state => state.shippingCart)
    const dispatch = useDispatch()
    const {
        register, handleSubmit, getValues, setError, formState: {errors},
    } = useForm();


    const checkPasswordMatch = (value) => {
        const originalPassword = getValues("password"); // "password" - имя поля пароля

        return value === originalPassword || "Passwords do not match";
    };

    function createCustomer({email, password, first_name, last_name, phone, newsletter}) {
        setLoading(true)
        const data = {
            email: email,
            password: password,
            first_name: first_name,
            last_name: last_name,
        }

        //https://devapi.platformz.us
        axios.post(`https://devapi.platformz.us/api/customer/create-customer`, {...data, newsletter: newsletter}, {})
            .then(response => {


                if(response.status === 200) {
                    axios.post(`https://devapi.platformz.us/api/customer/login-customer`, {email: data.email, password: data.password})
                        .then((res) => {
                            setLoading(false)
                            dispatch(userSetTokenAsync(res.data.data))
                        })
                }

            })
            .catch(error => {
                setLoading(false)

                setError('email', {type: 'custom', message: error.response.data.error.message});

                // setError("email", {
                //     type: "manual",
                //     message: error.response.data.error,
                // });
                //TESTtest123456*****
                //testsdasdasasdsadas@gmail.com
            })
    }
    useEffect(() => {
        if (customer.type === 'customer' && customer.quote_id && shippingCart.products && shippingCart.products.length > 0) {
            console.log('shippingCart.products ', shippingCart.products)
            setTimeout(() => {
                shippingCart.products.forEach(product => {
                    dispatch(addToCard(product.sku, null, null, null, product.name, product.price))
                })
                dispatch(updateShoppingCart())
                navigate('/checkout')
            },1000)

        }else if(customer.type === 'customer' && customer.accessToken){
            navigate('/email-verification?status=sending')
        }

    },[customer])
    const onSubmit = (data) => {


        createCustomer(data)


    };

    const methods = useForm()
    return (
        <FormProvider {...methods}>
            <form
                className="space-y-12"
                onSubmit={handleSubmit(onSubmit)}
            >
                <section className={'space-y-12 lg:flex lg:space-x-12 lg:space-y-0'}>
                    <InputText register={register} name={'first_name'} label={"First Name"} placeholder={'Your Name'}
                               errors={errors}
                               options={{required: 'This field is required'}}/>

                    <InputText register={register} name={'last_name'} label={"Last Name"} placeholder={'Your Last Name'}
                               errors={errors}
                               options={{required: 'This field is required'}}/>
                </section>

                <InputEmail register={register} name={'email'} label={"Email"} placeholder={'email@platformz.us'}
                            errors={errors}
                            options={{required: 'This field is required'}}/>





                <section className={'space-y-12 lg:flex lg:space-x-12 lg:space-y-0'}>
                    <InputPassword register={register} name={'password'} label={"Password"}
                                   placeholder={'•••••••••••••'}
                                   errors={errors}
                                   options={{required: 'This field is required'}}/>

                    <InputPassword register={register} name={'confirm_password'} label={"Confirm Password"}
                                   placeholder={'•••••••••••••'}
                                   errors={errors}
                                   options={{required: 'This field is required', validate: checkPasswordMatch}}/>
                </section>

                <section className={'space-y-12 lg:flex lg:space-x-12 lg:space-y-0'}>
                    <CheckBox register={register} name={'terms_of_service'} label={"Terms of Service"} errors={errors}
                              path={'/terms-of-service'}
                              options={{required: 'This field is required'}}/>
                    <CheckBox register={register} name={'privacy_policy'} label={"Privacy Policy"} errors={errors}
                              path={'/privacy-policy'}
                              options={{required: 'This field is required'}}/>
                    <CheckBox register={register} name={'newsletter'} label={"Opt-in for Newsletter"} errors={errors}/>
                </section>
                <GoogleProtectedInfo/>


                <button className={`features_template__section-row-list_item-cta mx-auto ${loading ?'animate-pulse' :''}`} disabled={loading ? true : false}>{loading ?'Loading...' :'Create Account'}
                </button>
            </form>
            <p className="text-xl font-light text-gray-500 pt-24">
                Already have an account? <Link to="/login"
                                               className="text-xl font-light  text-blue-400 hover:underline">Login
                here</Link>
            </p>
        </FormProvider>
    );
}

export default RegistrationForm;
