import React from "react";

function Timer({seconds}) {
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const remainingSeconds = time % 60;
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };

    return (
        <div>
            <p className={'text-2xl text-gray-600 pb-6'}>Resend code: {formatTime(seconds)}</p>
        </div>
    )
}

export default Timer