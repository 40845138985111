import { strapi_url } from "../../../../../tools/api/api";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updaterCartId } from "../../../../../tools/store/slices/guestSlice";
import { addToCard } from "../../../../../tools/store/slices/shippingCartSlice";

function PricingTemplates({ templates, itemsPerPage = 6 }) {

    const [itemOffset, setItemOffset] = useState(0);
    const dispatch = useDispatch()
    const customer = useSelector(state => state.customer)
    const guest = useSelector(state => state.guest)




    if (templates) {


        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        const currentItems = templates.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(templates.length / itemsPerPage);
        console.log('hi')

        const handlePageClick = (event) => {
            const newOffset = (event.selected * itemsPerPage) % templates.length;
            console.log(
                `User requested page number ${event.selected}, which is offset ${newOffset}`
            );
            setItemOffset(newOffset);
        }
        return (
            <>

                <div className="pricing__templates-grid pricing__templates-grid--themes">
                    {currentItems.map((i, index) => {
                        console.log(i)
                        return (

                            <div className="pricing__template pricing__template--themes" key={index}>
                                <div className="pricing__template-content">
                                    <h5 className="text-white text-3xl">{i.attributes.name}</h5>
                                    <div className={'pricing__template-price'}>
                                        <p>${i.attributes.price}</p>
                                        <div className="flex gap-6">
                                            <a href={i.attributes.preview_url} rel="noreferrer" target={'_blank'}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
                                                        fill="white" />
                                                    <path
                                                        d="M21.8944 11.5528C19.7362 7.23635 15.9031 5 12 5C8.09687 5 4.26379 7.23635 2.10557 11.5528C1.96481 11.8343 1.96481 12.1657 2.10557 12.4472C4.26379 16.7637 8.09687 19 12 19C15.9031 19 19.7362 16.7637 21.8944 12.4472C22.0352 12.1657 22.0352 11.8343 21.8944 11.5528ZM12 17C9.03121 17 5.99806 15.3792 4.12966 12C5.99806 8.62078 9.03121 7 12 7C14.9688 7 18.0019 8.62078 19.8703 12C18.0019 15.3792 14.9688 17 12 17Z"
                                                        fill="white" />
                                                </svg>
                                            </a>
                                            <button onClick={() => dispatch(addToCard(i.attributes.sku, customer.quote_id, customer.accessToken, guest.cart_id, i.attributes.name, i.attributes.price))} >
                                                <svg
                                                    width="24" height="25" viewBox="0 0 24 25" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_1_2824)">
                                                        <path
                                                            d="M4 16.6667V4.66675H2V2.66675H5C5.26522 2.66675 5.51957 2.7721 5.70711 2.95964C5.89464 3.14718 6 3.40153 6 3.66675V15.6667H18.438L20.438 7.66675H8V5.66675H21.72C21.872 5.66675 22.022 5.70141 22.1586 5.76808C22.2952 5.83476 22.4148 5.9317 22.5083 6.05153C22.6019 6.17137 22.6668 6.31095 22.6983 6.45966C22.7298 6.60837 22.7269 6.7623 22.69 6.90975L20.19 16.9097C20.1358 17.126 20.011 17.3179 19.8352 17.4551C19.6595 17.5922 19.4429 17.6667 19.22 17.6667H5C4.73478 17.6667 4.48043 17.5614 4.29289 17.3739C4.10536 17.1863 4 16.932 4 16.6667ZM6 23.6667C5.46957 23.6667 4.96086 23.456 4.58579 23.081C4.21071 22.7059 4 22.1972 4 21.6667C4 21.1363 4.21071 20.6276 4.58579 20.2525C4.96086 19.8775 5.46957 19.6667 6 19.6667C6.53043 19.6667 7.03914 19.8775 7.41421 20.2525C7.78929 20.6276 8 21.1363 8 21.6667C8 22.1972 7.78929 22.7059 7.41421 23.081C7.03914 23.456 6.53043 23.6667 6 23.6667ZM18 23.6667C17.4696 23.6667 16.9609 23.456 16.5858 23.081C16.2107 22.7059 16 22.1972 16 21.6667C16 21.1363 16.2107 20.6276 16.5858 20.2525C16.9609 19.8775 17.4696 19.6667 18 19.6667C18.5304 19.6667 19.0391 19.8775 19.4142 20.2525C19.7893 20.6276 20 21.1363 20 21.6667C20 22.1972 19.7893 22.7059 19.4142 23.081C19.0391 23.456 18.5304 23.6667 18 23.6667Z"
                                                            fill="white" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1_2824">
                                                            <rect width="24" height="24" fill="white"
                                                                transform="translate(0 0.666748)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </button>


                                        </div>

                                    </div>

                                    <div className="pricing__template-details">
                                        <p className={'text-xl pb-4 text-white'}>{i.attributes.name}</p>
                                        <p className={'text-base font-light text-white'}>{i.attributes.description}</p>
                                    </div>
                                </div>
                                <div className="pricing__template-picture">
                                    <img
                                        src={strapi_url + i.attributes.picture.data.attributes.url}
                                        alt="" />
                                </div>
                            </div>


                        )
                    })
                    } </div>
                {currentItems.length > 5 && <ReactPaginate
                    className={'portfolio__paginate'}
                    breakLabel="..."
                    nextLabel="next"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={4}

                    pageCount={pageCount}
                    previousLabel="prev"
                    renderOnZeroPageCount={null}
                />}

            </>
        )
    }
}

export default PricingTemplates