import {strapi_url} from "../../../tools/api/api";
import './Pricing.scss'


import axios from "axios";
import React, {useEffect, useState} from "react";

import TechnologySlider from "../../../components/TechnologySlider/TechnologySlider";
import PageBanner from "../../../components/PageBanner/PageBanner";
import PricingSlider from "../../../components/PricingSlider/PricingSlider";
import PricingTemplates from "./PricingComponents/PricingTemplates/PricingTemplates";
import PricingAddons from "./PricingComponents/PricingAddons/PricingAddons";

const Pricing = () => {
    const [content, setContent] = useState(null)
    const [templates, setTemplates] = useState(null)
    const [addons, setAddons] = useState(null)


    function getTemplaOptions() {
        axios.get('https://strapi.platformz.us/api/ecommerce-template-options?populate=deep')
            .then(res => {
                setTemplates(res.data.data)

            })
            .catch(err => console.error(err))
    }

    function getAddons() {
        axios.get('https://strapi.platformz.us/api/ecommerce-add-ons-and-features?populate=deep')
            .then(res => {
                setAddons(res.data.data)
                console.log('res.data.data', res.data.data)

            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        getTemplaOptions()
        getAddons()
        axios.get('https://strapi.platformz.us/api/build-your-platform?populate=deep')
            .then(res => {
                setContent(res.data.data.attributes)
            })
            .catch(err => console.error(err))
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        console.log(templates)
    }, [templates]);
    if (content) {


        return (
            <>
                <section className="pricing">
                    <div className={'mb-12'}>
                        <PageBanner content={content.Banner}/>
                    </div>

                    <PricingSlider content={content}/>


                    <ul className="pricing__features">
                        {content.features.map(feature => {
                            return (
                                <li className="pricing__feature-item">
                                    <div className="pricing__feature-item-ico"
                                         style={{backgroundColor: feature.feature_ico_bg_color}}>
                                        <img src={strapi_url + feature.feature_ico.data.attributes.url}
                                             alt={feature.feature_name}/>
                                    </div>
                                    <h4 className={'text-2xl py-2'}>{feature.feature_name}</h4>
                                    <p className={'text-xl text-gray-500'}>{feature.feature_desc}</p>
                                </li>
                            )
                        })}


                    </ul>


                    <div className="pricing__templates">
                        <h3 className="text-4xl">
                            {content.template_title}

                        </h3>   <p className="text-xl pt-4 pb-8">
                        {content.template_subtitle}
                    </p>

                        {templates !== null && <PricingTemplates templates={templates}/>}


                    </div>


                    {/*<div className="pricing__faq">*/}
                    {/*    <p className="pricing__faq-overtitle">Pricing FAQ</p>*/}
                    {/*    <h3 className="pricing__faq-title">Dedicated to help anything people’s needs</h3>*/}
                    {/*    <ul className="pricing__faq-list">*/}
                    {/*        {content.faq.map((item, index) => {*/}
                    {/*            return (*/}
                    {/*                <FaqItem data={item} open={index === 0 && true}/>*/}
                    {/*            )*/}
                    {/*        })}*/}

                    {/*    </ul>*/}
                    {/*</div>*/}
                    {addons && <PricingAddons title={content.addons_and_features_title}
                                              subtitle={content.addons_and_features_subtitle} addons={addons}/>}

                </section>
                <TechnologySlider content={content.logo_scroller}/>
            </>
        )
    }
}

export default Pricing