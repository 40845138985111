import "./About.scss";
import "./About.scss";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {strapi_url} from "../../../tools/api/api";
import {BlocksRenderer} from "@strapi/blocks-react-renderer";
import CKEditorRender from "../../../components/CKEditorText/CKEditorRender";
import PageBanner from "../../../components/PageBanner/PageBanner";
import OurTeam from "../../Landing/sections/OurTeam";
// import {useDispatch} from "react-redux";

const About = () => {
    // const dispatch = useDispatch()
    const [content, setContent] = useState(null);
    useEffect(() => {
        axios.get(`${strapi_url}/api/about-us?populate=deep`)
            .then(response => {
                  setContent(response.data.data.attributes)
                console.log(response.data.data.attributes)

            })
            .catch(error => console.error(error))

    }, [])
    if (content) {
        return (
            <>
                <PageBanner content={content.banner} />
                <div className="py-12 container">
                    <div className={'lg:flex'}>
                        <CKEditorRender text={content.description}/>
                        {content.picture.data && <div className="box_img_about_us flex-shrink-0 lg:w-1/2 m-auto gap-6">
                            <img src={strapi_url + content.picture.data.attributes.url} alt="computer"/>
                            </div>}

                    </div>

                    <div className="grid md:grid-cols-2 gap-8 md:gap-12">
                        {content.list.length && content.list.map((item, index) => {
                            return (
                                <div>
                                    <CKEditorRender text={item.point}/>
                                </div>
                            )
                        })}

                    </div>
                    <OurTeam/>

                </div>
            </>
        );
    }
};

export default About;
