import React from 'react'
import platformzLogo from '../../../../assets/logo/platformz-logo.png'
import customerImg from '../../../../assets/img/customer_placeholder.png'
import membersIcon from '../../../../assets/img/members.svg'
import chatIcon from '../../../../assets/img/chat.svg'
import alertIcon from '../../../../assets/img/notification-alert.svg'
import './Topbar.scss'

const Topbar = () => {
    return (
        <div className='topbar-container'>
            <div className='logo-container'>
                <img src={platformzLogo} alt='Logo' />
            </div>
            <div className='topbar-nav'>
                <div className='topbar-nav-links'>
                    <ul>
                        <li>
                            <img src={membersIcon} alt='members' />
                            <p>Add team Members</p>
                        </li>
                        <li>
                            <img src={chatIcon} alt='chat' />
                            <p>Chat Messaging Centre</p>
                        </li>
                        <li>
                            <img src={alertIcon} alt='alert' />
                            <p>Notifications and Alerts</p>
                        </li>
                    </ul>
                </div>
                <div className='topbar-customer'>
                    <img src={customerImg} alt='Customer Icon' />
                    <p>CUSTOMER</p>
                </div>
            </div>
        </div>
    )
}

export default Topbar