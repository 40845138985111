import React from "react"


export function InputEmail({register, options, name, label, placeholder, errors}) {
    const validateEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

    const style = 'bg-gray-50 border text-xl border-gray-300 text-gray-900 rounded-lg font-medium   focus:ring-primary-600 focus:border-primary-600 block w-full p-6 pt-8 pb-8'

    return (
        <div className={'w-full'}>
            <label
                className="block mb-4 text-xl font-medium text-gray-900">{label}</label>
            <input type="email"  {...register(name, {
                ...options,
                pattern: {value: validateEmail, message: 'Incorrect format'},
            })} placeholder={placeholder} className={style}/>
            {errors[name] && (
                <p className={'text-base text-red-600 font-light pt-4'}>{errors[name].message}</p>)}

        </div>


    )
}

export default InputEmail