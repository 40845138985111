import React, {useEffect, useState} from "react";

import {FormProvider, useForm} from "react-hook-form";
import InputText from "../../../components/FormComponents/InputText";
import InputEmail from "../../../components/FormComponents/InputEmail";
import InputPhone from "../../../components/FormComponents/InputPhone";

import axios from "axios";


import {useSelector} from "react-redux";
import InputSelector from "../../../components/FormComponents/InputSelector";
import {Link, useNavigate} from "react-router-dom";


export default function YourPersonalDetails() {
    const [addressList, setAddressList] = useState(null)
    const [selectedAdressFromList, setSelectedAdressFromList] = useState(null)
    const [addNewAdress, setAddNewAdress] = useState(false)


    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [shipppinStatus, setShipppinStatus] = useState(false)
    const [shipppingData, setShipppinData] = useState(null)
    const [shipppinGuestData, setGuestShipppingData] = useState(null)
    const [regions, setRegions] = useState(null)
    const customer = useSelector((state) => state.customer)
    const guest = useSelector((state) => state.guest)
    const [hideShippingAddress, setHideShippingAddress] = useState(false)
    const {
        register, handleSubmit, formState: {errors},
    } = useForm();


    async function createOrder(accessToken, shipping_data, customer_id, quote_id) {
        setLoading(true)
        setShipppinData(shipping_data)
        console.log(shipping_data, 'shipping_data')
        try {
            if (customer_id && shipping_data && accessToken) {
                const estimateShippingResponse = await axios.post(
                    'https://store.platformz.us/rest/V1/carts/mine/estimate-shipping-methods',
                    {
                        address: {
                            ...shipping_data.addressInformation.shipping_address,
                            customer_id: customer_id,
                            same_as_billing: 1,
                        },
                    },
                    {
                        withCredentials: true,
                        headers: {Authorization: `Bearer ${accessToken}`}
                    }
                );

                console.log(estimateShippingResponse.data);

                if (estimateShippingResponse.status === 200) {
                    const shippingInformationResponse = await axios.post(
                        'https://store.platformz.us/rest/V1/carts/mine/shipping-information',
                        shipping_data,
                        {
                            withCredentials: true,
                            headers: {Authorization: `Bearer ${accessToken}`}
                        }
                    );

                    console.log(shippingInformationResponse);

                    if (shippingInformationResponse.status === 200) {
                        setShipppinData(shippingInformationResponse.data);
                        navigate('/checkout/payment')
                        // const paymentInformationResponse = await axios.post(
                        //     'https://store.platformz.us/rest/V1/carts/mine/payment-information',
                        //     {
                        //         cartId:quote_id,
                        //         paymentMethod: {
                        //             method: 'stripe_payments',
                        //         },
                        //         billing_address: {
                        //             ...shipping_data.addressInformation.billing_address,
                        //         },
                        //     },
                        //     {
                        //         withCredentials: true,
                        //         headers: {Authorization: `Bearer ${accessToken}`}
                        //     }
                        // );
                        // if (paymentInformationResponse.status === 200) {
                        //     const redirectUrlResponse = await axios.get(
                        //         'https://store.platformz.us/rest/all/V1/stripe/payments/redirect_url',
                        //         {
                        //             withCredentials: true,
                        //             credentials: 'include',
                        //             headers: {Authorization: `Bearer ${accessToken}`}
                        //         }
                        //     );
                        //     if (redirectUrlResponse.status === 200) {
                        //         // window.location.href = redirectUrlResponse.data;
                        //     }
                        //
                        // }


                    }
                }
            }
        } catch (error) {
            console.error('Error:', error);

        }
    }


    // async function createOrderGuest(shipping_data, cart_id) {
    //     setLoading(true)
    //     try {
    //         if (cart_id && shipping_data) {
    //
    //             const shippingInformationResponse = await axios.post(
    //                 `https://store.platformz.us/rest/V1/guest-carts/${cart_id}/shipping-information`,
    //                 shipping_data,
    //                 {
    //                     withCredentials: true,
    //                 }
    //             );
    //
    //
    //             if (shippingInformationResponse.status === 200) {
    //                 setShipppinStatus(true);
    //                 console.log(shippingInformationResponse.data);
    //                 setShipppinData(shippingInformationResponse.data);
    //
    //                 // const paymentInformationResponse = await axios.get(`https://store.platformz.us/rest/V1/guest-carts/${cart_id}/shipping-methods`);
    //                 // if (paymentInformationResponse) {
    //                 //     const getShoppingId = await axios.put(`https://store.platformz.us/rest/V1/guest-carts/${cart_id}/order`, {
    //                 //         "paymentMethod": {
    //                 //             "method": "checkmo"
    //                 //         }
    //                 //     })
    //                 //     if (getShoppingId) {
    //                 //
    //                 //     }
    //                 // }
    //
    //
    //             }
    //         }
    //
    //     } catch (error) {
    //         console.error('Error:', error);
    //
    //     }
    // }


    const onSubmit = (data) => {

        const shipping_address = {
            region: data.region,
            region_code: '',
            region_id: data.region,
            country_id: data.country,
            street: [
                data.street
            ],
            postcode: data.postcode,
            city: data.city,
            firstname: data.firstname,
            lastname: data.lastname,
            email: data.email,
            telephone: data.phone.replace(/\D/g, '')
        }


        const sameAs = () => hideShippingAddress ? {...shipping_address} : {
            region: data.region,
            region_code: '',
            country_id: data.shipping_country,
            region_id: data.shipping_region,

            street: [
                data.shipping_street
            ],
            postcode: data.shipping_postcode,
            city: data.shipping_city,
            firstname: data.shipping_firstname,
            lastname: data.shipping_lastname,
            email: data.shipping_email,
            telephone: data.phone.replace(/\D/g, '')
        }

        const addressInformation = {
            addressInformation: {
                shipping_address: {
                    ...shipping_address
                },
                billing_address: sameAs(),
                shipping_carrier_code: "flatrate",
                shipping_method_code: "flatrate"
            }
        }

        if (customer.accessToken) {
            createOrder(customer.accessToken, addressInformation, customer.quote_id, customer.quote_id)
        } else {
            setGuestShipppingData(addressInformation)
            // createOrderGuest(addressInformation, guest.cart_id)
        }


    };

    useEffect(() => {
        axios.get(`https://store.platformz.us/rest/V1/directory/countries`,)
            .then(res => {
                const region_sort = res.data.filter((i) => i.available_regions)
                setRegions(region_sort)
                console.log('region_sort', region_sort)
            })
    }, []);


    useEffect(() => {
        if (addressList) {
            setAddNewAdress(false)
        } else {
            setAddNewAdress(true)
        }
    }, [addressList]);

    const getCustomerData = async (token) => {
        try {
            const res = await axios.get('https://store.platformz.us/rest/V1/customers/me', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (res.data) {
                setAddressList(res.data.addresses);
                console.log(res.data.addresses)
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        getCustomerData(customer.accessToken)
    }, [])
    const methods = useForm()
    return (
        <>


            <div
                className="w-full">
                <div
                    className="bg-[#F3F3F3] w-full p-12">
                    {addNewAdress ?
                        <>
                            <div className="">
                                <><FormProvider {...methods}>
                                    <form
                                        className="w-full"
                                        onSubmit={handleSubmit(onSubmit)}
                                    >
                                        <div className={'border-b pb-2 mb-8'}>
                                            <p className={'text-2xl'}>Billing Address</p>
                                        </div>


                                        <InputSelector register={register} name={'country'} label={"Choose a country"}
                                                       placeholder={'Choose a country'}
                                                       errors={errors} data={regions}
                                                       options={{required: 'This field is required'}}/>
                                        <section className={'grid xl:grid-cols-2 gap-4  mt-4'}>
                                            <InputText register={register} name={'city'} label={"City"}
                                                       placeholder={'Your city'}
                                                       errors={errors}
                                                       options={{required: 'This field is required'}}/>


                                            <InputText register={register} name={'street'} label={"Street"}
                                                       placeholder={'123 Oak Avek'}
                                                       errors={errors}
                                                       options={{required: 'This field is required'}}/>

                                            <InputText register={register} name={'postcode'} label={"Postcode"}
                                                       placeholder={'10577'}
                                                       errors={errors}
                                                       options={{required: 'This field is required'}}/>


                                            <InputText register={register} name={'firstname'} label={"First name"}
                                                       placeholder={'Your first name'}
                                                       errors={errors}
                                                       options={{required: 'This field is required'}}/>

                                            <InputText register={register} name={'lastname'} label={"Last name"}
                                                       placeholder={'Your last name'}
                                                       errors={errors}
                                                       options={{required: 'This field is required'}}/>

                                            <InputEmail register={register} name={'email'} label={"Email"}
                                                        placeholder={'email@platformz.us'}
                                                        errors={errors}
                                                        options={{required: 'This field is required'}}/>

                                        </section>

                                        <div className={'mt-4'}>
                                            <InputPhone register={register} name={'phone'} label={"Phone"}
                                                        errors={errors}
                                                        options={{required: 'This field is required'}}/>
                                        </div>

                                        <div
                                            className={'border-b pb-2 mb-8 xl:flex justify-between items-center mt-12'}>
                                            <p className={'text-2xl'}>Shipping Address</p>
                                            <label
                                                className="relative inline-flex items-center cursor-pointer mt-4 xl:mt-0">
                                                <input type="checkbox" className="sr-only peer"
                                                       onChange={(e) => setHideShippingAddress(!hideShippingAddress)}/>
                                                <div
                                                    className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                                <span
                                                    className="ms-3 text-basic font-medium text-black">same as Billing Address</span>
                                            </label>
                                        </div>
                                        {
                                            !hideShippingAddress && (
                                                <div>

                                                    <InputSelector register={register} name={'shipping_country'}
                                                                   label={"Choose a country"}
                                                                   placeholder={'Choose a country'}
                                                                   errors={errors} data={regions}/>


                                                    <div className={'grid xl:grid-cols-2  gap-4 mt-4'}>


                                                        <InputText register={register} name={'shipping_city'} label={"City"}
                                                                   placeholder={'Your city'}
                                                                   errors={errors}
                                                                   options={{required: 'This field is required'}}/>


                                                        <InputText register={register} name={'shipping_street'}
                                                                   label={"Street"}
                                                                   placeholder={'123 Oak Avek'}
                                                                   errors={errors}
                                                                   options={{required: 'This field is required'}}/>

                                                        <InputText register={register} name={'shipping_postcode'}
                                                                   label={"Postcode"}
                                                                   placeholder={'10577'}
                                                                   errors={errors}
                                                                   options={{required: 'This field is required'}}/>


                                                        <InputText register={register} name={'shipping_firstname'}
                                                                   label={"First name"}
                                                                   placeholder={'Your first name'}
                                                                   errors={errors}
                                                                   options={{required: 'This field is required'}}/>

                                                        <InputText register={register} name={'shipping_lastname'}
                                                                   label={"Last name"}
                                                                   placeholder={'Your last name'}
                                                                   errors={errors}
                                                                   options={{required: 'This field is required'}}/>

                                                        <InputEmail register={register} name={'shipping_email'}
                                                                    label={"Email"}
                                                                    placeholder={'email@platformz.us'}
                                                                    errors={errors}
                                                                    options={{required: 'This field is required'}}/>

                                                    </div>
                                                    <div className={'my-4'}>
                                                        <InputPhone register={register} name={'shipping_phone'}
                                                                    label={"Phone"}
                                                                    errors={errors}
                                                                    options={{required: 'This field is required'}}/>
                                                    </div>
                                                </div>)
                                        }

                                        <div
                                            className={'flex items-center flex-col lg:flex-row justify-center gap-6 mt-12'}>
                                            <Link
                                                className="features_template__section-row-list_item-cta w-full max-w-[300px]"
                                                to={'/checkout'}>Back
                                            </Link>
                                            <button
                                                className="features_template__section-row-list_item-cta w-full max-w-[300px]"
                                                type={"submit"}>{loading ? 'Processing...' : 'Next'}
                                            </button>
                                        </div>
                                    </form>
                                </FormProvider></>


                            </div>
                        </> : <>
                            <div>
                                <h3 className={'text-xl'}>Your Addresses</h3>
                                <ul className={'space-y-3 my-3'}>
                                    {
                                        addressList && addressList.map(address => {
                                            return (
                                                <li className={`border border-[#727272] rounded-xl p-3 cursor-pointer ${selectedAdressFromList && address.id === selectedAdressFromList.id ? 'bg-[#005EA5] text-white' : ''}`}
                                                    onClick={() => setSelectedAdressFromList(address)}>
                                                    <p>{address.firstname + " " + address.lastname}</p>
                                                    <p> {address.region.region + " " + address.street[0]}</p>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                <div className={'pb-4 hover:opacity-70'}>
                                    <button onClick={() => setAddNewAdress(true)}
                                            className={'text-base text-[#727272] font-medium flex items-center gap-3'}>
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M8.65385 1.15385C8.65385 0.515625 8.13822 0 7.5 0C6.86178 0 6.34615 0.515625 6.34615 1.15385V6.34615H1.15385C0.515625 6.34615 0 6.86178 0 7.5C0 8.13822 0.515625 8.65385 1.15385 8.65385H6.34615V13.8462C6.34615 14.4844 6.86178 15 7.5 15C8.13822 15 8.65385 14.4844 8.65385 13.8462V8.65385H13.8462C14.4844 8.65385 15 8.13822 15 7.5C15 6.86178 14.4844 6.34615 13.8462 6.34615H8.65385V1.15385Z"
                                                fill="#727272"/>
                                        </svg>
                                        Add a new address
                                    </button>
                                </div>

                                <button
                                    className="features_template__section-row-list_item-cta w-fit  mx-auto"
                                    onClick={() => {
                                        const data = {
                                            region: selectedAdressFromList.region.region,
                                            region_code: '',
                                            region_id: selectedAdressFromList.region.region_id,
                                            country_id: selectedAdressFromList.country_id,
                                            street: [
                                                selectedAdressFromList.street[0]
                                            ],
                                            postcode: selectedAdressFromList.postcode,
                                            city: selectedAdressFromList.city,
                                            firstname: selectedAdressFromList.firstname,
                                            lastname: selectedAdressFromList.lastname,
                                            // email: selectedAdressFromList.email,
                                            telephone: selectedAdressFromList.telephone.replace(/\D/g, '')
                                        }
                                        createOrder(customer.accessToken, {
                                            addressInformation: {
                                                shipping_address: {
                                                    ...data
                                                },
                                                billing_address: data,
                                                shipping_carrier_code: "flatrate",
                                                shipping_method_code: "flatrate"
                                            }
                                        }, customer.quote_id, customer.quote_id)
                                    }}
                                    type={"submit"}>{loading ? 'Processing...' : 'Use this Address'}
                                </button>
                            </div>
                        </>}


                </div>
            </div>


        </>
    );

}
