import React, {useState} from "react";

import {Link, useNavigate, useSearchParams} from "react-router-dom";

import SSO from "../../../components/SSO/SSO";

import LoginForm from "../../../components/LoginForm/LoginForm";
import InputEmail from "../../../components/FormComponents/InputEmail";
import InputPassword from "../../../components/FormComponents/InputPassword";
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import axios from "axios";
import {url} from "../../../tools/api/api";


export default function ForgotPasswordPage() {
    const [status, setStatus] = useState(false)
// eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {
        register, handleSubmit, getValues, setError, formState: {errors},
    } = useForm();


    const onSubmit = (data) => {

        axios.put(`https://store.platformz.us/rest/V1/customers/password/?email=${data.email}&template=email_reset`)
            .then(response => {
                if (response.data) {
                    setStatus(true)
                }
            })
            .catch(error => {
                setError('email',
                    {type: 'custom', message: 'This user does not exist'})
            })

    };

    const methods = useForm()

    return (
        <section className="bg-gray-50 pt-80 pb-80">
            <div className="flex flex-col items-center justify-center  mx-auto h-full w-1/2">
                <div
                    className="w-full bg-white rounded-lg shadow">

                    <div className="p-8 pt-20 ">
                        <p className="text-center leading-tight tracking-tight text-gray-900 md:text-4xl mb-6">
                            Forgot Password
                        </p>
                        {
                            status ? (<p className="text-3xl font-light text-gray-500 text-center pb-12">
                                Password reset instructions have been sent to your e-mail address.
                            </p>) : (<form
                                className="space-y-12"
                                onSubmit={handleSubmit(onSubmit)}
                            >


                                <InputEmail register={register} name={'email'} label={"Email"}
                                            placeholder={'email@platformz.us'}
                                            errors={errors}
                                            options={{required: 'This field is required'}}/>
                                <p className="text-3xl font-light text-gray-500">
                                    Enter your email so that we can send you a password recovery link.
                                </p>

                                <button className="features_template__section-row-list_item-cta mx-auto">Send
                                </button>
                            </form>)
                        }


                    </div>
                </div>
            </div>


        </section>
    );
}
